import { Form, FormError, FieldError, Label, Submit } from '@redwoodjs/web'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import { customersToOptions, toolboxesToOptions } from 'src/utils/utils'

import Input from '../Input/Input'
import LoadingShield from '../LoadingShield/LoadingShield'
import Select from '../Select/Select'
import Row from '../Row/Row'
import Button from '../Button/Button'
import ModalConfirm from '../ModalConfirm/ModalConfirm'

const ProjectForm = (props) => {
  const [customers, setCustomerOptions] = useState([])
  const [toolboxes, setToolboxOptions] = useState([])

  const onSubmit = (data) => {
    if (!props.editing) {
      const selectedCustomer = customers.find(({ selected }) => selected)
      const selectedToolbox = toolboxes.find(({ selected }) => selected)
      data.customer = selectedCustomer
      data.toolbox = selectedToolbox
    }
    props.onSave(data, props?.project?.id)
  }

  if (props.customers && props.customers.length && !customers.length) {
    const newCustomers = customersToOptions(
      props.customers,
      props.defaultCustomer
    )
    setCustomerOptions(newCustomers)
  }
  if (props.toolboxes && props.toolboxes.length && !toolboxes.length) {
    const newToolboxes = toolboxesToOptions(props.toolboxes)
    setToolboxOptions(newToolboxes)
  }

  const disableSubmit = () => {
    let disabled = false
    if (!props.editing) {
      const selectedCustomer = customers.find(({ selected }) => selected)
      const selectedToolbox = toolboxes.find(({ selected }) => selected)
      if (!selectedToolbox) {
        console.log('TOOLBOX ISSUE')
        disabled = true
      }
      if (!selectedCustomer) {
        console.log('SUBMIT ISSUE')
        disabled = true
      }
    }
    return false //disabled
  }

  return (
    <FormContainer>
      {props.loading && <LoadingShield />}
      <Form onSubmit={onSubmit} error={props.error}>
        {props.editing && (
          <Row>
            <FormHeader>Project Settings</FormHeader>
          </Row>
        )}
        <Row>
          <FormColumn className="mr-1">
            {!props.editing && (
              <FormRow>
                <FormColumn>
                  <FormError
                    error={props.error}
                    wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mb-4"
                    titleClassName="font-semibold"
                    listClassName="mt-2 list-disc list-inside"
                  />

                  <FormLabel name="Customer" />
                  <Select
                    form
                    title="Select Customer"
                    options={customers}
                    setOptions={setCustomerOptions}
                    disabled={props.defaultCustomer}
                  />
                  <FieldError name="Customer" className={CSS.errorMessage} />
                </FormColumn>
              </FormRow>
            )}
            <FormRow>
              <FormColumn>
                <FormLabel name="Project Name" />
                <Input
                  name="name"
                  placeholder="e.g. 123 Applewood Road"
                  defaultValue={props.project?.name}
                  validation={{ required: true }}
                />
                <FieldError name="Project Name" className={CSS.errorMessage} />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormLabel name="Project Reference" />
                <Input
                  name="reference"
                  placeholder="e.g. 8359204"
                  defaultValue={props.project?.reference}
                  validation={{ required: true }}
                />
                <FieldError
                  name="Project Reference"
                  className={CSS.errorMessage}
                />
              </FormColumn>
            </FormRow>
          </FormColumn>
          <FormColumn className="ml-1">
            <FormRow>
              <FormColumn>
                <FormLabel name="Address" />
                <Input
                  name="address1"
                  placeholder="Address Line 1"
                  defaultValue={props.project?.address1}
                />
                <Input
                  name="address2"
                  placeholder="Address Line 2"
                  defaultValue={props.project?.address2}
                />
                <FormRow noTop>
                  <Input
                    name="city"
                    placeholder="City"
                    defaultValue={props.project?.city}
                    className="mr-1"
                  />
                  <Input
                    name="postCode"
                    placeholder="Post code"
                    defaultValue={props.project?.postCode}
                  />
                </FormRow>
                <FieldError name="address" />
                {!props.editing && (
                  <FormRow>
                    <FormColumn>
                      <FormError
                        error={props.error}
                        wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mb-4"
                        titleClassName="font-semibold"
                        listClassName="mt-2 list-disc list-inside"
                      />

                      <FormLabel name="Toolbox" />
                      <Select
                        form
                        title="Select Toolbox"
                        options={toolboxes}
                        setOptions={setToolboxOptions}
                      />
                      <FieldError name="Toolbox" className={CSS.errorMessage} />
                    </FormColumn>
                  </FormRow>
                )}
              </FormColumn>
            </FormRow>
            {!props.editing && (
              <Button
                disabled={disableSubmit()}
                text="Create Project"
                type="submit"
                btnStyle="primary"
                className="mt-2"
              />
            )}
          </FormColumn>
        </Row>
        {props.editing && (
          <FullWidth>
            <HalfWidth>
              <Button
                text="Save Details"
                type="submit"
                btnStyle="success"
                className="mt-2"
              />
            </HalfWidth>
            <HalfWidth className="ml-1">
              <ModalConfirm
                message="Would you like to archive this project?"
                trigger={
                  <Button
                    text="Delete Project"
                    btnStyle="danger"
                    className="mt-2"
                  />
                }
                onConfirm={() => props.onArchive(props.project?.id)}
              />
            </HalfWidth>
          </FullWidth>
        )}
      </Form>
    </FormContainer>
  )
}

export default ProjectForm

const FullWidth = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HalfWidth = styled.div`
  width: 50%;
`

const FormContainer = styled.div`
  position: relative;
  width: 100%;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) =>
    props.noTop
      ? css`
          padding-top: 0rem;
        `
      : css`
          padding-top: 2rem;
        `}
`

const FormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const FormLabel = styled(Label)`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
`

const FormHeader = styled.span`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 150% */

  color: #000000;
`
