import styled, { css } from 'styled-components'

import Header from 'src/components/Header/Header'
import NewSidebar from 'src/components/NewSidebar/NewSidebar'

const Layout = styled.div`
  height: max-content;
  min-height: calc(100vh - 72px);
  width: 100vw;
`

const SiteContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: max-content;
  min-height: calc(100vh - 72px);
`

const SiteBody = styled.div`
  min-height: calc(100vh - 72px);
  height: calc(100vh - 72px);
  width: 100%;
  padding: 0;
  background: var(--color-background-base);
`

const NewCoreLayout = ({
  children,
  hideSidebar = false,
  noHeader,
  ...restOfProps
}) => {
  return (
    <Layout>
      <Header />
      <SiteContainer {...restOfProps}>
        <NewSidebar />
        <SiteBody {...restOfProps}>{children}</SiteBody>
      </SiteContainer>
    </Layout>
  )
}

export default NewCoreLayout
