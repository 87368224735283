const Trash = ({ className, fill = 'var(--color-danger-main)', onClick }) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <path
        d="M14.7321 2.125H11.25L10.125 0.6375C9.9753 0.439563 9.78118 0.278907 9.55802 0.168257C9.33486 0.0576062 9.08879 0 8.83929 0L6.16071 0C5.91121 0 5.66514 0.0576062 5.44198 0.168257C5.21882 0.278907 5.0247 0.439563 4.875 0.6375L3.75 2.125H0.267857C0.196817 2.125 0.128687 2.15299 0.0784535 2.2028C0.0282206 2.25261 0 2.32018 0 2.39062L0 2.92188C0 2.99232 0.0282206 3.05989 0.0784535 3.1097C0.128687 3.15951 0.196817 3.1875 0.267857 3.1875H0.90067L2.01228 15.549C2.04822 15.9454 2.23238 16.314 2.52855 16.5825C2.82471 16.851 3.21145 16.9999 3.61272 17H11.3873C11.7886 16.9999 12.1753 16.851 12.4715 16.5825C12.7676 16.314 12.9518 15.9454 12.9877 15.549L14.0993 3.1875H14.7321C14.8032 3.1875 14.8713 3.15951 14.9215 3.1097C14.9718 3.05989 15 2.99232 15 2.92188V2.39062C15 2.32018 14.9718 2.25261 14.9215 2.2028C14.8713 2.15299 14.8032 2.125 14.7321 2.125ZM5.73214 1.275C5.78222 1.20921 5.84696 1.1558 5.92131 1.11893C5.99566 1.08207 6.07759 1.06276 6.16071 1.0625H8.83929C8.92241 1.06276 9.00434 1.08207 9.07869 1.11893C9.15304 1.1558 9.21778 1.20921 9.26786 1.275L9.91071 2.125H5.08929L5.73214 1.275ZM11.9196 15.4527C11.9086 15.5851 11.8477 15.7085 11.749 15.7983C11.6504 15.8882 11.5212 15.9379 11.3873 15.9375H3.61272C3.47878 15.9379 3.34961 15.8882 3.25095 15.7983C3.1523 15.7085 3.09139 15.5851 3.08036 15.4527L1.97545 3.1875H13.0246L11.9196 15.4527Z"
        fill={fill}
      />
    </svg>
  )
}

export default Trash
