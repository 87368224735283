import Axios from 'axios'
import ApolloClient, { gql } from 'apollo-boost'

const client = new ApolloClient({
  uri: 'https://api.github.com/graphql',
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: 'Bearer 77d87960ef033a31292b37d61fb836f701aac317',
      },
    })
  },
})

import { strToBase64, lowercaseKeys } from './utils'

const creds = {
  bearer: '77d87960ef033a31292b37d61fb836f701aac317',
  username: 'nfinger',
  password: 'Ilmgmtaitw102018',
}

const defaultHeaders = {
  accept: 'application/json',
  'user-agent': 'https://github.com/jonschlinkert/github-base',
}
const BASE = 'https://api.github.com'

const createHeaders = (options) => {
  const opts = { ...options }
  const headers = { ...defaultHeaders, ...lowercaseKeys(opts.headers || {}) }

  if (!opts.bearer && !opts.token && !opts.username && !opts.password) {
    return headers
  }

  if (opts.token) {
    headers['Authorization'] = 'token ' + opts.token
    return headers
  }

  if (opts.bearer) {
    headers['Authorization'] = 'Bearer ' + opts.bearer
    return headers
  }

  const creds = opts.username + ':' + opts.password
  headers['Authorization'] = 'Basic bmZpbmdlcjpJbG1nbXRhaXR3MTAyMDE4'
  // headers['Authorization'] = 'Basic ' + strToBase64(creds)
  // console.log('lowercaseKeys(createHeaders -> headers', headers)
  return headers
}

export const all = async () => {
  return await Axios.get(`${BASE}/gists`)
}

export const getIconImage = async (id, content = false) => {
  const { data } = await get(id)
  const files = extractFile(data)
  const { text } = files[0]
  if (content) return text
  return getIconDataUrl(text)
}

export const getIconDataUrl = (svgContent) => {
  const base64data = btoa(unescape(encodeURIComponent(svgContent)))
  return `data:image/svg+xml;base64,${base64data}`
}

export const getMultipleGists = async (ids) => await Promise.all(ids.map(get))

export const get = async (id) => {
  return await client.query({
    query: gql`
      query GistsByID($name: String!) {
        viewer {
          gist(name: $name) {
            id
            name
            description
            files {
              text
            }
          }
        }
      }
    `,
    variables: { name: id },
  })
}

export const extractFile = (
  { viewer: { gist: { files } = {} } = {}, files: uploadFiles },
  key
) => files || uploadFiles[key].content

export const create = async (file) => {
  return await Axios.post(
    `${BASE}/gists`,
    {
      public: true,
      description: file.filename,
      files: {
        [file.filename]: {
          content: file.content,
        },
      },
    },
    { headers: lowercaseKeys(createHeaders(creds)) }
  )
}
