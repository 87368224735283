import ToolboxCell from 'src/components/ToolboxCell'

import SitecontainerLayout from 'src/layouts/SitecontainerLayout/SitecontainerLayout'
import NewCoreLayout from 'src/layouts/NewCoreLayout'

import WrapperForToolbox from 'src/components/Wrappers/WrapperForToolbox'

const ToolboxPage = ({ id }) => {
  return (
    <NewCoreLayout hideSidebar noTop noLeft noRight>
      {/*id && <ToolboxCell id={id} />*/}
      {id && <WrapperForToolbox id={id} />}
    </NewCoreLayout>
  )
}

export default ToolboxPage
