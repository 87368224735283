import React, { useState, useEffect, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'

import { ModalContext } from 'src/utils/ModalContext'

import WrapperForEditFitting from 'src/components/Wrappers/WrapperForEditFitting'

// import { addDocuments, searchDocuments } from 'src/search'
import IconCard from '../IconCard/IconCard'
import ModalConfirm from '../ModalConfirm'

const IconsList = ({
  icons,
  toolbox,
  listView,
  noContainer,
  editable,
  inProject,
  type,
  setDrag,
  search,
  toolboxId,
  remove,
  toolboxUpdateSave,
  addFittingToToolbox,
  updateToolboxFittings,
}) => {
  const [displayIcons, setDisplayIcons] = useState([])
  const { openModal, closeModal } = useContext(ModalContext)

  const duplicate = (icon) => {
    let num = 2
    let name = icon.name
    let found = icons.find((i) => i.name === name)
    while (found) {
      name = `${icon.name} ${num++}`
      found = icons.find((i) => i.name === name)
    }
    // Makes object identical to shape defined in graphQL schema:
    const fittingInput = {
      id: uuidv4(),
      name: icon.name ? icon.name : 'n/a',
      category: icon.category ? icon.category : 'n/a',
      image: icon.image ? icon.image : 'n/a',
      make: icon.make ? icon.make : 'n/a',
      model: icon.model ? icon.model : 'n/a',
      notes: icon.notes ? icon.notes : 'n/a',
      partNumber: icon.partNumber ? icon.partNumber : 'n/a',
      priceToSupply: icon.priceToSupply ? icon.priceToSupply : 0.0,
      priceToInstall: icon.priceToInstall ? icon.priceToInstall : 0.0,
      deleted: icon.deleted ? icon.deleted : false,
    }
    addFittingToToolbox({
      variables: { fitting: fittingInput, toolboxId: toolboxId },
    })
  }

  if (!displayIcons.length && !search && icons.length) {
    setDisplayIcons(icons)
  }

  useEffect(() => {
    if (icons) {
      console.log(icons.length)
      setDisplayIcons(icons)
    }
  }, [icons])

  if (displayIcons.length > 0) {
    return displayIcons.map((i, key) => (
      <>
        {listView ? (
          <IconRow>
            <IconCard
              key={key}
              noText
              inProject={inProject}
              toolbox={toolbox}
              toolboxUpdateSave={toolboxUpdateSave}
              displayIcons={displayIcons}
              noContainer={noContainer}
              editable={editable}
              type={type}
              setDrag={setDrag}
              duplicate={duplicate}
              updateToolboxFittings={updateToolboxFittings}
              remove={remove}
              {...i}
            />
            <IconDetails>
              <span>{i.name}</span>
              <div>
                <a
                  onClick={() =>
                    openModal(
                      <>
                        <WrapperForEditFitting
                          close={closeModal}
                          toolbox={toolbox}
                          inProject={inProject}
                          toolboxUpdateSave={toolboxUpdateSave}
                          updateToolboxFittings={updateToolboxFittings}
                          displayIcons={displayIcons}
                          id={i.id}
                        />
                      </>
                    )
                  }
                >
                  edit
                </a>

                {!inProject && toolbox.type != 'project' && (
                  <a
                    onClick={() => remove(i.id)}
                    style={{ color: 'var(--color-danger-main)' }}
                  >
                    delete
                  </a>
                )}
              </div>
            </IconDetails>
          </IconRow>
        ) : (
          <IconCard
            key={key}
            toolbox={toolbox}
            displayIcons={displayIcons}
            noContainer={noContainer}
            editable={editable}
            type={type}
            setDrag={setDrag}
            duplicate={duplicate}
            remove={remove}
            {...i}
          />
        )}
      </>
    ))
  } else {
    return <span>No icons in toolbox</span>
  }
}

export default IconsList

const IconDetails = styled.div`
  display: flex;
  flex-direction: column;
  > span {
    font-weight: bold;
  }
  > div {
    display: flex;
    > a {
      cursor: pointer;
      font-size: 12px;
      margin-right: 0.5rem;
    }
  }
`

const IconRow = styled.div`
  width: 100%;
  display: flex;
`
