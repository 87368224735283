import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import CustomRowTable from '../CustomRowTable/CustomRowTable'
import Archive from '../Archive/Archive'

const ARCHIVE_MUTATION = gql`
  mutation ArchiveCustomerMutation($id: ID!, $status: String) {
    updateCustomer(id: $id, status: $status) {
      id
    }
  }
`

const CustomersList = ({ customers }) => {
  const history = useHistory()
  const [archiveCustomer] = useMutation(ARCHIVE_MUTATION, {
    onCompleted: () => {
      location.reload()
    },
  })
  const onRowClicked = (customer) => {
    history.push(`/customer/${customer.id}`)
  }

  const customerHeaders = [
    { name: 'Customer Name', selector: 'name' },
    { name: 'Email Address', selector: 'email' },
    { name: 'Company Name', selector: 'company' },
    { name: '', selector: 'icon', right: true },
  ]

  customers = customers.map((c) => {
    const archived = c.status === 'archived'
    return {
      ...c,
      name: `${c.firstName} ${c.surname}`,
      to: `/customer/${c.id}`,
      icon: (
        <Archive
          archived={archived}
          onClick={() =>
            archiveCustomer({
              variables: { id: c.id, status: archived ? 'active' : 'archived' },
            })
          }
        />
      ),
    }
  })

  return (
    <CustomRowTable
      columns={customerHeaders}
      rows={customers}
      onRowClicked={onRowClicked}
    />
  )
}

export default CustomersList
