import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { useTitle } from 'react-use'
import React, { useContext, useEffect } from 'react'

import { AuthContext } from '../utils/auth'

import NewIconPage from 'src/pages/NewIconPage/'

import EditIconPage from 'src/pages/EditIconPage/'
import IconPage from 'src/pages/IconPage/'
import IconsPage from 'src/pages/IconsPage/'
import NewProjectPage from 'src/pages/NewProjectPage/'
import EditProjectPage from 'src/pages/EditProjectPage/'
import ProjectPage from 'src/pages/ProjectPage/'
import ProjectsPage from 'src/pages/ProjectsPage/'
import NewToolboxPage from 'src/pages/NewToolboxPage/'
import ToolboxPage from 'src/pages/ToolboxPage/'
import ToolboxesPage from 'src/pages/ToolboxesPage/'
import EditQuotePage from 'src/pages/EditQuotePage/'
import QuotePage from 'src/pages/QuotePage/'
import QuotesPage from 'src/pages/QuotesPage/'
import NewQuotePage from 'src/pages/NewQuotePage/'
import CustomerPage from 'src/pages/CustomerPage/'
import EditCustomerPage from 'src/pages/EditCustomerPage/'
import NewCustomerPage from 'src/pages/NewCustomerPage/'
import CustomersPage from 'src/pages/CustomersPage/'
import AccountPage from 'src/pages/AccountPage/'
import DashboardPage from 'src/pages/DashboardPage/'
import LoginPage from 'src/pages/LoginPage/'
import LandingPage from 'src/pages/LandingPage/'
import SignupPage from 'src/pages/SignupPage/'
import NotFoundPage from 'src/pages/NotFoundPage/'

class AR extends React.Component {
  render() {
    const Component = this.props.component
    const isAuthenticated = this.props.authenticated

    return isAuthenticated ? (
      <Component />
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )
  }
}

class SR extends React.Component {
  render() {
    const Component = this.props.component
    const isAuthenticated = this.props.authenticated
    const isSubscribed = this.props.subscribed

    console.log(isAuthenticated)
    console.log(isSubscribed)

    return isAuthenticated ? (
      isSubscribed ? (
        <Component id={this.props.id ? this.props.id : null} />
      ) : (
        <Redirect to={{ pathname: '/account' }} />
      )
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )
  }
}

const AllRoutes = () => {
  useTitle('ZapQuote')

  const { authenticated, subscribed } = useContext(AuthContext)

  useEffect(() => {
    console.log(authenticated)
    console.log(subscribed)
  }, [])

  return (
    <Switch>
      <Route
        path="/icons/new"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={NewIconPage}
            />
          )
        }}
      />
      <Route
        path="/icons"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={IconsPage}
            />
          )
        }}
      />
      <Route
        path="/toolboxes"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={ToolboxesPage}
            />
          )
        }}
      />
      <Route
        path="/quotes"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={QuotesPage}
            />
          )
        }}
      />
      <Route
        path="/projects"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={ProjectsPage}
            />
          )
        }}
      />
      <Route
        path="/customers"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={CustomersPage}
            />
          )
        }}
      />
      <Route
        path="/projects/new"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={NewProjectPage}
            />
          )
        }}
      />
      <Route
        path="/toolboxes/new"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={NewToolboxPage}
            />
          )
        }}
      />
      <Route
        path="/customers/new"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={NewCustomerPage}
            />
          )
        }}
      />
      <Route
        path="/quotes/new"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={NewQuotePage}
            />
          )
        }}
      />
      <Route
        path="/account"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={AccountPage}
            />
          )
        }}
      />
      <Route
        path="/dashboard"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={DashboardPage}
            />
          )
        }}
      />
      <Route
        path="/landing"
        render={() => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={LandingPage}
            />
          )
        }}
      />
      <Route
        path="/toolbox/:id"
        render={(props) => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={ToolboxPage}
              id={props.match.params.id}
            />
          )
        }}
      />
      <Route
        path="/project/:id"
        render={(props) => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={ProjectPage}
              id={props.match.params.id}
            />
          )
        }}
      />
      <Route
        path="/project/:id/edit"
        render={(props) => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={EditProjectPage}
              id={props.match.params.id}
            />
          )
        }}
      />
      <Route
        path="/icons/:id"
        render={(props) => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={IconPage}
              id={props.match.params.id}
            />
          )
        }}
      />
      <Route
        path="/icons/:id/edit"
        render={(props) => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={EditIconPage}
              id={props.match.params.id}
            />
          )
        }}
      />
      <Route
        path="/customer/:id"
        render={(props) => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={CustomerPage}
              id={props.match.params.id}
            />
          )
        }}
      />
      <Route
        path="/customers/:id/edit"
        render={(props) => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={EditCustomerPage}
              id={props.match.params.id}
            />
          )
        }}
      />
      <Route
        path="/quote/:id"
        render={(props) => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={QuotePage}
              id={props.match.params.id}
            />
          )
        }}
      />
      <Route
        path="/quotes/:id/edit"
        render={(props) => {
          return (
            <SR
              subscribed={subscribed}
              authenticated={authenticated}
              component={EditQuotePage}
              id={props.match.params.id}
            />
          )
        }}
      />
      <Route path="/" exact>
        <Redirect to="/dashboard" />
      </Route>
      <Route path="/account">
        <AccountPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/signup">
        <SignupPage />
      </Route>
    </Switch>
  )
}

export default AllRoutes
