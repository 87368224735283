import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery, gql } from '@apollo/client'

import Toolbox from 'src/components/Toolbox'
import { Form } from '@redwoodjs/web/dist/form/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import InnerContainer from 'src/components/Layout/InnerContainer'
import TopBar from 'src/components/Layout/TopBar'
import RightBar from 'src/components/Layout/RightBar'

import PageHeader from 'src/components/PageHeader/PageHeader'
import Input from 'src/components/Input/Input'
import Back from '../Back/Back'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    toolbox(id: $id) {
      id
      name
      type
      fittings
      userId
      projects {
        id
      }
    }
  }
`

const WrapperForToolbox = ({ id }) => {
  const [search, setSearch] = useState(null)
  const [toolbox, setToolbox] = useState(null)
  const handleInput = ({ target: { value } }) => setSearch(value)

  const { loading, error, data } = useQuery(QUERY, { variables: { id } })

  const updateToolboxFittings = (fittings) => {
    // replace fittings in toolbox state
    setToolbox({ ...toolbox, fittings })
  }

  useEffect(() => {
    data && data.toolbox && setToolbox(data.toolbox)
  }, [data])

  if (loading) return <LoadingIcon />
  if (error) return <p>Error {error ? JSON.stringify(error) : null}</p>
  if (!data.toolbox) {
    return <p>Empty ting</p>
  } else {
    return (
      <>
        <TopBar>
          <Back className="mt-1" routeTo={'/toolboxes'} />
          <CurrentToolTitle>Toolbox</CurrentToolTitle>
        </TopBar>
        <InnerContainer>
          {toolbox && (
            <Toolbox
              search={search}
              toolbox={toolbox}
              updateToolboxFittings={updateToolboxFittings}
            />
          )}
          <RightBar></RightBar>
        </InnerContainer>
      </>
    )
  }
}

export default WrapperForToolbox

const ToolboxName = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */
  width: 100%;
  color: #ffffff;
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: #379ecc;
`

const Edit = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 1rem;
  text-decoration-line: underline;
  cursor: pointer;
  color: #ffffff;
`

const CurrentToolTitle = styled.span`
  margin-right: 2rem;
  color: white;
  font-family: Josefin Sans;
  font-style: normal;
  font-size: 26px;
`

/*
      <>
        <PageHeader
          back={'/toolboxes'}
          title="Toolbox Editor"
          text={null}
          button={
            <Form>
              <Input
                placeholder="Search Icons"
                icon={<StyledIcon icon={faSearch} />}
                white
                onChange={handleInput}
              />
            </Form>
          }
        />
        <Toolbox search={search} toolbox={data.toolbox} />
      </>
      */
