import CustomersLayout from 'src/layouts/CustomersLayout'
import NewCustomer from 'src/components/NewCustomer'

const NewCustomerPage = () => {
  return (
    <CustomersLayout>
      <NewCustomer />
    </CustomersLayout>
  )
}

export default NewCustomerPage
