import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import ToolboxFormNew from 'src/components/ToolboxForm/ToolboxFormNew'
import { useHistory } from 'react-router-dom'

import { ModalContext } from 'src/utils/ModalContext'

const CREATE_POST_MUTATION = gql`
  mutation CreateToolboxMutation($name: String) {
    createToolbox(name: $name, status: "active", type: "user") {
      id
    }
  }
`

const NewToolbox = () => {
  const history = useHistory()
  const { closeModal } = useContext(ModalContext)
  const [createToolbox, { loading, error }] = useMutation(
    CREATE_POST_MUTATION,
    {
      onCompleted: ({ createToolbox: { id } }) => {
        closeModal()
        history.push(`/toolbox/${id}`)
      },
    }
  )

  const onSave = (input) => {
    console.log(input)
    createToolbox({ variables: { ...input } })
  }

  return <ToolboxFormNew onSave={onSave} loading={loading} error={error} />
}

export default NewToolbox
