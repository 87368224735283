import { useState, useCallback, useEffect, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import IconForm from 'src/components/IconForm/IconForm'
import IconFormNew from 'src/components/IconForm/IconFormNew'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

import { UploaderContext } from 'src/utils/uploader'

// TODO
/**
 *  Currently using old method of searching fitting collection
 *
 *  Now needs to simply find the fitting in the toolboxes fittings array, and edit that.
 *
 *  Make sure it updates list
 */

const UPDATE_POST_MUTATION = gql`
  mutation AddToToolbox($fitting: FittingInput, $toolboxId: String) {
    addFittingToToolbox(fitting: $fitting, toolboxId: $toolboxId) {
      fittings
    }
  }
`

const WrapperForEditFitting = ({
  id,
  close,
  toolbox,
  inProject,
  toolboxUpdateSave,
  displayIcons,
  updateToolboxFittings,
  test,
  project,
}) => {
  const { uploadFile, deleteFileByUrl } = useContext(UploaderContext)
  const [image, setImage] = useState(null)
  const [imageId, setImageId] = useState(null)
  const [icon, setIcon] = useState(null)
  const [updateFitting, result] = useMutation(UPDATE_POST_MUTATION, {
    onCompleted: ({ addFittingToToolbox: { fittings } }) => {
      updateToolboxFittings && updateToolboxFittings(fittings)
      if (close) {
        close()
      }
    },
  })

  useEffect(() => {
    // more json parsing... to be sorted asap
    // let fittingsParsed = toolbox.fittings.map((fitting) => JSON.parse(fitting))
    // test
    let fittingsParsed = displayIcons
    console.log(id)
    setIcon(fittingsParsed.find((fitting) => fitting.id == id))
    console.log(fittingsParsed.find((fitting) => fitting.id == id))
  }, [])

  const onUpload = useCallback(async (files) => {
    const blob = new Blob([files[0]], { type: 'application/pdf' })
    const url = await uploadFile(
      `user_data/${toolbox.userId}/datasheets/${id}.pdf`,
      blob
    )
    let fittingInput = { id }
    fittingInput.datasheet = url
    await updateFitting({
      variables: { fitting: fittingInput, toolboxId: toolbox.id },
    })
    setIcon({ ...icon, datasheet: url })
    return
  })

  const onSave = (input, id) => {
    console.log(input)
    let fittingInput = { id }
    if (input.name) fittingInput.name = input.name
    if (input.category) fittingInput.category = input.category
    if (input.make) fittingInput.make = input.make
    if (input.model) fittingInput.model = input.model
    if (input.notes) fittingInput.notes = input.notes
    if (input.partNumber) fittingInput.partNumber = input.partNumber
    if (input.priceToSupply)
      fittingInput.priceToSupply = parseFloat(input.priceToSupply)
    if (input.priceToInstall)
      fittingInput.priceToInstall = parseFloat(input.priceToInstall)

    //currently saves to variation before saving to toolbox!
    inProject && toolboxUpdateSave(fittingInput)

    updateFitting({
      variables: { fitting: fittingInput, toolboxId: toolbox.id },
    })
  }

  return (
    <>
      {icon ? (
        <IconFormNew
          icon={icon}
          image={image}
          onSave={onSave}
          error={result.error}
          loading={result.loading}
          editing
          onUpload={onUpload}
        />
      ) : (
        <LoadingIcon />
      )}
    </>
  )
}

export default WrapperForEditFitting
