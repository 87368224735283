import EditProjectCell from 'src/components/EditProjectCell'
import WrapperForEditProject from 'src/components/Wrappers/WrapperForEditProject'
import styled from 'styled-components'

const EditProjectPage = ({ id }) => {
  return (
    <FormContainer>{id && <WrapperForEditProject id={id} />}</FormContainer>
  )
}

export default EditProjectPage

const FormContainer = styled.div`
  width: 60%;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
`
