import styled from 'styled-components'

export const ModalContainer = styled.div`
  padding: 2rem;
  background-color: white;
  border-radius: 20px;
`

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModalTitle = styled.span`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 150% */

  color: #001733;
`
