import { useCallback, useState, useEffect, useContext, useReducer } from 'react'
import { useMutation } from '@apollo/client'
import { routes, navigate } from '@redwoodjs/router'
import styled, { css } from 'styled-components'
import Fullscreen from 'react-full-screen'
import { useToggle, useWindowSize } from 'react-use'
import { useDrop } from 'react-dnd'
import update from 'immutability-helper'
import { AuthContext } from '../../utils/auth'
import { UploaderContext } from '../../utils/uploader'
import { useHistory } from 'react-router-dom'

import LoadingZone from 'src/components/LoadingZone'

import { fileFetch, toBase64, fileUpload } from 'src/utils/pdfTransfer'

import Row from '../Row/Row'
import Draggable from '../Draggable/Draggable'
import DragNDrop from '../DragNDrop/DragNDrop'
import ProjectToolbox from '../ProjectToolbox/ProjectToolbox'
import Column from '../Column/Column'
import Button from '../Button/Button'
import LoadingIcon from '../LoadingIcon/LoadingIcon'
import PdfEditor from '../PdfEditor/PdfEditor.js'

export const UPLOAD_FILE_MUTATION = gql`
  mutation UpdateVariation(
    $id: ID!
    $file: String
    $workingFile: String
    $displayFile: String
  ) {
    updateVariation(
      id: $id
      file: $file
      workingFile: $workingFile
      displayFile: $displayFile
    ) {
      id
    }
  }
`

/*

  Icons List:
  - id - Taken from annot "lu" value
  - fittingId - Id that can reference the fitting
  - x - x position of the icon
  - y - y position of the icon
  - Rotation - rotation of the icon
  - Room - room this fitting is in
  - Links - other icons it's linked to by id

*/

const Project = ({
  project,
  active,
  activeVariation,
  iconsList,
  dispatch,
  toolboxUpdateSave,
  estimate,
  setEstimate,
  saveVariation,
  saving,
  savingFull,
  setSavingFull,
}) => {
  const history = useHistory()
  const [uploading, setUploading] = useState(false)
  const { getToken } = useContext(AuthContext)
  const { uploadFile, uploadProgress } = useContext(UploaderContext)
  const { width, height } = useWindowSize()
  const [annotations, setAnnotations] = useState(project.annotations || [])
  const [pdf, setPdf] = useState(null)
  const [workingFile, setWorkingFile] = useState(null)
  const [drag, setDrag] = useState(null)
  const [toolDrag, setToolDrag] = useState(null)
  const [uploadFileMutation] = useMutation(UPLOAD_FILE_MUTATION)
  const [show, toggle] = useToggle(false)
  const [toolbox, setToolbox] = useState(null)
  const [toolboxActive, setToolboxActive] = useState(true)
  const [settled, setSettled] = useState(false)
  const [positions, setPositions] = useState({
    toolbox: {
      top: 80,
      left: width * 0.72,
      title: 'Drag me around',
    },
    slider: { top: 540, left: width * 0.25, title: 'Drag me too' },
  })
  const variationId = project.variations[activeVariation].id

  const updateToolboxFittings = (fittings) => {
    // replace fittings in toolbox state
    setToolbox({ ...toolbox, fittings })
  }

  useEffect(() => {
    if (!pdf && project?.variations[activeVariation].file) {
      if (project?.variations[activeVariation].workingFile) {
        fileFetch(project?.variations[activeVariation].file)
          .then(setPdf)
          .then(
            fileFetch(project?.variations[activeVariation].workingFile).then(
              setWorkingFile
            )
          )
      } else {
        fileFetch(project?.variations[activeVariation].file).then(setPdf)
      }
    }
    if (!pdf && !project?.variations[activeVariation].file) {
      setSettled(true)
    }
  })

  useEffect(() => {
    setToolbox(project.toolbox)
  }, [project.toolbox])

  const updateAnnotations = () => {}

  const [, drop] = useDrop({
    // was 'toolbox'
    accept: 'toolbox',
    drop(item, monitor) {
      console.log('Item dropped:', JSON.stringify(item))
      const delta = monitor.getDifferenceFromInitialOffset()
      const left = Math.round(item.left + delta.x)
      const top = Math.round(item.top + delta.y)
      moveBox(item.id, left, top)
      // setToolDrag(false)
      return undefined
    },
  })

  const moveBox = (id, left, top) => {
    setPositions(
      update(positions, {
        [id]: {
          $merge: { left, top },
        },
      })
    )
  }

  const uploadWorkingFile = async (blob) => {
    setUploading(true)
    const url = await uploadFile(
      `user_data/${project.userId}/projects/${project.id}/working_file.xfdf`,
      blob
    )
    await uploadFileMutation({
      variables: { id: variationId, workingFile: url },
    })
    const file = await fileFetch(url)
    setWorkingFile(file)
    setUploading(false)
    return
  }
  const uploadDisplayFile = async (blob) => {
    setUploading(true)
    const url = await uploadFile(
      `user_data/${project.userId}/projects/${project.id}/display_file.pdf`,
      blob
    )
    await uploadFileMutation({
      variables: { id: variationId, displayFile: url },
    })
    setSavingFull(false)
    setUploading(false)
    return
  }

  const onDrop = useCallback(async (res) => {
    setUploading(true)
    const blob = new Blob([res[0]], { type: 'application/pdf' })
    const url = await uploadFile(
      `user_data/${project.userId}/projects/${project.id}/${Math.round(
        Math.random() * 1000000000
      )}.pdf`,
      blob
    )
    uploadFileMutation({ variables: { id: variationId, file: url } })
    const file = await fileFetch(url)
    setPdf(file)
    setUploading(false)
    //setSettled(false)
  }, [])

  return (
    <ProjectContainer
      active={active}
      enabled={show}
      onChange={(isFull) => toggle(isFull)}
    >
      <DropCover>
        <Holder>
          <MainProject ref={drop}>
            <ProjectDrop>
              {(!project?.variations[activeVariation].workingFile && pdf) ||
              (workingFile && pdf) ? (
                <PdfEditor
                  initialIcons={project.variations[0].icons}
                  iconsList={iconsList}
                  updateIconsList={dispatch}
                  project={project}
                  positions={positions}
                  variationId={variationId}
                  pdf={pdf}
                  workingFile={workingFile}
                  drag={drag}
                  setDrag={setDrag}
                  fullscreen={show}
                  updateAnnotations={updateAnnotations}
                  toggleFullscreen={toggle}
                  toolboxActive={toolboxActive}
                  setToolboxActive={setToolboxActive}
                  settled={settled}
                  setSettled={setSettled}
                  estimate={estimate}
                  setEstimate={setEstimate}
                  uploadWorkingFile={uploadWorkingFile}
                  uploadDisplayFile={uploadDisplayFile}
                  saving={saving}
                  savingFull={savingFull}
                />
              ) : uploading ? (
                <span>
                  {'PDF uploading...  ' +
                    parseFloat(uploadProgress).toFixed(0) +
                    '% Uploaded'}
                </span>
              ) : (
                <DNDHolder>
                  <DragNDrop
                    icon={
                      <PlanText>Drop your plan here to get started</PlanText>
                    }
                    text={<PlanOr>Or</PlanOr>}
                    info={<Button text="Select File" />}
                    onDrop={onDrop}
                  />
                </DNDHolder>
              )}
            </ProjectDrop>
          </MainProject>
          <ToolboxContainer>
            {pdf && toolboxActive && toolbox && (
              <ProjectToolbox
                noEdit
                inProject
                toolboxUpdateSave={toolboxUpdateSave}
                toolbox={toolbox}
                setDrag={setDrag}
                updateToolboxFittings={updateToolboxFittings}
                annotations={annotations}
              />
            )}
          </ToolboxContainer>
        </Holder>
        {<LoadingZone visible={!settled} />}
      </DropCover>
    </ProjectContainer>
  )
}

export default Project

const DNDHolder = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem;
`

const ProjectDrop = styled.div`
  height: 100%;
  width: 100%;
  min-height: 500px;
`

const ProjectContainer = styled(Fullscreen)`
  display: flex;
  flex-direction: row;
  background: white;
  height: 100%;
  width: 100%;
  position: relative
    ${(p) =>
      !p.active &&
      css`
        display: none;
      `};
`

const PlanText = styled.span`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* identical to box height, or 150% */

  text-align: center;

  color: rgba(0, 23, 51, 0.4);
`

const PlanOr = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Dark blue */

  color: #001733;
  opacity: 0.4;
`

const MainProject = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 0px;
`

const ToolboxContainer = styled.div`
  padding-right: 2rem;
  position: absolute;
  top: 46px;
  right: 0px;
`

const DropCover = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const Holder = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
