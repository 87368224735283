import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery, gql } from '@apollo/client'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

import Icon from 'src/components/Icon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: Int!) {
    icon: icon(id: $id) {
      id
      name
      category
      image
      make
      model
      notes
      partNumber
      priceToSupply
      priceToInstall
      toolboxes
    }
  }
`

const WrapperForFitting = ({ id }) => {
  const { loading, error, data } = useQuery(QUERY, { variables: { id } })

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (typeof Empty !== 'undefined' && isEmpty(data)) {
    return <p>Empty ting</p>
  } else {
    return <Icon icon={data} />
  }
}

export default WrapperForFitting
