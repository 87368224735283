import axios from 'axios'
import { REST_URL } from '../constants/urls'

//Local for now
const apiUrl = REST_URL + '/uploadPdf'

export const fileFetch = async (url) => {
  const { data } = await axios.get(url, {
    responseType: 'blob',
    timeout: 30000,
  })
  return data
}

export const fileUpload = async (file, token) => {
  let formData = new FormData()
  formData.append('pdf', file, 'blob.pdf')
  let up = await axios.post(apiUrl, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  console.log(up)
  return up
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
