import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { formatFullAddress } from 'src/utils/utils'

import CustomRowTable from '../CustomRowTable/CustomRowTable'
import Archive from '../Archive/Archive'

const ARCHIVE_MUTATION = gql`
  mutation ArchiveProjectMutation($id: ID!, $status: String) {
    updateProject(id: $id, status: $status) {
      id
    }
  }
`
const ProjectsList = ({ projects }) => {
  const history = useHistory()
  const [archiveProject] = useMutation(ARCHIVE_MUTATION, {
    onCompleted: () => {
      location.reload()
    },
  })
  const onRowClicked = (project) => {
    history.push(`/project/${project.id}`)
  }

  const projectHeaders = [
    { name: 'Address', selector: 'address' },
    { name: 'Customer Name', selector: 'name' },
    { name: 'Reference', selector: 'reference' },
    { name: '', selector: 'icon', right: true },
  ]

  projects = projects.map((p) => {
    let archived = p.status === 'archived'
    return {
      ...p,
      address: formatFullAddress(p),
      name: `${p.customer?.firstName} ${p.customer?.surname}`,
      icon: (
        <Archive
          archived={archived}
          onClick={() =>
            archiveProject({
              variables: { id: p.id, status: archived ? 'active' : 'archived' },
            })
          }
        />
      ),
    }
  })

  return (
    <CustomRowTable
      columns={projectHeaders}
      rows={projects}
      onRowClicked={onRowClicked}
    />
  )
}

export default ProjectsList
