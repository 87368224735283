import styled, { css } from 'styled-components'

const Divider = ({ className, vertical }) => {
  return (
    <DividerComponent
      vertical={vertical}
      className={className}
    ></DividerComponent>
  )
}

export default Divider

const DividerComponent = styled.div`
  opacity: 0.2;
  ${(p) =>
    p.vertical
      ? css`
          align-self: stretch;
          background-color: rgba(255, 255, 255, 0.12);
          border-style: none;
          flex-shrink: 0;
          height: auto;
          margin: 0 4px;
          width: 1px;
        `
      : css`
          width: 100%;
        `}

  border: 1px solid #001733;
`
