import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import ProjectForm from 'src/components/ProjectForm/ProjectForm'
import ProjectFormNew from 'src/components/ProjectForm/ProjectFormNew'
import { useHistory } from 'react-router-dom'

// fine
const CREATE_POST_MUTATION = gql`
  mutation CreateProjectMutation(
    $company: String
    $name: String
    $reference: String
    $address1: String
    $address2: String
    $city: String
    $postCode: String!
    $customerId: String
  ) {
    createProject(
      company: $company
      name: $name
      reference: $reference
      address1: $address1
      address2: $address2
      city: $city
      postCode: $postCode
      customerId: $customerId
      status: "active"
    ) {
      id
    }
  }
`

const CREATE_PROJECT_TOOLBOX = gql`
  mutation AddToProject($id: ID!, $toolboxId: String) {
    createProjectToolbox(id: $id, toolboxId: $toolboxId, type: "project") {
      id
    }
  }
`

// fine
const ADD_TOOLBOX_TO_PROJECT = gql`
  mutation AddToProject($id: ID!, $projectToolboxId: String) {
    updateProject(id: $id, toolboxId: $projectToolboxId) {
      id
    }
  }
`
// NOT USED
const ADD_CUSTOMER_TO_PROJECT = gql`
  mutation AddToProject($id: ID!, $customerId: String) {
    updateProject(id: $id, customerId: $customerId)
  }
`
// fine
const CREATE_VARIATION = gql`
  mutation AddToProject($projectId: String) {
    createVariation(projectId: $projectId, version: 1, status: "Active") {
      id
    }
  }
`
// fine
const CUSTOMERS_QUERY = gql`
  query FIND_POST_BY_ID($status: String) {
    customers(status: $status) {
      id
      firstName
      surname
    }
  }
`

// fine
const TOOLBOXES_QUERY = gql`
  query FIND_POST_BY_ID {
    toolboxes(type: "user", status: "active") {
      id
      name
    }
  }
`

const NewProject = ({ customer }) => {
  const history = useHistory()
  const [customerId, setCustomerId] = useState(customer ? customer : null)
  const [toolboxId, setToolboxId] = useState()
  const [createProjectToolbox] = useMutation(CREATE_PROJECT_TOOLBOX)
  const [addToolboxToProject] = useMutation(ADD_TOOLBOX_TO_PROJECT)
  const [createVariation] = useMutation(CREATE_VARIATION)
  const [createProject, { loading, error }] = useMutation(
    CREATE_POST_MUTATION,
    {
      onCompleted: async ({ createProject: { id } }) => {
        const projectToolbox = await createProjectToolbox({
          variables: {
            id,
            toolboxId,
          },
        })
        const projectToolboxId = projectToolbox.data.createProjectToolbox.id
        addToolboxToProject({
          variables: {
            id,
            projectToolboxId,
          },
        })
        const data = await createVariation({
          variables: { projectId: id },
        })
        console.log(data)
        const vid = data.data.createVariation.id
        history.push(`/project/${id}`)
      },
    }
  )

  const { data: { customers } = {} } = useQuery(CUSTOMERS_QUERY, {
    variables: { status: 'active' },
  })
  const { data: { toolboxes } = {} } = useQuery(TOOLBOXES_QUERY)

  const onSave = (input) => {
    // These don't update the value of the state quickly enough
    setCustomerId(input.customer.key)
    setToolboxId(input.toolbox.key)
    createProject({
      variables: {
        customerId: customerId ? customerId : input.customer.key,
        ...input,
      },
    })
  }

  return (
    <ProjectFormNew
      onSave={onSave}
      loading={loading}
      error={error}
      defaultCustomer={customer}
      customers={customers}
      toolboxes={toolboxes}
    />
  )
}

export default NewProject
