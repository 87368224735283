import styled, { css } from 'styled-components'

import { formatFullAddress } from 'src/utils/utils'

import Button from '../Button/Button'

const Customer = ({ customer, editing, setEditing }) => {
  return (
    <>
      <div className="mt-1">
        <CustomerDataRow>
          <CustomerDataBlock>
            <CustomerDataLabel className="mb-1">First Name</CustomerDataLabel>
            <CustomerDataInfo>{customer.firstName}</CustomerDataInfo>
          </CustomerDataBlock>
          <CustomerDataBlock>
            <CustomerDataLabel className="mb-1">Surname</CustomerDataLabel>
            <CustomerDataInfo>{customer.surname}</CustomerDataInfo>
          </CustomerDataBlock>
        </CustomerDataRow>
        <CustomerDataRow className="mt-2">
          <CustomerDataBlock>
            <CustomerDataLabel className="mb-1">
              Email Address
            </CustomerDataLabel>
            <CustomerDataInfo link>{customer.email}</CustomerDataInfo>
          </CustomerDataBlock>
        </CustomerDataRow>
        <CustomerDataRow className="mt-2">
          <CustomerDataBlock>
            <CustomerDataLabel className="mb-1">Company</CustomerDataLabel>
            <CustomerDataInfo>{customer.company}</CustomerDataInfo>
          </CustomerDataBlock>
        </CustomerDataRow>
        <CustomerDataRow className="mt-2">
          <CustomerDataBlock>
            <CustomerDataLabel className="mb-1">Phone Number</CustomerDataLabel>
            {customer.phone ? (
              <CustomerDataInfo>{customer.phone}</CustomerDataInfo>
            ) : (
              <CustomerDataInfo link onClick={() => setEditing(true)}>
                Add Phone Number
              </CustomerDataInfo>
            )}
          </CustomerDataBlock>
        </CustomerDataRow>
        <CustomerDataRow className="mt-2">
          <CustomerDataBlock>
            <CustomerDataLabel className="mb-1">Address</CustomerDataLabel>
            {customer.address1 ? (
              <CustomerDataInfo>{formatFullAddress(customer)}</CustomerDataInfo>
            ) : (
              <CustomerDataInfo link onClick={() => setEditing(true)}>
                Add Address
              </CustomerDataInfo>
            )}
          </CustomerDataBlock>
        </CustomerDataRow>
        <CustomerDataRow className="mt-2">
          <CustomerDataBlock>
            <Button
              text="Edit Details"
              onClick={() => {
                setEditing(true)
              }}
            />
          </CustomerDataBlock>
        </CustomerDataRow>
      </div>
    </>
  )
}

export default Customer

const CustomerDataRow = styled.div`
  display: flex;
  flex-direction: row;
`

const CustomerDataBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const CustomerDataLabel = styled.span`
  /* Bold */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark blue */
  color: #001733;
`

const CustomerDataInfo = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;

  ${(p) =>
    p.link &&
    css`
      /* identical to box height, or 150% */
      text-decoration-line: underline;

      /* Primary */
      color: #379ecc;
      cursor: pointer;
    `}
`
