import React, { useState, useEffect, useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { routes, navigate } from '@redwoodjs/router'
import SitecontainerLayout from 'src/layouts/SitecontainerLayout'
import styled from 'styled-components'

import { useHistory } from 'react-router-dom'

import PageHeader from 'src/components/PageHeader/PageHeader'
import TableCard from 'src/components/TableCard/TableCard'
import Button from 'src/components/Button/Button'
import ModalComponent from 'src/components/Modal/Modal'
import { ModalContext } from 'src/utils/ModalContext'
import NewProject from 'src/components/NewProject/NewProject'
import NewQuote from 'src/components/NewQuote/NewQuote'

import WrapperForCustomer from 'src/components/Wrappers/WrapperForCustomer'
import WrapperForEditCustomer from 'src/components/Wrappers/WrapperForEditCustomer'

const DELETE_POST_MUTATION = gql`
  mutation DeleteUserMutation($id: ID!) {
    updateCustomer(id: $id, status: "deleted") {
      id
    }
  }
`
// Cleanup with best principles
const PROJECTS_QUERY = gql`
  query ProjectByCustomerID($customerId: ID!) {
    projectsByCustomer(customerId: $customerId) {
      id
      address1
      status
      reference
    }
  }
`

const CUSTOMER_QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    customer(id: $id) {
      id
      firstName
      surname
      email
    }
  }
`

const CustomerPage = ({ id }) => {
  const history = useHistory()
  const { openModal, closeModal } = useContext(ModalContext)
  const [editing, setEditing] = useState(false)
  const { data: { projectsByCustomer = [] } = {} } = useQuery(PROJECTS_QUERY, {
    variables: { customerId: id },
  })
  const { data: { customer = {} } = {} } = useQuery(CUSTOMER_QUERY, {
    variables: { id },
  })
  const [deleteUser] = useMutation(DELETE_POST_MUTATION, {
    onCompleted: () => {
      history.push('/customers')
      location.reload()
    },
  })

  const onDeleteClick = (id) => {
    if (
      confirm(
        `Are you sure you want to delete ${customer?.firstName} ${customer?.surname}?`
      )
    ) {
      deleteUser({ variables: { id } })
    }
  }

  const projectData = projectsByCustomer.map((p) => ({
    ...p,
    name: { value: p.address1, link: `/project/${p.id}` },
    customer: `${customer?.firstName} ${customer?.surname}`,
  }))

  return (
    <SitecontainerLayout noTop noLeft noRight hideSidebar>
      <PageHeader
        back={'/customers'}
        title={
          <CustomerInfoHeaderName>
            {customer?.firstName} {customer?.surname}
          </CustomerInfoHeaderName>
        }
        text={
          <CustomerInfoHeaderEmail>{customer?.email}</CustomerInfoHeaderEmail>
        }
      />
      <CustomerInfoDataContainer>
        <CustomerInfoChildren>
          <TableCard
            cardTitle="Projects"
            cardAction={
              <Button
                btnStyle="secondary"
                noFloat
                onClick={() => openModal(<NewProject customer={id} />)}
                text="New Project"
              />
            }
            tableHeaders={[
              { name: 'Project Name', selector: 'name' },
              { name: 'Reference', selector: 'id' },
              { name: 'Status', selector: 'status' },
            ]}
            tableRows={projectData}
          />
          <TableCard
            cardTitle="Quotes"
            cardAction={
              <ModalComponent
                // className="mb-2"
                modalSize="md"
                button={
                  <Button btnStyle="secondary" noFloat text="New Quote" />
                }
                header="New Quote"
                body={<NewQuote customer={id} />}
              />
            }
            tableHeaders={[
              { name: 'Quote reference', selector: 'id' },
              { name: 'Project', selector: 'project.address1' },
              { name: 'Status', selector: 'status' },
            ]}
            tableRows={customer?.quotes}
            className="mt-2"
          />
          <Button
            onClick={() => onDeleteClick(id)}
            btnStyle="danger"
            text="Delete Customer"
            className="mt-2"
            noFloat
          />
        </CustomerInfoChildren>
        <CustomerInfoChildren className="pl-0">
          <CustomerData>
            <CustomerCellHeader>Customer Details</CustomerCellHeader>
            {editing ? (
              <WrapperForEditCustomer id={id} setEditing={setEditing} />
            ) : (
              <WrapperForCustomer id={id} setEditing={setEditing} />
            )}
          </CustomerData>
        </CustomerInfoChildren>
      </CustomerInfoDataContainer>
    </SitecontainerLayout>
  )
}

export default CustomerPage

const CustomerCellHeader = styled.span`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
`

const CustomerInfoHeaderBlocks = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const CustomerInfoHeaderName = styled.span`
  /* Heading 1 */
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  margin-top: 1rem;
  color: #ffffff;
`

const CustomerInfoHeaderEmail = styled.span`
  /* Link */
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* identical to box height, or 150% */
  text-decoration-line: underline;

  /* White */
  color: #ffffff;
  margin-bottom: 2rem;
`

const CustomerInfoHeaderBack = styled.span`
  /* Bold */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  /* White */
  color: #ffffff;
`

const CustomerInfoDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const CustomerInfoChildren = styled.div`
  margin: 2rem;
  width: 50%;
`

const CustomerData = styled.div`
  background: #ffffff;
  /* Default */
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1.5rem;
`
