import { useEffect, useRef } from 'react'
import axios from 'axios'

const client = require('filestack-js').init('ALhcWxoCDS0WtEDwSze7pz')

export const cloudinaryUpload = async (file) => {
  return axios.post('https://api.cloudinary.com/v1_1/daqsss1jo/upload', {
    file: await toBase64(file),
    upload_preset: 'ml_default',
  })
}

/**
 *
 * @param {
 *
 *
 *
 *
 * } url
 */

export const fileFetch = async (url) => {
  const { data } = await axios.get(url, {
    responseType: 'blob',
    timeout: 30000,
  })
  return data
}

/**
 *
 * @param {
 *
 *
 *
 * } file
 */
export const fileUpload = async (file) => {
  return await client.upload(file)
}

export const filePreview = (url, width = 200, height = 200) => {
  if (!url)
    return 'https://process.filestackapi.com/resize=w:150/resize=h:100/yBk3W5tySC2fLKsAAFf7'
  const split = url.split('/')
  const splitLength = split.length
  const id = split[splitLength - 1]
  return `https://process.filestackapi.com/output=format:png/resize=w:${width}/resize=h:${height}/${id}`
}

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  const ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString })
  return blob
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
})

export const customersToOptions = (customers, defaultCustomer) => {
  if (!customers) return []
  return customers.map((c, idx) => ({
    id: idx,
    title: `${c.firstName} ${c.surname}`,
    selected: defaultCustomer ? c.id === defaultCustomer : false,
    key: c.id,
  }))
}
export const toolboxesToOptions = (toolboxes) => {
  if (!toolboxes) return []
  return toolboxes.map((t, idx) => ({
    id: idx,
    title: t.name,
    selected: false,
    key: t.id,
  }))
}

export const projectsToOptions = (projects) => {
  if (!projects) return []
  return projects.map((p, idx) => ({
    id: idx,
    title: p.address1,
    selected: false,
    key: p.id,
  }))
}

export const formatFullAddress = (obj) => {
  const { address1, address2, city, postCode } = obj
  return `${address1} ${city}, ${postCode}`
}

export const lowercase = (str) => {
  return typeof str === 'string' ? str.toLowerCase() : ''
}

export const lowercaseKeys = (obj) => {
  const acc = {}
  for (const key of Object.keys(obj)) acc[lowercase(key)] = obj[key]
  return acc
}

export const strToBase64 = (str) => {
  return Buffer.from(str).toString('base64')
}

/**
 *
 * @param {
 *
 *
 *
 * } file
 */

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const imageURLToBase64 = (url) =>
  new Promise((resolve, reject) => {
    fetch(url)
      .then((r) => r.blob())
      .then(async (blob) => {
        const base64 = toBase64(blob)
        resolve(base64)
      })
  })

export const base64ToArrayBuffer = (base64) => {
  // decode base64 string, remove space for IE compatibility
  var binary_string = atob(
    base64.replace('data:application/pdf;base64,', '').replace(/\s/g, '')
  )
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

export const arrayBufferToBase64 = (buffer) => {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

export const useCombinedRefs = (...refs) => {
  return (target) => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(target)
      } else {
        ref.current = target
      }
    })
  }
}

export const categories = [
  { value: 'outlets', label: 'Outlets' },
  { value: 'appliances', label: 'Appliances' },
  { value: 'ac', label: 'A/C' },
]

export const categorizeParts = (parts) => {
  const categorized = {}
  parts.forEach((part) => {
    if (categorized[part.category]) categorized[part.category].push(part)
    else categorized[part.category] = [part]
  })
  return categorized
}

export const toTitleCase = (text) =>
  text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

// Naive implementation - in reality would want to attach
// a window or resize listener. Also use state/layoutEffect instead of ref/effect
// if this is important to know on initial client render.
// It would be safer to  return null for unmeasured states.
export const useDimensions = (ref) => {
  const dimensions = useRef({ width: 0, height: 0 })

  useEffect(() => {
    dimensions.current.width = ref && ref.current ? ref.current.offsetWidth : 0
    dimensions.current.height =
      ref && ref.current ? ref.current.offsetHeight : 0
  }, [])

  return dimensions.current
}

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const rotatePoint = (center, point, angle) => {
  const x = Math.round(
    Math.cos(angle) * (point.x - center.x) -
      Math.sin(angle) * (point.y - center.y) +
      center.x
  )
  const y = Math.round(
    Math.sin(angle) * (point.x - center.x) +
      Math.cos(angle) * (point.y - center.y) +
      center.y
  )
  return { x, y }
}
