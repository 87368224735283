import { Form, FormError, FieldError, Label, Submit } from '@redwoodjs/web'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import Input from '../Input/Input'
import LoadingShield from '../LoadingShield/LoadingShield'
import Row from '../Row/Row'
import Button from '../Button/Button'
import DragNDrop from '../DragNDrop/DragNDrop'

const IconForm = ({
  icon,
  image,
  onSave,
  loading,
  error,
  editing,
  onUpload,
}) => {
  const onSubmit = (data) => {
    onSave(data, icon?.id)
  }

  return (
    <FormContainer>
      {loading && <LoadingShield />}
      <Form onSubmit={onSubmit} error={error}>
        <Row>
          <FormColumn className="mr-1">
            <ImgContainer className="mb-1">
              <Img src={icon.image} />
            </ImgContainer>
            <HalfHeight>
              {icon.datasheet ? (
                <a href={icon.datasheet}>Link</a>
              ) : (
                <DragNDrop onDrop={onUpload} text="Attach datasheet or image" />
              )}
            </HalfHeight>
          </FormColumn>
          <FormColumn className="mr-1">
            <FormRow>
              <FormColumn>
                <FormLabel name="Name" />
                <Input
                  name="name"
                  placeholder="e.g. Main Consumer"
                  defaultValue={icon?.name}
                  validation={{ required: true }}
                />
                <FieldError name="Name" className={CSS.errorMessage} />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormLabel name="Make" />
                <Input
                  name="make"
                  placeholder=""
                  defaultValue={icon?.make}
                  validation={{ required: true }}
                />
                <FieldError name="Make" className={CSS.errorMessage} />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormLabel name="Model" />
                <Input
                  name="model"
                  placeholder=""
                  defaultValue={icon?.model}
                  validation={{ required: true }}
                />
                <FieldError name="Model" className={CSS.errorMessage} />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormLabel name="Part Number" />
                <Input
                  name="partNumber"
                  placeholder=""
                  defaultValue={icon?.partNumber}
                  validation={{ required: true }}
                />
                <FieldError name="Part Number" className={CSS.errorMessage} />
              </FormColumn>
            </FormRow>
          </FormColumn>
          <FormColumn className="ml-1">
            <FormRow>
              <FormColumn>
                <FormLabel name="Price To Supply" />
                <Input
                  name="priceToSupply"
                  placeholder=""
                  defaultValue={icon?.priceToSupply}
                  validation={{ required: true }}
                />
                <FieldError
                  name="Price To Supply"
                  className={CSS.errorMessage}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormLabel name="Price To Install" />
                <Input
                  name="priceToInstall"
                  placeholder=""
                  defaultValue={icon?.priceToInstall}
                  validation={{ required: true }}
                />
                <FieldError
                  name="Price To Install"
                  className={CSS.errorMessage}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormLabel name="Notes" />
                <Input
                  name="notes"
                  placeholder=""
                  defaultValue={icon?.notes}
                  validation={{ required: true }}
                />
                <FieldError name="Notes" className={CSS.errorMessage} />
              </FormColumn>
            </FormRow>
            {editing && (
              <Button
                text="Save Icon"
                type="submit"
                btnStyle="success"
                className="mt-2"
              />
            )}
          </FormColumn>
        </Row>
      </Form>
    </FormContainer>
  )
}

export default IconForm

const FormContainer = styled.div`
  position: relative;
  width: 100%;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(p) =>
    p.noTop
      ? css`
          padding-top: 0rem;
        `
      : css`
          padding-top: 2rem;
        `}
`

const FormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const FormLabel = styled(Label)`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
`

const HalfHeight = styled.div`
  height: 50%;
`

const ImgContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e4eef2;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
`

const Img = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-size: 100%;
  background-image: url(${(p) => p.img}); */
  height: 50%;
  width: 50%;
`
