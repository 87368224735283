import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'

import InputNonRW from 'src/components/Input/InputNonRW'
import {
  FormColumn,
  FormError,
  FormRow,
  FormContainer,
  FormBody,
  FormBox,
  FormFooter,
  RequiredAsterix,
} from 'src/components/FormComponents'
import {
  ModalContainer,
  ModalTitle,
  ModalHeader,
} from 'src/components/ModalComponents'
import CloseButton from 'src/components/CloseButton/CloseButton'
import Button from 'src/components/Button/Button'

import { customersToOptions, toolboxesToOptions } from 'src/utils/utils'
import Select from '../Select/Select'

import { ModalContext } from 'src/utils/ModalContext'

import NewCustomer from 'src/components/NewCustomer'
import NewProject from 'src/components/NewProject'

const ProjectForm = (props) => {
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postCode, setPostCode] = useState('')
  const [name, setName] = useState('')
  const [reference, setReference] = useState('')
  const [customers, setCustomerOptions] = useState([])
  const [toolboxes, setToolboxOptions] = useState([])
  const [error, setError] = useState(null)

  const { closeModal, switchModal } = useContext(ModalContext)

  if (props.customers && props.customers.length && !customers.length) {
    const newCustomers = customersToOptions(
      props.customers,
      props.defaultCustomer
    )
    setCustomerOptions(newCustomers)
  }
  if (props.toolboxes && props.toolboxes.length && !toolboxes.length) {
    const newToolboxes = toolboxesToOptions(props.toolboxes)
    setToolboxOptions(newToolboxes)
  }

  const handleAddress1Change = (e) => {
    setAddress1(e.target.value)
  }

  const handleAddress2Change = (e) => {
    setAddress2(e.target.value)
  }

  const handleCityChange = (e) => {
    setCity(e.target.value)
  }

  const handlePostCodeChange = (e) => {
    setPostCode(e.target.value)
  }

  const handleProjectNameChange = (e) => {
    setName(e.target.value)
  }

  const handleReferenceChange = (e) => {
    setReference(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const selectedCustomer = customers.find(({ selected }) => selected)
    const selectedToolbox = toolboxes.find(({ selected }) => selected)
    if (selectedCustomer && selectedToolbox && name) {
      setError(null)
      console.log({
        customer: selectedCustomer,
        toolbox: selectedToolbox,
        address1,
        address2,
        city,
        postCode,
        name,
        reference,
      })
      props.onSave({
        customer: selectedCustomer,
        toolbox: selectedToolbox,
        address1,
        address2,
        city,
        postCode,
        name,
        reference,
      })
    } else {
      setError('Required fields missing')
    }
  }

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalTitle>New Project</ModalTitle>
        <CloseButton onClick={() => closeModal()} />
      </ModalHeader>
      <hr />
      <FormContainer>
        <FormBody>
          <FormColumn>
            <FormRow>
              <div>
                <label name="Customer">
                  Customer <RequiredAsterix>*</RequiredAsterix>
                </label>

                <Select
                  form
                  title="Select Existing Customer"
                  options={customers}
                  setOptions={setCustomerOptions}
                  disabled={props.defaultCustomer}
                />
                <span>
                  or create a new customer{' '}
                  <a
                    onClick={() =>
                      switchModal(
                        <NewCustomer
                          onComplete={(id) => {
                            switchModal(<NewProject customer={id} />)
                          }}
                        />
                      )
                    }
                  >
                    here
                  </a>
                </span>
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="ProjectName">
                  Project Name <RequiredAsterix>*</RequiredAsterix>
                </label>
                <InputNonRW
                  name="projectName"
                  placeholder="e.g Smith"
                  onChange={handleProjectNameChange}
                />
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="Project Reference">Project Reference</label>
                <InputNonRW
                  name="reference"
                  placeholder="e.g 8359204"
                  onChange={handleReferenceChange}
                />
              </div>
            </FormRow>
          </FormColumn>
          <FormColumn>
            <FormRow>
              <div>
                <label name="Address">Address</label>
                <InputNonRW
                  name="address1"
                  placeholder="Address Line 1"
                  onChange={handleAddress1Change}
                />
                <InputNonRW
                  name="address2"
                  placeholder="Address Line 2"
                  onChange={handleAddress2Change}
                />
                <FormRow noTop>
                  <InputNonRW
                    name="city"
                    placeholder="City"
                    onChange={handleCityChange}
                  />
                  <InputNonRW
                    name="postCode"
                    placeholder="Postcode"
                    onChange={handlePostCodeChange}
                  />
                </FormRow>
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="Toolbox">
                  Toolbox Template <RequiredAsterix>*</RequiredAsterix>
                </label>
                <Select
                  form
                  title="Select Existing Toolbox"
                  options={toolboxes}
                  setOptions={setToolboxOptions}
                />
              </div>
            </FormRow>
          </FormColumn>
        </FormBody>
        <FormFooter>
          <Button
            disabled={props.loading}
            text="Save Details"
            type="submit"
            onClick={handleSubmit}
            btnStyle="success"
            className="mt-2"
          />
          {error && <FormError>{error}</FormError>}
          {props.error && <FormError>{props.error.message}</FormError>}
        </FormFooter>
      </FormContainer>
    </ModalContainer>
  )
}
export default ProjectForm
