import styled from 'styled-components'

import Tabs from '../Tabs/Tabs'
import Back from '../Back/Back'

const PageHeader = ({
  title,
  text,
  button,
  tabs,
  onTabChange,
  back,
  className,
}) => {
  return (
    <PageHeaderContainer>
      <PageHeaderTextContainer className={className}>
        {back && <Back routeTo={back} />}
        <PageHeaderMain>{title}</PageHeaderMain>
        <PageHeaderSub>{text}</PageHeaderSub>
      </PageHeaderTextContainer>
      <TabContainer>
        {tabs && <Tabs onSelect={onTabChange} tabs={tabs} bottom white />}
      </TabContainer>
      <PageHeaderButton>{button}</PageHeaderButton>
    </PageHeaderContainer>
  )
}

export default PageHeader

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* Secondary */
  background: var(--color-top-bar);
  height: 180px;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
`

const PageHeaderTextContainer = styled.div`
  height: 100%;
  flex: 2;
  display: flex;
  margin-left: 3rem;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
`

const PageHeaderMain = styled.span`
  /* Heading 1 */
  font-family: DM Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  /* White */
  color: #ffffff;
`

const PageHeaderSub = styled.span`
  /* Body */
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* identical to box height, or 150% */

  /* White */
  color: #ffffff;
`

const TabContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: flex-end;
`

const PageHeaderButton = styled.div`
  flex: 1;
  margin-right: 3rem;
`
