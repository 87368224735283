const ToolboxIcon = ({ color = '#379ECC' }) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5608 9.06078L21.4397 6.93922C21.1584 6.65797 20.7769 6.5 20.3789 6.5H18V2.75C18 1.50922 16.9908 0.5 15.75 0.5H8.25C7.00922 0.5 6 1.50922 6 2.75V6.5H3.62109C3.22313 6.5 2.84203 6.65797 2.56031 6.93922L0.439219 9.06078C0.157969 9.34203 0 9.72359 0 10.1216V20C0 20.8283 0.671719 21.5 1.5 21.5H22.5C23.3283 21.5 24 20.8283 24 20V10.1211C24 9.72359 23.842 9.34203 23.5608 9.06078ZM7.5 2.75C7.5 2.33609 7.83703 2 8.25 2H15.75C16.163 2 16.5 2.33609 16.5 2.75V6.5H7.5V2.75ZM22.5 20H1.5V15.5H6V16.625C6 16.8322 6.16781 17 6.375 17H7.125C7.33219 17 7.5 16.8322 7.5 16.625V15.5H16.5V16.625C16.5 16.8322 16.6678 17 16.875 17H17.625C17.8322 17 18 16.8322 18 16.625V15.5H22.5V20ZM18 14V12.875C18 12.6678 17.8322 12.5 17.625 12.5H16.875C16.6678 12.5 16.5 12.6678 16.5 12.875V14H7.5V12.875C7.5 12.6678 7.33219 12.5 7.125 12.5H6.375C6.16781 12.5 6 12.6678 6 12.875V14H1.5V10.1211L3.62109 8H20.3784L22.5 10.1211V14H18Z"
        fill={color}
      />
    </svg>
  )
}

export default ToolboxIcon
