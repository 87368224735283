import React from 'react'
import { useQuery, gql } from '@apollo/client'

import Quote from 'src/components/Quote'
import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    document(id: $id) {
      id
      type
      customer {
        id
        surname
        email
        company
      }
      variation {
        id
        icons
        file
        workingFile
        displayFile
        miscCharges {
          id
          name
          price
        }
      }
      projectId
      attachments
      notes
      user {
        id
        lastName
        email
        company
        companyLogo
        insuranceFile
        termsFile
      }
    }
  }
`

const WrapperForDocument = ({ id }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      id,
    },
  })

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (!data.document) {
    return <span>Quote not found</span>
  } else {
    return <Quote quote={data.document} user={data.document.user} />
  }
}

export default WrapperForDocument
