const BackArrow = ({ style = 'default' }) => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.937499 0.183655H0.5625C0.253125 0.183655 0 0.431614 0 0.734675V17.2653C0 17.5683 0.253125 17.8163 0.5625 17.8163H0.937499C1.24687 17.8163 1.5 17.5683 1.5 17.2653V0.734675C1.5 0.431614 1.24687 0.183655 0.937499 0.183655ZM18.75 5.01426H13.5V2.17192C13.5 0.541818 11.4844 -0.284712 10.3031 0.87243L3.57187 7.43875C2.69062 8.30202 2.69062 9.69794 3.57187 10.5612L10.2984 17.1321C11.475 18.2847 13.4953 17.4673 13.4953 15.8326V12.9903H18.7453C19.9922 12.9903 21 11.9984 21 10.7862V7.21835C21 6.00151 19.9922 5.01426 18.75 5.01426ZM19.4953 10.7862C19.4953 11.1903 19.1625 11.5209 18.75 11.5209H12V15.8326C12 16.1586 11.5969 16.324 11.3625 16.0944L4.63125 9.51885C4.33594 9.22957 4.33594 8.76579 4.63125 8.47651L11.3578 1.90559C11.5922 1.676 11.9953 1.83672 11.9953 2.16733V6.47906H18.7453C19.1578 6.47906 19.4953 6.80967 19.4953 7.21375V10.7862Z"
        fill={style === 'default' ? 'white' : '#379ECC'}
      />
    </svg>
  )
}

export default BackArrow
