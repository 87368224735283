import styled from 'styled-components'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

const LoadingShield = () => {
  return (
    <LoadingCover>
      <LoadingIcon />
    </LoadingCover>
  )
}

export default LoadingShield

const LoadingCover = styled.div`
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  background-color: rgba(230, 230, 230, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5rem;
`
