import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import FullLogo from 'src/components/FullLogo'

const LoadingZone = ({ visible }) => {
  return (
    <Container visible={visible}>
      <FullLogo />
      <span>loading...</span>
    </Container>
  )
}

export default LoadingZone

const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
