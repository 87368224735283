import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import BackArrow from '../BackArrow/BackArrow'

const Back = ({ routeTo = '/dashboard', className }) => {
  const history = useHistory()
  // const setRoute = () => n---avigate(routeTo)
  const setRoute = () => history.push(routeTo)

  return (
    <BackContainer onClick={setRoute}>
      <BackArrow />
      <BackText>Back</BackText>
    </BackContainer>
  )
}

export default Back

const BackContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

const BackText = styled.span`
  /* Bold */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  margin-left: 1rem;
  /* White */
  color: #ffffff;
`
