import { useContext } from 'react'
import styled from 'styled-components'

import { DragContext } from '../Draggable/Draggable'

const DragHandle = ({ className }) => {
  const { drag } = useContext(DragContext)
  return (
    <div ref={drag}>
      <DragSVG
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-lines"
        className={`svg-inline--fa fa-grip-lines fa-w-16 ${className}`}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M496 288H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-128H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"
        ></path>
      </DragSVG>
    </div>
  )
}

export default DragHandle

const DragSVG = styled.svg`
  cursor: move;
`
