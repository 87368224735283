import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import SitecontainerLayout from 'src/layouts/SitecontainerLayout'

import InputNonRW from '../../components/Input/InputNonRW'
import Button from '../../components/Button/Button'

import Terms from '../../components/Terms/Terms'

import LoadingIcon from 'src/components/LoadingIcon'

import { AuthContext } from '../../utils/auth'

import { ModalContext } from '../../utils/ModalContext'

const SignupPage = () => {
  const { signUp } = useContext(AuthContext)
  return (
    <SitecontainerLayout style={{ height: '100vh' }} noTop noHeader hideSidebar>
      <StyledContainer>
        <SignupBox signUp={signUp} />
      </StyledContainer>
    </SitecontainerLayout>
  )
}

export default SignupPage

const reduceErrors = (error) => {
  switch (error.code) {
    default:
      return { elements: [], message: 'An error occurred' }
  }
}

const SignupBox = ({ signUp }) => {
  const [firstName, setFirstName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const { openModal, closeModal } = useContext(ModalContext)

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
  }
  const handleSurnameChange = (e) => {
    setSurname(e.target.value)
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }
  const showAgreement = (e) => {
    e.preventDefault()
    if (email !== '' && password !== '' && firstName !== '') {
      openModal(
        <ModalContainer>
          <DashboardInfoCard style={{ width: '100%' }}>
            <DashboardTableContainer>
              <TermSectionHolder>
                <DashboardInfoCardHeader>
                  Terms and Conditions
                </DashboardInfoCardHeader>
                <Terms />
                <Button
                  text="Accept Terms & Conditions"
                  className="mt-2"
                  onClick={handleSubmit}
                />
              </TermSectionHolder>
            </DashboardTableContainer>
          </DashboardInfoCard>
        </ModalContainer>
      )
    } else {
      setError({ message: 'Required fields missing' })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    closeModal()

    setLoading(true)
    let userData = {
      firstName: firstName,
      lastName: surname,
      email: email,
      password: password,
      confirmPassword: password,
    }
    signUp(userData)
      .then((res) => {
        window.location.href = '/dashboard'
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        setError(reduceErrors(err))
      })
  }

  return (
    <DashboardInfoCard>
      <DashboardInfoCardHeaderContainer>
        <DashboardInfoCardHeader>{'Create an account'}</DashboardInfoCardHeader>
      </DashboardInfoCardHeaderContainer>
      <DashboardInfoCardDivider />
      <DashboardTableContainer>
        <StyledForm>
          <AccountDataRow className="pt-0">
            <AccountDataBlock className="mr-1">
              <FormLabel name="First Name">
                First Name <RequiredAsterix>*</RequiredAsterix>
              </FormLabel>
              <InputNonRW name="firstName" onChange={handleFirstNameChange} />
            </AccountDataBlock>

            <AccountDataBlock>
              <FormLabel name="Surname">Surname</FormLabel>
              <InputNonRW name="surname" onChange={handleSurnameChange} />
            </AccountDataBlock>
          </AccountDataRow>
          <AccountDataRow>
            <AccountDataBlock>
              <FormLabel name="Email Address">
                Email Address <RequiredAsterix>*</RequiredAsterix>
              </FormLabel>
              <InputNonRW name="email" onChange={handleEmailChange} />
            </AccountDataBlock>
          </AccountDataRow>
          {/*
          <AccountDataRow>
            <AccountDataBlock>
              <FormLabel name="Company">Company</FormLabel>
              <InputNonRW name="Company" onChange={handleCompanyChange} />
            </AccountDataBlock>
          </AccountDataRow>
          */}
          <AccountDataRow>
            <AccountDataBlock>
              <FormLabel name="Password">
                Password <RequiredAsterix>*</RequiredAsterix>
              </FormLabel>
              <InputNonRW
                name="Password"
                type="password"
                placeholder="At least 8 characters"
                onChange={handlePasswordChange}
              />
            </AccountDataBlock>
          </AccountDataRow>
          <div className="mt-8 text-center">
            {loading ? (
              <LoadingIcon />
            ) : (
              <Button
                text="Start Free Trial"
                className="mt-2"
                onClick={showAgreement}
              />
            )}
          </div>
          <SmallPrint>
            By clicking "Start Free Trial", you agree to our Terms. Learn how we
            collect, use and share your data in our Data Policy and how we use
            cookies and similar technology in our Cookie Policy
          </SmallPrint>
          {error && (
            <ErrorBox>
              <span>{error.message}</span>
            </ErrorBox>
          )}
        </StyledForm>
      </DashboardTableContainer>
      <DashboardInfoCardDivider />
      <DashboardInfoCardFooterContainer>
        <Link to={'/login'} style={{ color: 'var(--color-text-hyperlink)' }}>
          Already have an account? Sign in
        </Link>
      </DashboardInfoCardFooterContainer>
    </DashboardInfoCard>
  )
}

const TermSectionHolder = styled.div`
  height: 100%;
`

const ModalContainer = styled.div`
  width: 60vw;
  height: 60vh;
`

const SmallPrint = styled.p`
  color: gray;
  font-size: var(--text-info-size);
  text-align: center;
  margin-top: 1rem;
`

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DashboardInfoCard = styled.div`
  background: #ffffff;
  /* Default */

  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 40%;
`

const DashboardInfoCardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  height: 2rem;
  padding: 3rem;
`

const DashboardInfoCardFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 2rem;
  padding: 3rem;
`

const DashboardInfoCardHeader = styled.span`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
`

const RequiredAsterix = styled.span`
  color: red;
`

const DashboardTableContainer = styled.div`
  padding: 1.5rem 3rem;
`

const DashboardInfoCardDivider = styled.div`
  width: 90%;
  margin: auto;
  border-bottom: 1px solid rgba(0, 23, 51, 0.2);
`

const TableCardActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 40%;
  cursor: pointer;
`

const StyledForm = styled.form`
  position: relative;
  width: 100%;
`

const FormLabel = styled.label`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
`

const AccountDataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) =>
    props.noTop
      ? css`
          padding-top: 0rem;
        `
      : css`
          padding-top: 1rem;
        `}
`

const AccountDataBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ErrorBox = styled.div`
  display: grid;
  place-items: center;
  padding: 0.5rem 0rem;
  > span {
    color: var(--color-danger-main);
    text-align: center;
    font-size: var(--text-info-size);
  }
`
