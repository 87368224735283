import ToolboxesLayout from 'src/layouts/ToolboxesLayout'
import NewToolbox from 'src/components/NewToolbox'

const NewToolboxPage = () => {
  return (
    <ToolboxesLayout>
      <NewToolbox />
    </ToolboxesLayout>
  )
}

export default NewToolboxPage
