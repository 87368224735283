import { useDropzone } from 'react-dropzone'
import styled, { css } from 'styled-components'

import PaperClip from '../PaperClip/PaperClip'

const DragNDrop = ({ icon, text, info, accept, onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  })
  return (
    <DragAndDropContainer {...getRootProps()} isDragActive={isDragActive}>
      <input {...getInputProps()} />
      {icon && icon}
      {text && <DragAndDropText>{text}</DragAndDropText>}
      {info && <DragAndDropLimitText>{info}</DragAndDropLimitText>}
    </DragAndDropContainer>
  )
}

export default DragNDrop

const DragAndDropContainer = styled.div`
  cursor: pointer;
  background: rgba(0, 23, 51, 0.05);
  border: 1px dashed #1d4474;
  box-sizing: border-box;
  border-radius: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;

  ${(p) =>
    p.isDragActive &&
    css`
      opacity: 0.8;
    `}
`

const DragAndDropText = styled.span`
  /* Bold */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;

  /* Dark blue */
  color: #001733;
`

const DragAndDropLimitText = styled.span`
  /* Body */
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;

  /* Dark blue */
  color: #001733;
  opacity: 0.6;
`
