import Quotes from 'src/components/Quotes'
import LoadingIcon from 'src/components/LoadingIcon'

import { Link } from 'react-router-dom'

export const QUERY = gql`
  query POSTS {
    quotes {
      id
      status
      to {
        id
        surname
        email
        company
      }
      from {
        id
        surname
        email
        company
      }
      attachments
      notes
    }
  }
`

export const Loading = () => <LoadingIcon />

export const Empty = () => {
  return (
    <div className="text-center">
      {'No quotes yet. '}
      <Link
        to={'quotes/new'}
        className="text-blue-500 underline hover:text-blue-700"
      >
        {'Create one?'}
      </Link>
    </div>
  )
}

export const Success = ({ quotes }) => {
  return <Quotes quotes={quotes} />
}
