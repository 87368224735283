import React from 'react'
import ReactDOM from 'react-dom'
import { ModalContext } from '../../utils/ModalContext'

import { useTransition, animated, config } from 'react-spring'

import styled from 'styled-components'

const Modal = () => {
  const { modalContent, closeModal, modal, openModal } = React.useContext(
    ModalContext
  )

  const transitions = useTransition(modal, null, {
    config: config.stiff,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return ReactDOM.createPortal(
    transitions.map(
      ({ item, key, props }) =>
        item && (
          <ModalOverlay onClick={() => closeModal()} key={key} style={props}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
              {modalContent}
            </ModalContainer>
          </ModalOverlay>
        )
    ),
    document.querySelector('#modal-root')
  )
}

export default Modal

const ModalOverlay = styled(animated.div)`
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: var(--z-index-modal);
`

const ModalContainer = styled.div``
