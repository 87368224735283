import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'

import InputNonRW from 'src/components/Input/InputNonRW'
import {
  FormColumn,
  FormError,
  FormRow,
  FormContainer,
  FormBody,
  FormBox,
  FormFooter,
  RequiredAsterix,
} from 'src/components/FormComponents'
import {
  ModalContainer,
  ModalTitle,
  ModalHeader,
} from 'src/components/ModalComponents'
import CloseButton from 'src/components/CloseButton/CloseButton'
import Button from 'src/components/Button/Button'

import { ModalContext } from 'src/utils/ModalContext'

import DragNDrop from '../DragNDrop/DragNDrop'

const IconForm = (props) => {
  const [name, setName] = useState(props.icon.name ? props.icon.name : '')
  const [make, setMake] = useState(props.icon.make ? props.icon.make : '')
  const [model, setModel] = useState(props.icon.model ? props.icon.model : '')
  const [partNumber, setPartNumber] = useState(
    props.icon.partNumber ? props.icon.partNumber : ''
  )
  const [priceToSupply, setPriceToSupply] = useState(
    props.icon.priceToSupply ? props.icon.priceToSupply : '0.00'
  )
  const [priceToInstall, setPriceToInstall] = useState(
    props.icon.priceToInstall ? props.icon.priceToInstall : '0.00'
  )
  const [notes, setNotes] = useState(props.icon.notes ? props.icon.notes : '')
  const [error, setError] = useState(null)

  const { closeModal } = useContext(ModalContext)

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleMakeChange = (e) => {
    setMake(e.target.value)
  }

  const handleModelChange = (e) => {
    setModel(e.target.value)
  }

  const handlePartNumberChange = (e) => {
    setPartNumber(e.target.value)
  }

  const handlePriceToSupplyChange = (e) => {
    let input = e.target.value
    setPriceToSupply(input)
  }

  const handlePriceToInstallChange = (e) => {
    let input = e.target.value
    setPriceToInstall(input)
  }

  const formatSupply = () => {
    console.log(priceToSupply)
    setPriceToSupply(priceToSupply.replace(/[^\d.]/g, ''))
    if (priceToSupply.replace(/[^\d.]/g, '') == '') setPriceToSupply('0.00')
  }

  const formatInstall = () => {
    console.log(priceToInstall)
    setPriceToInstall(priceToInstall.replace(/[^\d.]/g, ''))
    if (priceToInstall.replace(/[^\d.]/g, '') == '') setPriceToInstall('0.00')
  }

  const handleNotesChange = (e) => {
    setNotes(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Todo: validation
    if (isNaN(Number.parseFloat(priceToSupply))) {
      setError('Price to Supply is an invalid value')
      return
    }
    if (isNaN(Number.parseFloat(priceToInstall))) {
      setError('Price to Install is an invalid value')
      return
    }

    setError(null)
    props.onSave(
      {
        name,
        make,
        model,
        partNumber,
        priceToSupply: Number.parseFloat(priceToSupply),
        priceToInstall: Number.parseFloat(priceToInstall),
        notes,
      },
      props.icon.id
    )
  }

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalTitle>Edit Icon</ModalTitle>
        <CloseButton onClick={() => closeModal()} />
      </ModalHeader>
      <hr />
      <FormContainer>
        <FormBody>
          <FormColumn
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
            }}
          >
            <FormRow>
              <FormBox style={{ height: '40%', margin: '1rem 0' }}>
                <Img src={props.icon.image} />
              </FormBox>
            </FormRow>
            <FormRow>
              <FormBox style={{ height: '40%', margin: '1rem 0' }}>
                {props.icon.datasheet ? (
                  <a href={props.icon.datasheet}>Link</a>
                ) : (
                  <DragNDrop
                    onDrop={props.onUpload}
                    text="Attach datasheet or image"
                  ></DragNDrop>
                )}
              </FormBox>
            </FormRow>
          </FormColumn>
          <FormColumn>
            <FormRow>
              <div>
                <label name="Name">Name</label>
                <InputNonRW
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="Make">Make</label>
                <InputNonRW
                  name="make"
                  value={make}
                  onChange={handleMakeChange}
                />
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="Model">Model</label>
                <InputNonRW
                  name="model"
                  value={model}
                  onChange={handleModelChange}
                />
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="PartNumber">Part Number</label>
                <InputNonRW
                  name="PartNumber"
                  value={partNumber}
                  onChange={handlePartNumberChange}
                />
              </div>
            </FormRow>
          </FormColumn>
          <FormColumn>
            <FormRow>
              <div>
                <label name="PriceToSupply">Price To Supply</label>
                <InputNonRW
                  name="priceToSupply"
                  value={priceToSupply}
                  alert={priceToSupply == 0}
                  onChange={handlePriceToSupplyChange}
                  onBlur={formatSupply}
                />
                {priceToSupply == 0 && (
                  <FormError>
                    Alert: This item is currently free for the customer
                  </FormError>
                )}
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="PriceToInstall">Price To Install</label>
                <InputNonRW
                  name="PriceToInstall"
                  value={priceToInstall}
                  alert={priceToInstall == 0}
                  onChange={handlePriceToInstallChange}
                  onBlur={formatInstall}
                />
                {priceToInstall == 0 && (
                  <FormError>
                    Alert: This item is currently free for the customer
                  </FormError>
                )}
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="Notes">Notes</label>
                <InputNonRW
                  name="Notes"
                  value={notes}
                  onChange={handleNotesChange}
                />
              </div>
            </FormRow>
          </FormColumn>
        </FormBody>
        <FormFooter>
          <Button
            disabled={props.loading}
            text="Save Details"
            type="submit"
            onClick={handleSubmit}
            btnStyle="success"
            className="mt-2"
          />
          {error && <FormError>{error}</FormError>}
          {props.error && <FormError>{props.error.message}</FormError>}
        </FormFooter>
      </FormContainer>
    </ModalContainer>
  )
}

export default IconForm

const Img = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-size: 100%;
  background-image: url(${(p) => p.img}); */
  height: 50%;
  width: 50%;
`
