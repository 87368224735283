import SitecontainerLayout from 'src/layouts/SitecontainerLayout/SitecontainerLayout'
import NewCoreLayout from 'src/layouts/NewCoreLayout'
import WrapperForDocument from 'src/components/Wrappers/WrapperForDocument'

const QuotePage = ({ id }) => {
  return (
    <NewCoreLayout hideSidebar>
      {/*id && <QuoteCell id={id} />*/}
      {id && <WrapperForDocument id={id} />}
    </NewCoreLayout>
  )
}

export default QuotePage
