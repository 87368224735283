import { useState } from 'react'
import { Form, FormError, FieldError, Label } from '@redwoodjs/web'
import styled, { css } from 'styled-components'

import Select from 'src/components/Select/Select'
import { customersToOptions, projectsToOptions } from 'src/utils/utils'

import Input from '../Input/Input'
import Button from '../Button/Button'
import LoadingShield from '../LoadingShield/LoadingShield'

const QuoteForm = (props) => {
  const [customers, setCustomerOptions] = useState([])
  const [projects, setProjectOptions] = useState([])
  const [templates, setTemplateOptions] = useState([
    {
      id: 0,
      display: 'Dynamic',
      key: 'dynamic',
      selected: true,
    },
    {
      id: 1,
      display: 'Manual',
      key: 'manual',
      selected: false,
    },
  ])

  const onSubmit = (data) => {
    const selectedCustomer = customers.find(({ selected }) => selected)
    const selectedProject = projects.find(({ selected }) => selected)
    const selectedTemplate = templates.find(({ selected }) => selected)
    data.customer = selectedCustomer
    data.project = selectedProject
    data.template = selectedTemplate
    props.onSave(data, props?.quote?.id)
  }

  if (props.customers && props.customers.length && !customers.length) {
    const newCustomers = customersToOptions(props.customers)
    setCustomerOptions(newCustomers)
  }
  if (props.projects && props.projects.length && !projects.length) {
    const newProjects = projectsToOptions(props.projects)
    setProjectOptions(newProjects)
  }

  return (
    <StyledForm onSubmit={onSubmit} error={props.error}>
      {props.loading && <LoadingShield />}
      <FormColumn>
        <FormError
          error={props.error}
          wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mb-4"
          titleClassName="font-semibold"
          listClassName="mt-2 list-disc list-inside"
        />

        <FormRow noTop>
          <FormColumn>
            <FormLabel
              name="Customer"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <Select
              form
              title="Select Customer"
              options={customers}
              setOptions={setCustomerOptions}
            />
            <FieldError name="Customer" className={CSS.errorMessage} />
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn>
            <FormLabel
              name="Project"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <Select
              form
              title="Select Project"
              options={projects}
              setOptions={setProjectOptions}
            />
            <FieldError name="project" className={CSS.errorMessage} />
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn>
            <FormLabel
              name="Quote Reference"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <Input
              name="reference"
              defaultValue={props.quote?.reference}
              className={CSS.input}
              placeholder="e.g. Quote for Project 1"
              errorClassName={CSS.inputError}
              validation={{ required: true }}
            />
            <FieldError name="reference" className={CSS.errorMessage} />
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn>
            <FormLabel
              name="Template"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <Select
              form
              title="Select Template"
              options={templates}
              setOptions={setTemplateOptions}
            />
            <FieldError name="template" className={CSS.errorMessage} />
          </FormColumn>
        </FormRow>

        <div className="mt-8 text-center">
          <Button
            disabled={props.loading}
            text="Create Quote"
            type="submit"
            btnStyle="primary"
            className="mt-2"
          />
        </div>
      </FormColumn>
    </StyledForm>
  )
}

export default QuoteForm

const StyledForm = styled(Form)`
  position: relative;
  width: 100%;
`

const FormLabel = styled(Label)`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(p) =>
    p.noTop
      ? css`
          padding-top: 0rem;
        `
      : css`
          padding-top: 2rem;
        `}
`

const FormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
