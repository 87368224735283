import React, { createContext, useState, useEffect } from 'react'
import Modal from '../components/ModalNew/Modal'

import { useLocation } from 'react-router-dom'

let ModalContext
let { Provider } = (ModalContext = createContext())

let ModalProvider = ({ children }) => {
  const location = useLocation()
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState("I'm the Modal Content")

  useEffect(() => {
    // closes the modal on url location change
    closeModal()
  }, [location])

  const closeModal = () => {
    setModal(false)
    setModalContent(null)
  }

  const switchModal = (content) => {
    setModalContent(content)
  }

  const openModal = (content) => {
    setModal(true)
    setModalContent(content)
  }

  return (
    <Provider
      value={{
        modal,
        closeModal,
        openModal,
        modalContent,
        switchModal,
      }}
    >
      <Modal />
      {children}
    </Provider>
  )
}

export { ModalContext, ModalProvider }
