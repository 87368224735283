import styled, { css } from 'styled-components'

import Column from '../Column/Column'

const Filter = ({ onClick, active, text, value }) => {
  return (
    <Column>
      <Filters onClick={() => onClick(value)} selected={active}>
        {text}
      </Filters>
      {active && <Underline />}
    </Column>
  )
}

export default Filter

const Filters = styled.span`
  cursor: pointer;
  /* Body */
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: var(--color-text-main);

  opacity: 0.6;

  ${(props) =>
    props.selected &&
    css`
      /* Primary */
      color: var(--color-text-active);
    `}
`

const Underline = styled.div`
  border: 1px solid var(--color-text-active);
`
