import { useMutation } from '@redwoodjs/web'
import CustomerForm from 'src/components/CustomerForm'
import LoadingIcon from 'src/components/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    customer(id: $id) {
      id
      email
      firstName
      surname
      phone
      company
      address1
      address2
      city
      postCode
    }
  }
`
const UPDATE_POST_MUTATION = gql`
  mutation UpdateCustomerMutation(
    $id: ID!
    $email: String
    $firstName: String
    $surname: String
    $phone: String
    $company: String
    $address1: String
    $address2: String
    $city: String
    $postCode: String!
  ) {
    UpdateCustomer(
      id: $id
      email: $email
      firstName: $firstName
      surname: $surname
      phone: $phone
      company: $company
      address1: $address1
      address2: $address2
      city: $city
      postCode: $postCode
    ) {
      id
    }
  }
`

export const Loading = () => <LoadingIcon />

export const Success = ({ customer, setEditing }) => {
  const [updateCustomer, { loading, error }] = useMutation(
    UPDATE_POST_MUTATION,
    {
      onCompleted: () => {
        setEditing(false)
      },
    }
  )

  const onSave = (input, id) => {
    updateCustomer({ variables: { id, ...input } })
  }

  return (
    <CustomerForm
      customer={customer}
      onSave={onSave}
      error={error}
      loading={loading}
    />
  )
}

//                 <WrapperForEditCustomer id={id} setEditing={setEditing} />
