import { useMutation } from '@redwoodjs/web'
import { useHistory } from 'react-router-dom'

import CustomRowTable from '../CustomRowTable/CustomRowTable'
import Archive from '../Archive/Archive'
const ARCHIVE_MUTATION = gql`
  mutation ArchiveQuoteMutation($id: ID!) {
    UpdateQuote(id: $id, status: "archived") {
      id
    }
  }
`

const QuotesList = ({ quotes }) => {
  const history = useHistory()
  const [archiveQuote] = useMutation(ARCHIVE_MUTATION, {
    onCompleted: () => {
      location.reload()
    },
  })
  const onRowClicked = (quote) => {
    history.push(`/quote/${quote.id}`)
  }

  const quoteHeaders = [
    { name: 'Customer Name', selector: 'name' },
    { name: 'Email Address', selector: 'to.email' },
    { name: 'Company Name', selector: 'to.company' },
    { name: '', selector: 'icon', right: true },
  ]

  quotes = quotes.map((c) => ({
    ...c,
    name: `${c.to?.firstName} ${c.to?.surname}`,
    icon:
      c.status === 'archived' ? (
        'Archived'
      ) : (
        <Archive onClick={() => archiveQuote({ variables: { id: c.id } })} />
      ),
  }))

  return (
    <CustomRowTable
      columns={quoteHeaders}
      rows={quotes}
      onRowClicked={onRowClicked}
    />
  )
}

export default QuotesList
