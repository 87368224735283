import styled from 'styled-components'

const Terms = () => {
  return (
    <TermsContainer>
      <h3>Welcome to ZapQuote</h3>
      <p>Thanks for using our Services.</p>
      <hr />
      <p>
        ZapQuote provides online business software to small businesses. We
        provide Markup and Quoting Software and trade under the name of ZapQuote
        Limited
      </p>
      <p>
        If You browse any of our Websites or use any of our Services You agree
        to comply with and be bound by these Terms. These Terms are binding on
        any use of the Service and apply to You from the time that ZapQuote
        provides You with access to the Service.
      </p>
      <p>
        These Terms are not intended to answer every question or address every
        issue raised by the use of the Service. It is Your obligation to ensure
        that You have read, understood and agree to the most recent Terms.
      </p>
      <p>
        By registering to use the Service you acknowledge that You have read and
        understood these Terms and have the authority to act on behalf of any
        person for whom You are using the Service. You are deemed to have agreed
        to these Terms on behalf of any entity for whom you use the Service.
      </p>
      <p>
        ZapQuote reserves the right to change these Terms at any time without
        prior notice by updating this page and You agree to be bound by such
        changes. You should visit this page regularly to check the current terms
        & conditions applicable to You.
      </p>
      <hr />
      <h3>Definitions</h3>
      <h5>“ZapQuote”</h5>
      <p>
        means ZapQuote Limited, a company registered in England and Wales whose
        registered office is ZapQuote, Hyde house, 19 station Rd, Addlestone
        KT15 2AL with company number 12313026.
      </p>
      <h5>“Data”</h5>
      <p>
        means any data input or imported by You or with Your authority into the
        Website.
      </p>
      <h5>“Intellectual Property Rights”</h5>
      <p>
        means any registered or unregistered design rights, patents, copyright,
        database rights, data protection rights, trademarks, service marks,
        moral rights, know-how and any other intellectual or industrial property
        rights, anywhere in the world.
      </p>
      <h5>“Invited User”</h5>
      <p>
        means any person or entity, other than the Subscriber, that uses the
        Service with the authorisation of the Subscriber from time to time.
      </p>
      <h5>“Partners”</h5>
      <p>means any partner as defined in clause 12 of these Terms.</p>
      <h5>“Service”</h5>
      <p>
        means the online business software services made available (as may be
        changed or updated from time to time by ZapQuote) via the Website.
      </p>
      <h5>“Subscriber”</h5>
      <p>
        means the person who registers to use the Service, and, where the
        context permits, includes any entity on whose behalf that person
        registers to use the Service.
      </p>
      <h5>“Subscription Fee”</h5>
      <p>
        means the monthly, annual or biannual subscription fee (excluding any
        taxes and duties) payable by You in accordance with the pricing set out
        on the Website (which ZapQuote may change from time to time).
      </p>
      <h5>“Terms”</h5>
      <p>means the Terms and Conditions of Use herein.</p>
      <h5>“Website”</h5>
      <p>
        means any of the images, written material, databases, software or other
        material available at any website owned or operated by ZapQuote.
      </p>
      <h5>“You”</h5>
      <p>
        means the Subscriber, and where the context permits, an Invited User.
        “Your” has a corresponding meaning.
      </p>
      <hr />
      <h3>1. Privacy Policy</h3>
      <p>
        1.1 Your privacy is important to us. Our Privacy Policy forms part of
        these Terms.
      </p>
      <hr />
      <h3>2. Access Conditions</h3>
      <p>
        2.1 Clear Books grants You the right to access and use the Website and
        Services with the user roles according to your Subscription. This right
        is non-exclusive and non-transferable and is limited by and subject to
        this agreement. You must only use the Website and Services for your own
        lawful internal business purposes.
      </p>
      <p>
        2.2 the Subscriber determines who is an Invited User and what level of
        user role access to the relevant organisation and Service that Invited
        User has;
      </p>
      <p>
        2.3 the Subscriber is responsible for all Invited Users’ use of the
        Service;
      </p>
      <p>
        2.4 the Subscriber controls each Invited User’s level of access to the
        relevant organisation and Service at all times and can revoke or change
        an Invited User’s access, or level of access, at any time and for any
        reason, in which case that person or entity will cease to be an Invited
        User or shall have that different level of access, as the case may be;
      </p>
      <p>
        2.5 if there is any dispute between a Subscriber and an Invited User
        regarding access to any organisation or Service, the Subscriber shall
        decide what access or level of access to the relevant Data or Service
        that Invited User shall have, if any.
      </p>
      <p>
        2.6 You will ensure that all usernames and passwords required to access
        the Website are kept secure and confidential. You will immediately
        notify Zapquote of any unauthorised use of your passwords or any other
        breach of security and ZapQuote will reset your password.
      </p>
      <p>2.7 When accessing and using the Website You must:</p>
      <p>
        a) not attempt to undermine the security or integrity of ZapQuote
        computing systems or networks or the Website;
      </p>
      <p>
        b) not use, or misuse, the Website in any way which may impair the
        functionality of the Website or Services, or impair the ability of any
        other user to use the Website or Services;
      </p>
      <p>
        c) not attempt to gain unauthorised access to the computer system on
        which the Website is hosted or to any materials other than those to
        which you have been given express permission to access;
      </p>
      <p>
        d) not attempt to transmit or input into the Website any files that may
        damage any other person’s computing devices or software; content that
        may be offensive; or material or Data in violation of any law (including
        Data or other material protected by copyright or trade secrets which you
        do not have the right to use);
      </p>
      <p>
        e) not attempt to modify, copy, adapt, reproduce, disassemble, decompile
        or reverse engineer the Services, Website or any software applications
        included in the Website, or as part of the Website;
      </p>
      <p>
        f) only access the Website manually. The use of automated agents,
        robots, or automated software, other than search engine spiders, to view
        the site is forbidden;
      </p>
      <p>g) not attempt to build a copy of our Data.</p>
      <p>
        2.8 By enabling the multi-currency functionality within the Services You
        confirm that You have read and accept the XE.com terms and conditions
        for the use of the XE Currency Datafeed service.
      </p>
      <p>
        2.9 Your use of automated bank account feeds enabled by the Yodlee data
        gathering service (Yodlee Service) from within the Service is subject to
        the Yodlee Terms of Use.
      </p>
      <hr />
      <h3>3. Intellectual Property Rights</h3>
      <p>
        3.1 All Intellectual Property Rights in all the material which comprises
        the Website including, but not limited to, design, structure, layouts,
        graphical images and underlying source code belongs to ZapQuote. All
        rights are reserved.
      </p>
      <p>
        3.2 You acknowledge that, except as otherwise agreed between the parties
        in writing, all Intellectual Property Rights of ZapQuote and the Website
        shall remain with ZapQuote.
      </p>
      <p>
        3.3 If you submit any content to any public area of ZapQuote, including
        blogs, message boards, and forums, You agree only to use such tool for
        lawful and legitimate purposes and not for posting or disseminating any
        material unrelated to the use of the Services. You must ensure that such
        material is not defamatory or offensive, untrue, racially offensive or
        an incitement to racial hatred or otherwise in breach of an individual’s
        right to privacy or human rights or actionable in law in any
        jurisdiction. In the event that you do submit such material to the
        Website, ZapQuote reserves the right to remove it without reference to
        you and co-operate with any investigation by the authorities or court
        order relating to it, any and all consequences that may directly or
        indirectly follow will be entirely your responsibility not the
        responsibility of ZapQuote. You agree to indemnify and hold Zapquote
        harmless from any action or consequences that may arise in such
        circumstances, including any claims by third parties.
      </p>
      <p>
        3.4 By submitting content to any public area of Zapquote, including
        blogs, message boards, and forums, or in response to any request for
        feedback made by ZapQuote or by a third party service provider acting on
        behalf of ZapQuote, You grant ZapQuote a royalty-free, perpetual,
        irrevocable, non-exclusive right and licence to use, reproduce, modify,
        adapt, publish, translate, create derivative works from, distribute,
        communicate to the public, perform and display the content (in whole or
        in part) worldwide and to incorporate it in other works in any form,
        media, or technology now known or later developed, for the full term of
        any rights that may exist in such content. You also permit any
        subscriber to access, display, view, store and reproduce such content
        for personal use.
      </p>
      <p>
        3.5 By submitting content You warrant that You are entitled to and have
        all necessary Intellectual Property Rights over that content.
      </p>
      <hr />
      <h3>4. Trademark Notice</h3>
      <p>
        4.1 ZapQuote.co.uk, ZapQuote and the ZapQuote logo are trademarks of
        ZapQuote Limited
      </p>
      <hr />
      <h3>5. Data Protection</h3>
      <p>
        5.1 ZapQuote may place information concerning You on a database of
        internal use only. ZapQuote will never disclose your details to any
        third party unless required to by law, unless specifically instructed to
        the contrary by the User.
      </p>
      <hr />
      <h3>6. Third Party Websites, Goods and Services</h3>
      <p>
        6.1 ZapQuote provides content from and links to third party websites
        that are not affiliated with or endorsed by ZapQuote (although ZapQuotes
        branding, advertisements or links may appear on these websites) and
        ZapQuote may send e-mail messages to You containing advertisements or
        promotions including links to third parties. ZapQuote has no
        responsibility for the content of a linked website and makes no
        representation as to the quality, suitability, functionality or legality
        of any third party websites which we link to, or to any goods and
        services available from such websites.
      </p>
      <p>
        6.2 All matters concerning goods and services that you purchase from a
        third party website including all contract terms are solely between You
        and the owner of that website. ZapQuote will not be liable for any costs
        or damages to You or any third party arising directly or indirectly out
        of any third party website. You hereby waive any claim You might have
        against ZapQuote with respect to any such websites. The Contracts
        (Rights of Third Parties) Act 1999 shall not apply to this agreement.
      </p>
      <hr />
      <h3>7. Disclaimer of Warranties</h3>
      <p>7.1 You acknowledge that:</p>
      <p>a) You are authorised to access and use the Website;</p>
      <p>
        b) If You are using the Website on behalf of or for the benefit of any
        organisation then ZapQuote will assume that You have the right to do so.
        The organisation will be liable for your actions including any breach of
        these Terms;
      </p>
      <p>
        c) You warrant that where You have registered to use the Website on
        behalf of another person You have the authority to agree to these terms
        on behalf of that person and agree that by registering to use the
        Website You bind the person on whose behalf you act to the performance
        of any and all obligations that You become subject to by virtue of these
        terms without limiting your own personal obligations under these terms.
      </p>
      <p>
        d) The provision of, access to, and use of, the Website is on an “as is,
        where is” basis and at your own risk;
      </p>
      <p>
        e) ZapQuote does not guarantee the availability of the Website or
        services offered on the Website and does not warrant that the use of the
        Website will be uninterrupted or error free. Among other things, the
        operation and availability of the systems used for accessing the
        Website, including public telephone services, computer networks and the
        Internet, can be unpredictable and may from time to time interfere with
        or prevent access to the Website. ZapQuote is not in any way responsible
        for any such interference that prevents your access or use of the
        Website.
      </p>
      <p>
        f) Zapquote is not your accountant or legal adviser and use of the
        Website should not substitute professional accounting, architectural
        Design or legal advice. If you have any accounting, architectural or
        legal questions, contact an accountant, architect or a lawyer.
      </p>
      <p>
        g) It is your sole responsibility to determine that the Website meets
        the needs of your business.
      </p>
      <p>
        h) You remain solely responsible for complying with all applicable
        accounting, tax and other laws. It is your responsibility to check that
        storage of and access to your Data via the Software and the Website will
        comply with laws applicable to you (including any laws requiring you to
        retain records).
      </p>
      <p>
        i) Zapquote reserves the right to revise, change, modify, delete or
        suspend the content of any part of the Website and/or any of the
        services it provides on the Website without notice at any time in its
        sole discretion.
      </p>
      <p>
        7.2 ZapQuote gives no warranty about the Website or the Services
        including as to its accuracy, adequacy or completeness. Without limiting
        the foregoing, ZapQuote does not warrant that the Website will meet your
        requirements or that it will be suitable for your purposes. To avoid
        doubt, all implied conditions or warranties are excluded in so far as is
        permitted by law including, without limitation, warranties of
        merchantability, fitness for purpose, title and non-infringement.
      </p>
      <p>
        7.3 You warrant and represent that You are acquiring the right to access
        and use the Website and agreeing to these Terms for the purposes of a
        business and that, to the maximum extent permitted by law, any statutory
        consumer guarantees or legislation intended to protect non-business
        consumers in any jurisdiction does not apply to the supply of the
        Website or these Terms.
      </p>
      <hr />
      <h3>8. Application Performance</h3>
      <p>
        8.1 ZapQuote denies all liability for the timely operation of the
        Website when used within an Internet environment, where You or a third
        party is providing the computer equipment upon which the product is to
        reside or depend upon for any part of its functionality.
      </p>
      <p>
        8.2 By accepting these Terms You confirm your understanding that the
        timely operation of the Internet and the World Wide Web is governed by
        constraints beyond the control of ZapQuote. You accept that ZapQuote is
        not liable for the perceived slow operation of the Website.
      </p>
      <hr />
      <h3>9. Subscriptions</h3>
      <p>
        9.1 Zapquote may offer a free trial which will allow You to assess if
        the Website is satisfactory for your purpose.
      </p>
      <p>
        9.2 Subscription Fees automatically recur either monthly, annually, or
        biannually depending on your subscription package. Subscription Fees
        begin on your first payment date and are payable in advance of each
        subscription period. Different Subscription Fees may include access to
        different Services.
      </p>
      <p>
        9.3 If Subscription Fees are not paid, Zapquote may suspend or terminate
        Your and any Invited Users’ use of the Service or Your or any Invited
        Users’ rights of access to all or any Data.
      </p>
      <p>
        9.4 Your subscription is activated by providing the information required
        on the subscription page. You will pay ZapQuote or ZapQuotes’
        distributor (as directed by Zapquote) the charge for the Subscription
        Fees by credit/debit card at the time of order.
      </p>
      <p>
        9.5 No refund will be given for the cancellation or termination of a
        subscription.
      </p>
      <p>
        9.6 A subscription can be cancelled at any time by terminating your
        Service. When a subscription is cancelled no further payment will be
        taken by ZapQuote.
      </p>
      <p>
        9.7 If a subscription is cancelled for any reason and Subscription Fees
        prior to the cancellations date remain unpaid, You will be liable to pay
        all outstanding Subscription Fees to Zapquote.
      </p>
      <p>
        9.8 You must cancel Your Service. ZapQuote will not accept instructions
        to do so. This is to ensure against fraudulent requests to terminate
        Services.
      </p>
      <p>
        9.9 Once a subscription has been cancelled, any Data will no longer be
        accessible by You. After six months that Data will be archived then
        permanently deleted. If You want to retain that Data in the same format
        when you cancel your subscription, please contact support@ZapQuote.co.uk
        who can offer You a low-cost view-only dormant account (a Dormant
        Account). These Terms of Use will apply to any Dormant Accounts.
      </p>
      <p>
        9.10 Pricing of Services is advertised on the pricing and subscription
        pages of each Service Website.
      </p>
      <hr />
      <h3>10. Termination & Remedies</h3>
      <p>
        10.1 ZapQuote is entitled to restrict, suspend or terminate your
        subscription or deny You access to the Website without notice in its
        sole discretion.
      </p>
      <p>
        10.2 ZapQuote shall be entitled to disclose your user identity and
        details if required or requested by the courts or other law enforcement
        authorities and/or agencies or in such other circumstances as ZapQuote
        in its sole discretion considers reasonably necessary or appropriate.
      </p>
      <hr />
      <h3>11. Indemnity</h3>
      <p>
        11.1 You indemnify ZapQuote against all claims, costs, damage and loss
        arising from your breach of any of these Terms or any obligation You may
        have to ZapQuote, including but not limited to any costs relating to the
        recovery of any Subscription Fee that have not been paid by You.
      </p>
      <p>
        11.2 Your use of any information or materials on this Website is
        entirely at your own risk, for which ZapQuote shall not be liable. It
        shall be your responsibility to ensure that the Website, its products,
        services and information, meet your specific requirements.
      </p>
      <hr />
      <h3>12. Partners</h3>
      <p>
        12.1 ZapQuote regularly partners with accountants, bookkeepers and
        resellers among other professional service providers. Partners are not
        endorsed or approved by Clear Books and Clear Books is not responsible
        for the quality of their service. It is Your responsibility to vet
        Partners for the service you require from them.
      </p>
      <hr />
      <h3>13. Referral scheme for Clear Books users</h3>
      <p>
        13.1 ZapQuote may run affiliate schemes for our Services whereby
        Subscribers can receive a benefit if you refer our Services to a third
        party who signs up and pays for the Services. We reserve the right to
        alter or remove the terms of our affiliate schemes at any time.
      </p>
      <p>
        13.2 ZapQuote reserves the right to remove You from the affiliate scheme
        at any time and refuse payment of any monies owed. Reasons for such
        action include using spam techniques to promote Your affiliation.
      </p>
      <p>
        13.3 Partners, such accountants, bookkeepers and resellers, are not
        eligible to participate in our affiliate schemes.
      </p>
      <hr />
      <h3>14. Data Ownership</h3>
      <p>
        14.1 Ownership of the underlying data and right to access belongs to the
        account payer where the account payer is an owner or shareholder of the
        organisation to which the data relates. If the account payer is not an
        owner or shareholder of the organisation, for example a Partner, then
        the data ultimately belongs to the owner or shareholder of the
        organisation to which the data relates.
      </p>
      <p>
        14.2. You will indemnify ZapQuote against any claims or loss relating
        to:
      </p>
      <p>
        i. ZapQuotes’ refusal to provide any person access to Your information
        in accordance to these Terms,
      </p>
      <p>
        ii. ZapQuotes’ making available information or Data to any person with
        your authorisation
      </p>
      <p>
        14.3 Access to the data is contingent on full payment of the
        Subscription Fee when due. You grant ZapQuote a licence to use, copy,
        transmit, store and back up Data for the purposes of enabling You to
        access and use the Website and for any other purpose related to the
        provision of any services to You.
      </p>
      <p>
        14.4 You must maintain copies of all data inputted into the Website.
        ZapQuote adheres to its best practice, policies and procedures to
        prevent data loss, including a daily system data back-up regime but does
        not make any guarantees that there will be no loss of data. Zapquote
        expressly excludes liability for any loss of data no matter how caused.{' '}
      </p>
      <p>
        14.5 If You enable third-party applications for use in conjunction with
        the Website, You acknowledge that ZapQuote may allow the providers of
        those third-party applications to access Your Data as required for the
        interoperation of such third-party applications with the Website.
        ZapQuote shall not be responsible for any disclosure, modification or
        deletion of Your Data resulting from any such access by third-party
        application providers.{' '}
      </p>
      <hr />
      <h3>15. Limitation of Liability</h3>
      <p>
        15.1. To the maximum extent permitted by law, ZapQuote excludes all
        liability and responsibility to You (or any other person) in contract,
        tort (including negligence), or otherwise, for any loss (including loss,
        but not limited to, of information, Data, profits, savings, business or
        business opportunities, or goodwill) or damage resulting, directly or
        indirectly, from any use of, or reliance on, the Service or Website even
        if ZapQuote is advised of the possibility of such loss or damage.
      </p>
      <p>
        15.2. If You suffer loss or damage as a result of ZapQuotes’ negligence
        or failure to comply with these Terms that is not excluded by clause
        15.1, any claim by You will be limited in respect of any one incident,
        or series of connected incidents, to the Subscription Fee paid by You in
        the previous 12 months.
      </p>
      <p>
        15.3. If any loss is caused by You or by a third party given a right of
        access to the Website by You, ZapQuote shall have no liability for that
        loss.
      </p>
      <p>
        15.4. If You are not satisfied with the Service, Your sole and exclusive
        remedy is to terminate these Terms.
      </p>
      <hr />
      <h3>16. Support</h3>
      <p>
        16.1 ZapQuote aims to (i) respond to all software support queries within
        1 day of them being raised between Monday and Friday 9am - 5pm, and to
        (ii) respond to all software support queries within 2 days of them being
        raised on weekends and bank holidays between 9am and 5pm but does not
        warrant that this will be the case.
      </p>
      <p>
        16.2 Support is not intended to deal with any questions relating to
        accountancy, taxation or company law. Our support agents will only help
        You learn how to use our Services.
      </p>
      <p>
        16.3 The support service is intended to provide timely, informal help
        and as such any reliance placed upon it is at Your risk. Any opinions or
        statements made by a support agent should be checked with a relevant
        professional. To the fullest extent permissible by law ZapQuote does not
        accept any responsibility for the opinions or statements expressed by
        its support agents. Without this limitation of liability we would not be
        able to provide this service.
      </p>
      <p>
        16.4 You agree that ZapQuote employees will from time to time be
        required to access Your Data for legitimate business purposes, such as
        to assist You with a support query or to investigate or resolve an issue
        raised by You.
      </p>
      <p>
        16.5 In order to offer comprehensive support for all our customers,
        ZapQuote reserves the right to require customers whose usage of the
        support system is excessive to attend a ZapQuote training session
        charged at the applicable rates at the relevant time. The meaning of
        ‘excessive’ is to be determined at ZapQuotes’ sole discretion, but may
        include customers who:
      </p>
      <hr />
      <p>(i) utilise more than four hours of support agent time per month;</p>
      <p>(ii) raise multiple support tickets with the same query; or</p>
      <p>
        (iii) repeatedly fail to check ZapQuotes’ online resources where those
        resources would have provided an adequate response to the query.
      </p>
      <hr />
      <p>
        If You refuse to attend such training, ZapQuote reserves the right to
        withdraw support from You.
      </p>
      <p>
        16.6 ZapQuote reserves the right to withdraw support from You if You
        send malicious, offensive or illegal support requests. The meaning of
        the terms “malicious” and “offensive” is to be determined at ZapQuotes’
        sole discretion.
      </p>
      <hr />
      <h3>17. Custom Accelerated Development</h3>
      <p>
        17.1 By paying for a Custom Accelerated Development project you
        acknowledge that you are paying for the prioritisation of development
        work only. You will have no rights to the Intellectual Property Rights
        created or advanced as a result of your payment.
      </p>
      <p>
        For clarification, all Intellectual Property Rights as outlined in
        clause 3 will apply to any and all Custom Accelerated Development work.
      </p>
      <p>
        17.2 ZapQuote will make available to customers any feature or
        functionality created as a result of a Custom Accelerated Development
        project for a minimum of 12 months after it is first put live to
        customers. After this time Zapquote reserves the right to disable any
        feature or functionality or any element of such feature or functionality
        at its sole discretion.
      </p>
      <p>
        17.3 After a period of one month from the estimated completion date You
        agree that no further development work will be expected of Zapquote. Any
        further development work required after this period would require
        re-negotiation for which You would be charged.
      </p>
      <hr />
      <p>
        18. Additional terms for Account Information Services in the United
        Kingdom (effective as of 14 September 2019)
      </p>
      <p>
        18.1 ZapQuote Limited is regulated by the Financial Conduct Authority as
        an account information service provider under the Payment Services
        Regulations 2017 (PSRs) (registration number 843585).
      </p>
      <p>
        18.2 The account information services will make up part of the Services
        and any references to Data throughout the agreement will be deemed to
        include references to 'consolidated information' and 'transaction
        information' (as defined in sub clause 18.10 below).
      </p>
      <p>
        18.3 As an account information service provider, Clear Books can, with
        Your express permission, receive or access Your bank transaction
        information from Your payment account with a payment service provider in
        order to flow those transactions into Your Clear Books account for clear
        and simple reconciliation. This is called a bank feed, and we use a
        third party provider, Yodlee, to provide these bank feeds from your
        payment service provider to Clear Books.
      </p>
      <p>
        18.4 If You wish to set up a bank feed You will need to explicitly
        consent to Clear Books receiving or accessing Your transaction
        information and, if applicable, accessing Your payment account using the
        prescribed authorisation/authentication method prescribed. In some
        situations, You may be required to periodically re-authorise us to
        access Your payment account on Your behalf.
      </p>
      <p>
        18.5 Once we have retrieved your transaction information, we will import
        it into Your Clear Books account and only use it to provide the Services
        to You in the normal way. The same applies to any consolidated
        information we create out of that transaction information.
      </p>
      <p>
        18.6 You can stop using account information services at any time by
        turning off the bank feed within Clear Books. You may still use the
        Services without the account information services.
      </p>
      <p>
        18.7 Any transaction information imported into Your Clear Books account
        will be a reflection of the transaction information held with Your
        payment service provider (e.g., the information held in your online
        banking transactions). Clear Books is not responsible for any inaccuracy
        or incompleteness of this information.
      </p>
      <p>
        18.8 For the purposes of these additional terms relating to the account
        information services, our maximum aggregate liability to You arising out
        of or in connection with the unauthorised disclosure of Your sensitive
        payment data, where sensitive payment data is used to commit fraud,
        shall be limited to two times the fees paid by You to us in the
        preceding 12 months. Otherwise, the liability limitations in the
        Agreement will apply to the account information services in the normal
        way as part of the Services.
      </p>
      <p>
        18.9 As a technology company, we prefer to communicate with you online.
        If we are required to communicate with You under the PSRs, we will:
      </p>
      <p>
        a. Communicate general messages with a notification on the Website; and
      </p>
      <p>
        b. Communicate messages specific to You, including in the event of
        actual or suspected fraud, to the email address provided by You during
        the registration process.
      </p>
      <p>18.10 Definitions:</p>
      <p>
        “Consolidated information” means a form of information created using
        Your transaction information, as part of the account information
        service.
      </p>
      <p>
        “Payment account” means an account that is used for the execution of
        payment transactions, such as a current account.
      </p>
      <p>
        “Payment service provider” means an entity which provides payment
        services as set out in the PSRs (e.g., a bank).
      </p>
      <p>
        “Sensitive payment data” means Your information that could be reasonably
        used to carry out fraud, including Your personalised security
        credentials (but excluding, in relation to Your payment account(s), the
        name of the account holder and the account number).
      </p>
      <p>
        “Transaction information” means the information We access from Your
        payment account(s) concerning Your payment transactions.
      </p>
      <hr />
      <h3>19. Force Majeure</h3>
      <p>
        19.1 Neither party is liable for delay in meeting its obligations due to
        any cause outside its reasonable control including acts of god, riot,
        war, malicious acts of damage, fires, electricity supply failure,
        Government authority.
      </p>
      <hr />
      <h3>20. Survival</h3>
      <p>
        20.1 Should any provision of these Terms be held to be void, invalid,
        unenforceable or illegal by a court, the validity and enforceability of
        the other provisions shall not be affected thereby. If any provision is
        determined to be unenforceable, You agree to a modification of such
        provision to provide for enforcement of the provision’s intent, to the
        extent permitted by applicable law.
      </p>
      <hr />
      <h3>21. Absence of Waiver</h3>
      <p>
        21.1 Any failure or delay by ZapQuote to enforce any of the Terms or to
        exercise any right under the Terms will not be construed as a waiver to
        any extent of its rights.
      </p>
      <hr />
      <h3>22. Right to Assign</h3>
      <p>
        22.1 ZapQuote may assign its rights and obligations under these Terms
        and upon such assignment ZapQuote shall be relieved of any further
        obligation under these Terms. You may not assign or transfer any rights
        or obligations to any other person without ZapQuotes’ prior written
        consent.
      </p>
      <hr />
      <h3>23. Entire Agreement</h3>
      <p>
        23.1 These Terms and the Privacy Policy supersede and extinguish all
        prior agreements, representations (whether oral or written), and
        understandings and constitute the entire agreement between You and
        ZapQuote.
      </p>
      <hr />
      <h3>24. Delays</h3>
      <p>
        24.1 Neither party will be liable for any delay or failure in
        performance of its obligations under these Terms if the delay or failure
        is due to any cause outside its reasonable control. This clause does not
        apply to any obligation to pay money.
      </p>
      <hr />
      <h3>25. Confidentiality</h3>
      <p>
        25.1 Unless the relevant party has the prior written consent of the
        other or unless required to do so by law each party will preserve the
        confidentiality of all confidential information of the other obtained in
        connection with these Terms. Neither party will, without prior consent
        of the other, disclose or make any confidential information available to
        any person, or use the same for its own benefit, other than as
        contemplated by these Terms. Each party’s obligations under this clause
        will survive termination of these Terms.
      </p>
      <p>
        25.2. The provisions of clause 25.1 shall not apply to any information
        which:
      </p>
      <p>
        a. is or becomes public knowledge other than by a breach of this clause;
      </p>
      <p>
        b. is received from a third party who lawfully acquired it and who is
        under no obligation restricting its disclosure;
      </p>
      <p>
        c. is in the possession of the receiving party without restriction in
        relation to disclosure before the date of receipt from the disclosing
        party;
      </p>
      <p>
        d. is independently developed without access to the Confidential
        Information; or
      </p>
      <p>
        e. is required to be disclosed by court order or as a result of a
        regulatory requirement.
      </p>
      <hr />
      <h3>26. Rights of Third Parties</h3>
      <p>
        26.1 A person who is not a party to these Terms has no right to benefit
        under or to enforce any term of these Terms.
      </p>
      <hr />
      <h3>27. Notices</h3>
      <p>
        27.1 Any notice given under these Terms by either party to the other
        must be by email and will be deemed to have been given on transmission.
        Notices to ZapQuote must be sent to support@ZapQuote.co.uk or to any
        other email address notified by email to You by ZapQuote. Notices to You
        will be sent to the email address which You provided when setting up
        Your access to the Service.{' '}
      </p>
      <hr />
      <h3>28. Governing Law and Jurisdiction</h3>
      <p>
        28.1 Your use of the Website and Services and any terms governing such
        use of the Website and Services are subject to the laws of England and
        Wales and you agree to submit to the exclusive jurisdiction of the
        English courts.
      </p>
    </TermsContainer>
  )
}

export default Terms

const TermsContainer = styled.div`
  margin: 2rem 0rem;
  height: 40vh;
  overflow-y: scroll;
  > p {
  }
  > h3 {
    margin-bottom: 1rem;
  }
  > h5 {
    margin: 1rem 0rem;
  }
`
