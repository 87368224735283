const House = ({ color = '#379ECC' }) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5416 8.88167L18.9999 6.80542V3.58042C18.9999 3.44235 18.888 3.33042 18.7499 3.33042H17.9166C17.7785 3.33042 17.6666 3.44235 17.6666 3.58042V5.71917L11.8411 0.964167C11.3502 0.568198 10.6496 0.568198 10.1586 0.964167L0.458213 8.88167C0.315756 8.99777 0.294136 9.20726 0.40988 9.35001L0.830713 9.86709C0.94631 10.0089 1.15457 10.031 1.29738 9.91667L2.99988 8.52584V18.6508C2.99988 19.019 3.29836 19.3175 3.66655 19.3175H8.99989C9.36808 19.3175 9.66655 19.019 9.66655 18.6508V13.3175L12.3332 13.33V18.6667C12.3332 19.0349 12.6317 19.3333 12.9999 19.3333L18.3332 19.3196C18.7014 19.3196 18.9999 19.0211 18.9999 18.6529V8.52584L20.7024 9.91667C20.8452 10.0327 21.055 10.0111 21.1711 9.86834L21.592 9.35126C21.7078 9.20815 21.6856 8.99825 21.5425 8.88245C21.5422 8.88219 21.5419 8.88193 21.5416 8.88167V8.88167ZM17.662 17.9896H17.6661L13.6661 18.0021V12.6617V12.6617C13.665 12.2948 13.3685 11.9973 13.0016 11.995L9.00156 11.9838C8.63337 11.9826 8.33396 12.2802 8.33281 12.6483C8.33281 12.649 8.33281 12.6497 8.33281 12.6504V17.9896H4.33322V7.43792L10.9999 1.9975L17.6666 7.43792L17.662 17.9896Z"
        fill={color}
      />
    </svg>
  )
}

export default House
