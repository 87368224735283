import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from 'react'

const Select = ({
  setOptions,
  options = [],
  title = '',
  form,
  disabled = false,
}) => {
  const resetThenSet = (id) => {
    options.forEach((item) => (item.selected = false))
    options[id].selected = true
    setOptions(options)
  }
  const [listOpen, setListOpen] = useState(false)
  const [headerTitle, setHeaderTitle] = useState(title)

  const close = () => {
    setListOpen(false)
  }

  const selectItem = (title, id, stateKey) => {
    setListOpen(false)
    resetThenSet(id, stateKey)
  }

  const toggleList = () => {
    setListOpen(!listOpen)
  }

  useEffect(() => {
    const newTitle = options.find((o) => o.selected)?.title
    if (newTitle && newTitle !== title) {
      setHeaderTitle(newTitle)
    }
    // if (listOpen) {
    //   window.addEventListener('click', close)
    // } else {
    //   window.removeEventListener('click', close)
    // }
  })

  return (
    <StyledDropdown form={form} className="dd-wrapper">
      <StyledDropdownHeader
        form={form}
        className="dd-header"
        style={{ cursor: 'pointer' }}
        onClick={() => (disabled ? () => {} : toggleList())}
      >
        <div className="dd-header-title">{headerTitle}</div>
        {listOpen ? (
          <StyledIcon icon={faAngleUp} size="2x" />
        ) : (
          <StyledIcon icon={faAngleDown} size="2x" />
        )}
      </StyledDropdownHeader>
      {listOpen && (
        <ul className="dd-list" onClick={(e) => e.stopPropagation()}>
          {options.map((item) => (
            <StyledListItem
              form={form}
              className="dd-list-item"
              key={item.id}
              onClick={() => selectItem(item.title, item.id, item.key)}
            >
              {item.title} {item.selected && <StyledIcon icon={faCheck} />}
            </StyledListItem>
          ))}
        </ul>
      )}
    </StyledDropdown>
  )
}

const StyledIcon = styled(FontAwesomeIcon)`
  color: #379ecc;
`

const StyledDropdown = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: var(--input-margin);
  color: #001733;
`

const StyledDropdownHeader = styled.div`
  background-color: #fff !important;
  padding: var(--input-padding);
  height: var(--input-height);

  ${(p) =>
    p.form &&
    css`
      /* Dark blue */
      background-color: rgba(0, 23, 51, 0.05) !important;
      /* Dark blue */
      border: 1px solid rgba(0, 23, 51, 0);
      box-sizing: border-box;
      border-radius: 100px;
    `}
`

const StyledListItem = styled.li`
  ${(p) =>
    p.form &&
    css`
      background-color: rgba(0, 23, 51, 0.05);
    `}
`

export default Select
