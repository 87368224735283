import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery, gql } from '@apollo/client'
import Icons from 'src/components/Icons'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query POSTS($category: String) {
    fittings(type: "core", category: $category) {
      id
      name
      category
      image
      make
      model
      notes
      partNumber
      priceToSupply
      priceToInstall
    }
  }
`

const WrapperForFittings = ({ category, toolbox }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: { category },
  })

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (!data.fittings) {
    return <div className="text-center">{'No fittings found. '}</div>
  } else {
    return (
      <Icons
        key="main"
        icons={data.fittings}
        toolbox={toolbox}
        category={category}
      />
    )
  }
}

export default WrapperForFittings
