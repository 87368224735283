import React, { Children, cloneElement } from 'react'
import { useDrag } from 'react-dnd'

export const DragContext = React.createContext()

const style = {
  position: 'absolute',
  zIndex: 99999,
}
const Box = ({ id, left, top, hideSourceOnDrag, children, setToolDrag }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: 'toolbox' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag} />
  }

  return (
    <div
      onDragStart={() => setToolDrag(true)}
      onDragEnd={() => setToolDrag(false)}
      style={{ ...style, left, top }}
    >
      <DragContext.Provider
        value={{
          drag,
        }}
      >
        {children}
      </DragContext.Provider>
    </div>
  )
}
export default Box
