import { useState, useCallback } from 'react'
import { useMutation } from '@redwoodjs/web'
import { navigate, routes } from '@redwoodjs/router'
import IconForm from 'src/components/IconForm'

import { fileFetch, toBase64, fileUpload } from 'src/utils/utils'
import { create, get, extractFile } from 'src/utils/gists'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    icon: icon(id: $id) {
      id
      name
      category
      image
      make
      model
      notes
      partNumber
      priceToSupply
      priceToInstall
    }
  }
`
const UPDATE_POST_MUTATION = gql`
  mutation UpdateIconMutation(
    $id: ID!
    $name: String
    $image: String
    $make: String
    $model: String
    $notes: String
    $partNumber: String
    $priceToSupply: Float
    $priceToInstall: Float
  ) {
    UpdateIcon(
      id: $id
      name: $name
      image: $image
      make: $make
      model: $model
      notes: $notes
      partNumber: $partNumber
      priceToSupply: $priceToSupply
      priceToInstall: $priceToInstall
    ) {
      id
    }
  }
`

export const Loading = () => <LoadingIcon />

export const Success = ({ icon, close }) => {
  const [image, setImage] = useState(null)
  const [imageId, setImageId] = useState(icon.image)
  const [updateIcon, { loading, error }] = useMutation(UPDATE_POST_MUTATION, {
    onCompleted: () => {
      if (close) {
        close(true)
      }
    },
  })

  const onUpload = useCallback(async (files) => {
    var file = files[0]
    var reader = new FileReader()
    reader.onload = async (event) => {
      const svg = event.target.result
      const filename = `${icon.name}.svg`
      const { data } = await create({
        filename,
        content: svg,
      })
      setImageId(data.id)
      const extracted = extractFile(data, filename)
      setImage(extracted)
    }
    reader.readAsText(file)
  }, [])

  const onSave = (input, id) => {
    updateIcon({
      variables: {
        id,
        ...input,
        image: imageId,
        priceToSupply: parseFloat(input.priceToSupply),
        priceToInstall: parseFloat(input.priceToInstall),
      },
    })
  }

  return (
    <IconForm
      icon={icon}
      image={image}
      onSave={onSave}
      error={error}
      loading={loading}
      editing
      onUpload={onUpload}
    />
  )
}
