const ChevronDown = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.60151 7.32343L0.164014 1.80155C-0.0562988 1.58124 -0.0562988 1.22499 0.164014 1.00468L0.496826 0.671864C0.717139 0.451552 1.07339 0.451552 1.2937 0.671864L5.99995 5.46718L10.7062 0.676552C10.9265 0.45624 11.2828 0.45624 11.5031 0.676552L11.8359 1.00936C12.0562 1.22968 12.0562 1.58593 11.8359 1.80624L6.39839 7.32811C6.17808 7.54374 5.82183 7.54374 5.60151 7.32343Z"
        fill="#379ECC"
      />
    </svg>
  )
}

export default ChevronDown
