import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'

import InputNonRW from 'src/components/Input/InputNonRW'
import {
  FormColumn,
  FormError,
  FormRow,
  FormContainer,
  FormBody,
  FormFooter,
  RequiredAsterix,
} from 'src/components/FormComponents'
import {
  ModalContainer,
  ModalTitle,
  ModalHeader,
} from 'src/components/ModalComponents'
import CloseButton from 'src/components/CloseButton/CloseButton'
import Button from 'src/components/Button/Button'

import { ModalContext } from 'src/utils/ModalContext'

const CustomerForm = (props) => {
  const [firstName, setFirstName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postCode, setPostCode] = useState('')
  const [error, setError] = useState(null)

  const { closeModal } = useContext(ModalContext)

  const handleFieldChange = (e) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstName(e.target.value)
        break
      case 'surname':
        setSurname(e.target.value)
        break
      case 'email':
        setEmail(e.target.value)
        break
      case 'phone':
        setPhone(e.target.value)
        break
      case 'company':
        setCompany(e.target.value)
        break
      case 'address1':
        setAddress1(e.target.value)
        break
      case 'address2':
        setAddress2(e.target.value)
        break
      case 'city':
        setCity(e.target.value)
        break
      case 'postCode':
        setPostCode(e.target.value)
        break
      default:
        console.log('error')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Todo: validation
    if (firstName !== '' && email !== '') {
      setError(null)
      props.onSave({
        firstName,
        surname,
        email,
        phone,
        company,
        address1,
        address2,
        city,
        postCode,
      })
    } else {
      setError('First Name and Email are required')
    }
  }

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalTitle>New Customer</ModalTitle>
        <CloseButton onClick={() => closeModal()} />
      </ModalHeader>
      <hr />
      <FormContainer>
        <FormBody>
          <FormColumn>
            <FormRow>
              <div>
                <label name="First Name">
                  First Name <RequiredAsterix>*</RequiredAsterix>
                </label>
                <InputNonRW
                  name="firstName"
                  placeholder="e.g Jo"
                  onChange={handleFieldChange}
                />
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="Surname">Surname</label>
                <InputNonRW
                  name="surname"
                  placeholder="e.g Smith"
                  onChange={handleFieldChange}
                />
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="Email Address">
                  Email Address <RequiredAsterix>*</RequiredAsterix>
                </label>
                <InputNonRW
                  name="email"
                  placeholder="e.g jo@smith.com"
                  onChange={handleFieldChange}
                />
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="Phone">Phone</label>
                <InputNonRW
                  name="phone"
                  placeholder="e.g 0771234567 "
                  onChange={handleFieldChange}
                />
              </div>
            </FormRow>
          </FormColumn>
          <FormColumn>
            <FormRow>
              <div>
                <label name="Company">Company</label>
                <InputNonRW
                  name="company"
                  placeholder="e.g Jo Smith Enterprises"
                  onChange={handleFieldChange}
                />
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label name="Address">Address</label>
                <InputNonRW
                  name="address1"
                  placeholder="Address Line 1"
                  onChange={handleFieldChange}
                />
                <InputNonRW
                  name="address2"
                  placeholder="Address Line 2"
                  onChange={handleFieldChange}
                />
                <FormRow noTop>
                  <InputNonRW
                    name="city"
                    placeholder="City"
                    onChange={handleFieldChange}
                  />
                  <InputNonRW
                    name="postCode"
                    placeholder="PostCode"
                    onChange={handleFieldChange}
                  />
                </FormRow>
              </div>
            </FormRow>
          </FormColumn>
        </FormBody>
        <FormFooter>
          <Button
            disabled={props.loading || !firstName || !email}
            text="Save Details"
            type="submit"
            onClick={handleSubmit}
            btnStyle="success"
            className="mt-2"
          />
          {error && <FormError>{error}</FormError>}
          {props.error && <FormError>{props.error.message}</FormError>}
        </FormFooter>
      </FormContainer>
    </ModalContainer>
  )
}

export default CustomerForm
