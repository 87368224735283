import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'

import InputNonRW from 'src/components/Input/InputNonRW'
import {
  FormColumn,
  FormError,
  FormRow,
  FormContainer,
  FormBody,
  FormFooter,
  RequiredAsterix,
} from 'src/components/FormComponents'
import {
  ModalContainer,
  ModalTitle,
  ModalHeader,
} from 'src/components/ModalComponents'
import CloseButton from 'src/components/CloseButton/CloseButton'
import Button from 'src/components/Button/Button'

import { ModalContext } from 'src/utils/ModalContext'

const ToolboxForm = (props) => {
  const [toolboxName, setToolboxName] = useState('')
  const [error, setError] = useState(null)

  const { closeModal } = useContext(ModalContext)

  const handleToolboxNameChange = (e) => {
    setToolboxName(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Todo: validation
    if (toolboxName !== '') {
      setError(null)
      props.onSave({
        name: toolboxName,
      })
    } else {
      setError('Please enter a toolbox name')
    }
  }

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalTitle>New Toolbox</ModalTitle>
        <CloseButton onClick={() => closeModal()} />
      </ModalHeader>
      <hr style={{ minWidth: '400px' }} />
      <FormContainer>
        <FormBody>
          <FormColumn>
            <FormRow>
              <div>
                <label name="Toolbox">
                  Toolbox Name <RequiredAsterix>*</RequiredAsterix>
                </label>
                <InputNonRW
                  name="toolboxName"
                  placeholder="e.g Standard Shop Fittings"
                  onChange={handleToolboxNameChange}
                />
              </div>
            </FormRow>
          </FormColumn>
        </FormBody>
        <FormFooter>
          <Button
            disabled={props.loading}
            text="Create Toolbox"
            type="submit"
            onClick={handleSubmit}
            btnStyle="success"
            className="mt-2"
          />
          {error && <FormError>{error}</FormError>}
          {props.error && <FormError>{props.error.message}</FormError>}
        </FormFooter>
      </FormContainer>
    </ModalContainer>
  )
}

export default ToolboxForm
