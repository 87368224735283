import format from 'date-fns/format'
import parse from 'date-fns/parse'
import styled from 'styled-components'

import { formatter } from 'src/utils/utils'

import CustomRowTable from '../CustomRowTable/CustomRowTable'
import ChevronRight from '../ChevronRight/ChevronRight'

const Variations = ({
  project: { variations = [] } = {},
  activeVariation,
  setActiveVariation,
}) => {
  const customerHeaders = [
    { name: 'Name', selector: 'name' },
    { name: 'Date Created', selector: 'created' },
    { name: 'Icons', selector: 'iconsUsed' },
    { name: 'Estimate', selector: 'estimate' },
    { name: '', selector: 'icon', right: true },
  ]

  variations = variations.map((v, idx) => ({
    ...v,
    name: `Variation ${idx + 1}`,
    estimate: formatter.format(v.estimate),
    created: v.createdAt,
    icon:
      activeVariation === idx ? (
        <DisabledAction>Active Version</DisabledAction>
      ) : (
        <ActionContainer onClick={() => setActiveVariation(idx)}>
          <Action>Load</Action>
          <ChevronRight />
        </ActionContainer>
      ),
  }))

  return (
    <TableContainer>
      <CustomRowTable columns={customerHeaders} rows={variations} />
    </TableContainer>
  )
}

export default Variations

const TableContainer = styled.div`
  width: 75%;
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 10rem;
`

const Action = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-decoration-line: underline;

  color: #379ecc;
`

const DisabledAction = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16;
  color: #001733;
`
