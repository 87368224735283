import { Link, routes } from '@redwoodjs/router'
import Icons from 'src/components/Icons'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query POSTS($search: String) {
    icons(filter: { toolboxes: null, name_contains: $search }) {
      id
      name
      category
      image
      make
      model
      notes
      partNumber
      priceToSupply
      priceToInstall
    }
  }
`

export const beforeQuery = (props) => {
  if (!props.search) {
    return {
      variables: { ...props, search: '' },
      fetchPolicy: 'no-cache',
    }
  }
  return { variables: props, fetchPolicy: 'no-cache' }
}

export const Loading = () => <LoadingIcon />

export const Empty = () => {
  return <div className="text-center">{'No icons found. '}</div>
}

export const Success = ({ icons, search }) => {
  return <Icons icons={icons} search={search} />
}
