import * as firebase from 'firebase/app'

const firebaseClientConfig = {
  apiKey: 'AIzaSyCIJctYv_A5K1lZNFb3RrgchgQFV8C90B8',
  authDomain: 'zapquote-1.firebaseapp.com',
  databaseURL: 'https://zapquote-1.firebaseio.com',
  projectId: 'zapquote-1',
  storageBucket: 'zapquote-1.appspot.com',
  messagingSenderId: '752049358568',
  appId: '1:752049358568:web:d029160c59b70fdfa8eb5d',
}

const firebaseClient = ((config) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }
  return firebase
})(firebaseClientConfig)

export default firebase
