import React, { useContext, createContext, useState } from 'react'

import firebase from './firebase'
import 'firebase/storage'

const storage = firebase.storage()

export const UploaderContext = createContext()

const Uploader = ({ children }) => {
  const [uploadProgress, setUploadProgress] = useState([])
  const [completed, setCompleted] = useState(null)
  const [errorOcurred, setErrorOcurred] = useState(false)
  const [uploadComplete, setUploadComplete] = useState(false)

  const uploadFile = async (path, file, metadata) => {
    return new Promise((resolve, reject) => {
      // reset any previous uploads
      setErrorOcurred(false)
      setUploadComplete(false)

      let storageRef = storage.ref()
      let uploadTask = storageRef.child(path).put(file)

      setUploadProgress([0])

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setUploadProgress([progress])
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
          }
        },
        (error) => {
          setErrorOcurred(true)
          console.log('Failed to Upload. Reason: ' + JSON.stringify(error))
          reject()
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL)
            setUploadComplete(true)
            resolve(downloadURL)
          })
        }
      )
    })
  }

  const deleteFileByUrl = async (url) => {
    // https://firebasestorage.googleapis.com/v0/b/zapquote-1.appspot.com/o/user_data%2FtxVe1ogIGHdcLsYpdVscx6PVml83%2Fdummy.pdf?alt=media&token=f43c3038-a1d6-4e64-ac78-67bde3b8c60b
    // so currently between appspot.com/o/ and ?alt
    return new Promise((resolve, reject) => {
      console.log(url)
      let newUrl = url.replace(
        'https://firebasestorage.googleapis.com/v0/b/zapquote-1.appspot.com/o/',
        ''
      )
      const end = newUrl.indexOf('?')

      newUrl = newUrl.substring(0, end)
      newUrl = newUrl.replaceAll('%2F', '/')

      console.log(newUrl)

      let storageRef = storage.ref()
      let fileToDelete = storageRef.child(newUrl)

      fileToDelete
        .delete()
        .then(() => {
          resolve('successful delete')
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const value = {
    uploadProgress,
    errorOcurred,
    uploadComplete,
    uploadFile,
    deleteFileByUrl,
  }

  return (
    <UploaderContext.Provider value={value}>
      {children}
    </UploaderContext.Provider>
  )
}

export default Uploader
