import React, { createContext, useState, useEffect } from 'react'
import { REST_URL } from '../constants/urls'

import firebase from './firebase'
import 'firebase/auth'

import axios from 'axios'

export const AuthContext = React.createContext()
export const auth = firebase.auth()

const Auth = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [token, setToken] = useState(null)
  const [user, setUser] = useState(null)
  const [tempLoaded, setTempLoaded] = useState(false)

  useEffect(async () => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth !== null) {
        let idToken = await userAuth.getIdToken()
        setUser({ token: idToken, email: userAuth.email })
        setAuthenticated(true)
        setSubscribed(true)
        setTempLoaded(true)
      } else {
        setTempLoaded(true)
      }
    })
  }, [])

  const signUp = (userData) => {
    return axios
      .post(REST_URL + '/signup', {
        ...userData,
      })
      .then((res) => {
        return auth.signInWithEmailAndPassword(
          userData.email,
          userData.password
        )
      })
  }

  const logIn = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password)
  }

  const logOut = () => {
    auth.signOut().then((res) => {
      window.location.href = '/login'
      //setAuthenticated(false)
      //setSubscribed(false)
      //setUser(null)
    })
  }

  const val = {
    token,
    authenticated,
    tempLoaded,
    setAuthenticated,
    subscribed,
    setSubscribed,
    logIn,
    signUp,
    logOut,
    user,
  }

  const value = React.useMemo(
    () => ({
      authenticated,
      setAuthenticated,
      subscribed,
      setSubscribed,
      logIn,
      signUp,
      logOut,
      user,
    }),
    [authenticated]
  )

  return <AuthContext.Provider value={val}>{children}</AuthContext.Provider>
}

export default Auth
