import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'

import { UploaderContext } from '../../utils/uploader'

import { useHistory } from 'react-router-dom'

import PageHeader from 'src/components/PageHeader/PageHeader'
import Trash from 'src/components/Trash/Trash'

import Button from '../Button/Button'
import DragNDrop from '../DragNDrop/DragNDrop'

export const UPLOAD_FILE_MUTATION = gql`
  mutation UpdateUser(
    $id: ID!
    $companyLogo: String
    $insuranceFile: String
    $termsFile: String
  ) {
    updateUser(
      id: $id
      companyLogo: $companyLogo
      insuranceFile: $insuranceFile
      termsFile: $termsFile
    ) {
      id
    }
  }
`

const DELETE_POST_MUTATION = gql`
  mutation DeleteUserMutation($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`

const User = ({ user, editing, setEditing }) => {
  const history = useHistory()
  const [uploading, setUploading] = useState(false)
  const [uploadFileMutation] = useMutation(UPLOAD_FILE_MUTATION)
  const { uploadFile, uploadProgress, deleteFileByUrl } = useContext(
    UploaderContext
  )
  const [companyLogo, setCompanyLogo] = useState(null)
  const [insuranceFile, setInsuranceFile] = useState(null)
  const [termsFile, setTermsFile] = useState(null)
  const [deleteUser] = useMutation(DELETE_POST_MUTATION, {
    onCompleted: () => {
      history.push('/users')
      location.reload()
    },
  })

  useEffect(() => {
    user.companyLogo && setCompanyLogo(user.companyLogo)
    user.insuranceFile && setInsuranceFile(user.insuranceFile)
    user.termsFile && setTermsFile(user.termsFile)
  }, [])

  const uploadCompanyLogo = useCallback(async (files) => {
    setUploading(true)
    const blob = new Blob([files[0]], { type: files[0].type })
    const url = await uploadFile(`user_data/${user.id}/${files[0].name}`, blob)
    uploadFileMutation({ variables: { id: user.id, companyLogo: url } })
    setCompanyLogo(url)

    setUploading(false)
  }, [])

  const uploadInsuranceFile = useCallback(async (files) => {
    setUploading(true)
    const blob = new Blob([files[0]], { type: files[0].type })
    const url = await uploadFile(`user_data/${user.id}/${files[0].name}`, blob)
    uploadFileMutation({ variables: { id: user.id, insuranceFile: url } })
    setInsuranceFile(url)
    setUploading(false)
  }, [])

  const uploadTermsFile = useCallback(async (files) => {
    setUploading(true)
    const blob = new Blob([files[0]], { type: files[0].type })
    const url = await uploadFile(`user_data/${user.id}/${files[0].name}`, blob)
    uploadFileMutation({ variables: { id: user.id, termsFile: url } })
    setTermsFile(url)
    setUploading(false)
  }, [])

  const deleteCompanyLogo = async () => {
    uploadFileMutation({ variables: { id: user.id, companyLogo: null } })
    await deleteFileByUrl(companyLogo)
    setCompanyLogo(null)
  }

  const deleteInsuranceFile = async () => {
    uploadFileMutation({ variables: { id: user.id, insuranceFile: null } })
    await deleteFileByUrl(insuranceFile)
    setInsuranceFile(null)
  }

  const deleteTermsFile = async () => {
    uploadFileMutation({ variables: { id: user.id, termsFile: null } })
    await deleteFileByUrl(termsFile)
    setTermsFile(null)
  }

  return (
    <>
      <PageHeader
        title="My Account"
        text="View and edit your contact details and your subscription plan."
        button={
          <Button
            onClick={console.log('nice try')}
            text="Sign Out"
            style="clear"
          />
        }
      />
      <AccountContainer>
        <AccountContainerHalf className="ml-1">
          <AccountSubsection>
            <div>
              <span>Documents</span>
            </div>
            <div>
              <span>Company Logo</span>
              {companyLogo ? (
                <AccountUploadDocumentContainer style={{ height: 'unset' }}>
                  <CompanyLogo src={companyLogo} target="_blank" />
                  <div>
                    <Trash onClick={deleteCompanyLogo} />
                  </div>
                </AccountUploadDocumentContainer>
              ) : (
                <DNDHolder>
                  <DragNDrop
                    info={<Button text="Select File" />}
                    onDrop={uploadCompanyLogo}
                    accept="image/jpeg, image/png"
                  />
                </DNDHolder>
              )}
            </div>
            <div>
              <span>Insurance Documents</span>
              {insuranceFile ? (
                <AccountUploadDocumentContainer>
                  <AccountUploadedDocumentLink
                    target="_blank"
                    href={insuranceFile}
                  >
                    insurance.pdf
                  </AccountUploadedDocumentLink>
                  <Trash onClick={deleteInsuranceFile} />
                </AccountUploadDocumentContainer>
              ) : (
                <DNDHolder>
                  <DragNDrop
                    info={<Button text="Select File" />}
                    onDrop={uploadInsuranceFile}
                    accept="application/pdf"
                  />
                </DNDHolder>
              )}
            </div>
            <div>
              <span>Terms & Conditions</span>
              {termsFile ? (
                <AccountUploadDocumentContainer>
                  <AccountUploadedDocumentLink target="_blank" href={termsFile}>
                    terms.pdf
                  </AccountUploadedDocumentLink>
                  <Trash onClick={deleteTermsFile} />
                </AccountUploadDocumentContainer>
              ) : (
                <DNDHolder>
                  <DragNDrop
                    info={<Button text="Select File" />}
                    onDrop={uploadTermsFile}
                    accept="application/pdf"
                  />
                </DNDHolder>
              )}
            </div>
          </AccountSubsection>
          <AccountDeleteContainer className="account-delete-container mt-2">
            <Button text="Delete Account" style="danger" />
          </AccountDeleteContainer>
        </AccountContainerHalf>
      </AccountContainer>
    </>
  )
}

export default User

const CompanyLogo = styled.img`
  width: 100px;
  height: 100px;
`

const AccountContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 3rem;
`

const DNDHolder = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem;
`

const AccountContainerHalf = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AccountSubsection = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 100%;
  padding: 2rem;
  margin-top: 2rem;
  > div:first-child {
    > span {
      font-family: Josefin Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;

      color: #000000;
    }
  }
  > div:not(:first-child) {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    > span {
      font-family: DM Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #001733;
      margin-bottom: 1rem;
    }
  }
`

const AccountDeleteContainer = styled.div`
  display: flex;
  width: 50%;
`
const AccountUploadDocumentContainer = styled.div`
  background: rgba(0, 23, 51, 0.05);
  border-radius: 10px;
  width: 100%;
  height: 3.5rem;
  padding: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AccountUploadedDocumentLink = styled.a`
  cursor: pointer;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-decoration-line: underline;

  color: #001733;
`
