import { createGlobalStyle } from 'styled-components'

/*
    :root - defines the shades of colors, spacing etc. e.g --color-red
    html - uses these colors to set colors for elements e.g --color-background-alert
*/

const GlobalStyles = createGlobalStyle`
    :root {

        --color-black: #000;
        --color-gray-0: hsl(0, 0%, 10%);
        --color-gray-1: hsl(0, 0%, 20%);
        --color-gray-2: hsl(0, 0%, 30%);
        --color-gray-3: hsl(0, 0%, 40%);
        --color-gray-4: hsl(0, 0%, 50%);
        --color-gray-5: hsl(0, 0%, 60%);
        --color-gray-6: hsl(0, 0%, 70%);
        --color-gray-7: hsl(0, 0%, 80%);
        --color-gray-8: hsl(0, 0%, 90%);
        --color-white: #fff;

        --color-zapquote-blue-0: #001733;
        --color-zapquote-blue-1: #1b315f;
        --color-zapquote-blue-2: #2d4472;
        --color-zapquote-blue-3: #379ecc;
        --color-zapquote-blue-4: #e4eef2;

        --color-red: #CC3737;
    }
    html {

        --color-background-base: var(--color-zapquote-blue-4);

        --color-danger-main: var(--color-red);

        --color-top-bar: var(--color-zapquote-blue-1);
        --color-right-bar: var(--color-zapquote-blue-2);

        --color-button-background-primary: var(--color-zapquote-blue-3); 

        --color-text-main: var(--color-zapquote-blue-0);
        --color-text-light: var(--color-white);
        --color-text-dark: var(--color-black);
        --color-text-active: var(--color-zapquote-blue-3);
        --color-text-hyperlink: var(--color-zapquote-blue-3);

        --color-background: white;

        --z-index-modal: 7000;

        --text-info-size: 14px;

        --input-padding: 1.5rem 1rem;
        --input-margin: 1rem;
        --input-height: 0.5rem;
        --input-font-size: 16px;
        --input-label-font-size: 16px;
        --form-padding: 1rem;
        --form-container-padding: 1.5rem 3rem;

        @media (max-width: 1440px) {

            --text-info-size: 12px;

            
            --input-padding: 1rem;
            --input-margin: 0.5rem;
            --input-font-size: 16px;
            --input-label-font-size: 16px;
            --input-font-size: 14px;
            --form-padding: 0.5rem;
            --form-container-padding: 0.5rem 3rem;
        }
    }
`

export default GlobalStyles
