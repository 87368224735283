import { Form, FormError, FieldError, Label } from '@redwoodjs/web'
import styled from 'styled-components'

import Input from '../Input/Input'
import Button from '../Button/Button'

const ToolboxForm = (props) => {
  const onSubmit = (data) => {
    props.onSave(data, props?.toolbox?.id)
  }

  return (
    <FormContainer onSubmit={onSubmit} error={props.error}>
      <FormError
        error={props.error}
        wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mb-4"
        titleClassName="font-semibold"
        listClassName="mt-2 list-disc list-inside"
      />

      <FormLabel name="Toolbox Name" errorClassName={CSS.labelError} />
      <Input
        name="name"
        placeholder="e.g. My toolbox 1"
        defaultValue={props.toolbox?.name}
        errorClassName={CSS.inputError}
        validation={{ required: true }}
      />
      <FieldError name="name" className={CSS.errorMessage} />

      <div className="mt-8 text-center">
        <Button
          disabled={props.loading}
          text={props.toolbox ? 'Save Toolbox' : 'Create Toolbox'}
          btnStyle={props.toolbox ? 'success' : 'primary'}
          type="submit"
          className="mt-2"
        />
      </div>
    </FormContainer>
  )
}

export default ToolboxForm

const FormContainer = styled(Form)`
  width: 100%;
`

const FormLabel = styled(Label)`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #001733;
`
