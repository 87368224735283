import styled from 'styled-components'
import { navigate } from '@redwoodjs/router'
import { useHistory } from 'react-router-dom'

import Table from '../Table/Table'
const TableCard = ({
  cardTitle,
  cardAction,
  tableHeaders,
  tableRows,
  linkTo,
  className = '',
}) => {
  const history = useHistory()
  const actionClick = () => {
    if (linkTo) {
      history.push(linkTo)
      //n-avigate(linkTo)
    }
  }

  return (
    <DashboardInfoCard className={className}>
      <DashboardInfoCardHeaderContainer>
        <DashboardInfoCardHeader>{cardTitle}</DashboardInfoCardHeader>
        <TableCardActionContainer onClick={actionClick}>
          {cardAction}
        </TableCardActionContainer>
      </DashboardInfoCardHeaderContainer>
      <DashboardInfoCardDivider />
      <DashboardTableContainer>
        <Table headers={tableHeaders} rows={tableRows} />
      </DashboardTableContainer>
    </DashboardInfoCard>
  )
}

export default TableCard

const DashboardInfoCard = styled.div`
  background: #ffffff;
  /* Default */

  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 100%;
`

const DashboardInfoCardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  height: 2rem;
  padding: 3rem;
`

const DashboardInfoCardHeader = styled.span`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
`

const DashboardTableContainer = styled.div`
  padding: 1.5rem;
`

const DashboardInfoCardDivider = styled.div`
  border-bottom: 1px solid rgba(0, 23, 51, 0.2);
`

const TableCardActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 40%;
  cursor: pointer;
`
