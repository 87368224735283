import styled, { css } from 'styled-components'

const Row = ({ className, wrap, children }) => {
  return (
    <RowComponent wrap={wrap} className={className}>
      {children}
    </RowComponent>
  )
}

export default Row

const RowComponent = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.wrap &&
    css`
      flex-wrap: wrap;
    `}
`
