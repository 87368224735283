import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import SitecontainerLayout from 'src/layouts/SitecontainerLayout/SitecontainerLayout'
import Button from 'src/components/Button/Button'
import Select from 'src/components/Select/Select'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FilterGroup from 'src/components/FilterGroup/FilterGroup'
import NewCustomer from 'src/components/NewCustomer/NewCustomer'

import { ModalContext } from '../../utils/ModalContext'

import WrapperForCustomers from 'src/components/Wrappers/WrapperForCustomers'

const CUSTOMERS_QUERY = gql`
  query {
    customers {
      id
      status
    }
  }
`

const CustomerPage = () => {
  const { openModal, closeModal } = useContext(ModalContext)
  const { data: { customers = [] } = {} } = useQuery(CUSTOMERS_QUERY)
  const activeCount = customers.filter(({ status }) => status === 'active')
    .length
  const archivedCount = customers.filter(({ status }) => status === 'archived')
    .length
  const filters = [
    { display: `Active (${activeCount})`, value: 'active' },
    { display: `Archived (${archivedCount})`, value: 'archived' },
  ]
  const initialOptions = [
    {
      id: 0,
      title: 'Recent',
      selected: true,
      key: 'recent',
    },
  ]
  const [activeFilter, setActiveFilter] = useState('active')
  const [options, setOptions] = useState(initialOptions)

  return (
    <SitecontainerLayout noTop noLeft noRight>
      <PageHeader
        title="Customers"
        text="These are the clients or companies you plan to create projects for."
        button={
          <Button
            text="New Customer"
            onClick={() => openModal(<NewCustomer />)}
          />
        }
      />
      } />
      <CustomersBody className="p-3 pl-5 pr-5">
        <CustomerFiltersContainer className="mb-2">
          <CustomerFilters>
            <FilterGroup
              filters={filters}
              onClick={setActiveFilter}
              activeFilter={activeFilter}
            />
          </CustomerFilters>
          <CustomerFilters>
            <Select options={options} setOptions={setOptions} />
          </CustomerFilters>
        </CustomerFiltersContainer>
        <WrapperForCustomers activeFilter={activeFilter} />
      </CustomersBody>
    </SitecontainerLayout>
  )
}

export default CustomerPage

const CustomersBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const CustomerFilters = styled.div`
  width: 20%;
  height: 100%;
`

const CustomerFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
`
