import styled from 'styled-components'

const Column = ({ children, className }) => {
  return <Col className={className}>{children}</Col>
}

export default Column

const Col = styled.div`
  display: flex;
  flex-direction: column;
`
