import { useQuery, useMutation } from '@redwoodjs/web'
import QuoteForm from 'src/components/QuoteForm'
import { useHistory } from 'react-router-dom'
const CREATE_POST_MUTATION = gql`
  mutation CreateQuoteMutation($input: QuoteInput!) {
    createQuote(input: $input, status: "active") {
      id
    }
  }
`

const CUSTOMERS_QUERY = gql`
  query FIND_POST_BY_ID {
    customers {
      id
      firstName
      surname
    }
  }
`

const PROJECTS_QUERY = gql`
  query FIND_POST_BY_ID {
    projects {
      id
      address1
    }
  }
`
const NewQuote = () => {
  const history = useHistory()
  const [createQuote, { loading, error }] = useMutation(CREATE_POST_MUTATION, {
    onCompleted: () => {
      history.push('/quotes')
    },
  })

  const { data: { customers } = {} } = useQuery(CUSTOMERS_QUERY)
  const { data: { projects } = {} } = useQuery(PROJECTS_QUERY)

  const onSave = (input) => {
    createQuote({ variables: { input } })
  }

  return (
    <QuoteForm
      onSave={onSave}
      loading={loading}
      error={error}
      customers={customers}
      projects={projects}
    />
  )
}

export default NewQuote
