import IconsLayout from 'src/layouts/IconsLayout'
import IconsCell from 'src/components/IconsCell'

import WrapperForFittings from 'src/components/Wrappers/WrapperForFittings'

const IconsPage = () => {
  return (
    <IconsLayout>
      <IconsCell />
      <WrapperForFittings />
    </IconsLayout>
  )
}

export default IconsPage
