import IconsLayout from 'src/layouts/IconsLayout'
import IconCell from 'src/components/IconCell'

import WrapperForFitting from 'src/components/Wrappers/WrapperForFitting'

const IconPage = ({ id }) => {
  return (
    <IconsLayout>
      {id && <IconCell id={id} />}
      {id && <WrapperForFitting id={id} />}
    </IconsLayout>
  )
}

export default IconPage
