//import { navigate, routes } from '@redwoodjs/router'
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import ChevronDown from '../ChevronDown/ChevronDown'
import FullLogo from '../FullLogo/FullLogo'

import { AuthContext } from '../../utils/auth'

// auth goes here

const Header = () => {
  const history = useHistory()
  const { authenticated, logOut, user } = useContext(AuthContext)
  let isAuthenticated, loginWithRedirect, loading, logout // was Auth0
  const [open, setOpen] = useState(false)
  return (
    <HeaderContainer>
      <HeaderItems>
        <FullLogo
          onClick={() => history.push('/dashboard')}
          className="pl-2 pointed"
        />
        <HeaderInfoContainer onClick={() => authenticated && setOpen(!open)}>
          {!authenticated && (
            <span onClick={() => loginWithRedirect({})}>Log in</span>
          )}
          {!loading && <HeaderText>{user.email}</HeaderText>}
          <ChevronDown className="ml-1" />
        </HeaderInfoContainer>
        {open && (
          <HeaderOptions>
            <HeaderOption onClick={() => history.push('/account')}>
              My Account
            </HeaderOption>
            <HeaderOption signout onClick={() => logOut()}>
              Sign Out
            </HeaderOption>
          </HeaderOptions>
        )}
      </HeaderItems>
      <HeaderBottom />
    </HeaderContainer>
  )
}

export default Header

const HeaderInfoContainer = styled.div`
  padding-right: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 72px;
`

const HeaderItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  width: 100%;
`

const HeaderText = styled.span`
  /* Bold */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;

  /* Dark blue */

  color: #001733;
`

const HeaderBottom = styled.div`
  height: 2px;
  background: linear-gradient(90deg, #379ecc 0%, #1d4474 100%);
`

const HeaderOptions = styled.div`
  background: #ffffff;
  /* Raised */

  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  position: absolute;
  right: 2rem;
  top: 5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const HeaderOption = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: #000000;

  ${(p) =>
    p.signout &&
    css`
      color: #cc3737;
    `}
`
