const LandingPage = () => {
  return (
    <div>
      <h1>LandingPage</h1>
      <p>Find me in ./web/src/pages/LandingPage/LandingPage.js</p>
    </div>
  )
}

export default LandingPage
