import styled from 'styled-components'
import { useContext } from 'react'
import { useQuery } from '@apollo/client'

import Button from 'src/components/Button/Button'
import SitecontainerLayout from 'src/layouts/SitecontainerLayout/SitecontainerLayout'
import ChevronRight from 'src/components/ChevronRight/ChevronRight'
import TableCard from 'src/components/TableCard/TableCard'
import NewProject from 'src/components/NewProject/NewProject'
import ModalComponent from 'src/components/Modal/Modal'
import NewCustomer from 'src/components/NewCustomer/NewCustomer'
import NewQuote from 'src/components/NewQuote/NewQuote'

import { ModalContext } from '../../utils/ModalContext'

const projectTableHeaders = [
  { name: 'Project Name', selector: 'name' },
  { name: 'Customer', selector: 'customer' },
  { name: 'Reference', selector: 'reference' },
  { name: 'Status', selector: 'status' },
]

const quoteTableHeaders = [
  { name: 'Drafts', selector: 'drafts' },
  { name: 'Awaiting Signature', selector: 'awaitingSignature' },
  { name: 'Accepted', selector: 'accepted' },
  { name: 'Declined', selector: 'declined' },
]

const toolboxTableHeaders = [
  { name: 'Toolbox Name', selector: 'name' },
  { name: 'Icons', selector: 'icons' },
  { name: 'Projects Used In', selector: 'projects' },
]

const PROJECTS_QUERY = gql`
  query {
    projects {
      id
      name
      status
      customer {
        id
        firstName
        surname
      }
      reference
    }
  }
`
const QUOTES_QUERY = gql`
  query FIND_POST_BY_ID {
    documents {
      id
      status
    }
  }
`

const DashboardPage = () => {
  const { openModal, closeModal } = useContext(ModalContext)
  const { data: { projects = [] } = {} } = useQuery(PROJECTS_QUERY)
  const { data: { quotes = [] } = {} } = useQuery(QUOTES_QUERY)

  const projectData =
    projects.length > 0
      ? projects.map((p) => ({
          ...p,
          name: { value: p.name, link: `/project/${p.id}` },
          customer: `${p.customer?.firstName} ${p.customer?.surname}`,
        }))
      : []

  let drafts = 0,
    awaiting = 0,
    accepted = 0,
    declined = 0
  quotes.forEach((q) => {
    switch (q.status) {
      case 'declined':
        declined += 1
        break
      case 'accepted':
        accepted += 1
        break
      case 'awaiting':
        awaiting += 1
        break
      case 'active':
      default:
        drafts += 1
    }
  })
  const quoteData = [
    {
      drafts: { value: drafts, bold: true },
      awaitingSignature: { value: awaiting, bold: true },
      accepted: { value: accepted, bold: true },
      declined: { value: declined, bold: true },
    },
  ]

  return (
    <SitecontainerLayout>
      <DashboardContainer>
        <DashboardMain>
          {/* Projects */}
          <TableCard
            className="mb-2"
            cardTitle="Projects"
            linkTo={'/projects'}
            cardAction={<ChevronRight />}
            tableHeaders={projectTableHeaders}
            tableRows={projectData}
          />
          {/* Quotes */}
          <TableCard
            className="mb-2"
            cardTitle="Quotes"
            linkTo={'/quotes'}
            cardAction={<ChevronRight />}
            tableHeaders={quoteTableHeaders}
            tableRows={quoteData}
          />
        </DashboardMain>
        <DashboardRight>
          <Button
            text="New Project"
            onClick={() => openModal(<NewProject />)}
          />
          <Button
            text="New Customer"
            onClick={() => openModal(<NewCustomer />)}
          />
        </DashboardRight>
      </DashboardContainer>
    </SitecontainerLayout>
  )
}

export default DashboardPage

const DashboardContainer = styled.div`
  display: flex;
  height: 100%;
`

const DashboardMain = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
`

const DashboardRight = styled.div`
  padding-left: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  > button {
    margin-bottom: 1rem;
  }
`
