import React from 'react'
import styled from 'styled-components'
import { useQuery, gql } from '@apollo/client'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

import User from 'src/components/User'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    user: user(id: $id) {
      id
      email
      firstName
      lastName
      phone
      currency
      company
      companyLogo
      insuranceFile
      termsFile
    }
  }
`

const WrapperForUser = ({ id, editing, setEditing }) => {
  const { loading, error, data } = useQuery(QUERY, { variables: { id } })

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (!data.user) {
    return <p>Empty ting</p>
  } else {
    return <User user={data.user} editing={editing} setEditing={setEditing} />
  }
}

export default WrapperForUser
