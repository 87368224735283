import styled, { css } from 'styled-components'
import { Tabs as RTabs, TabList, Tab, TabPanel } from 'react-tabs'

const STabs = styled(RTabs)`
  width: 100%;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #001733;
  ${(p) =>
    p.bottom &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    `}
`

const STabList = styled(TabList)`
  list-style-type: none;
  padding: 4px;
  display: flex;
  margin: 0;
`
STabList.tabsRole = 'TabList'

const STab = styled(Tab)`
  padding: 4px;
  user-select: none;
  cursor: pointer;
  flex: 1;
  margin-left: ${(p) => (p.noGap ? '0rem' : '0.5rem')};
  margin-right: ${(p) => (p.noGap ? '0rem' : '0.5rem')};

  ${(p) =>
    p.white &&
    css`
      color: #ffffff;
      opacity: 0.6;
    `}

  &.is-selected {
    opacity: 1;
    color: ${(p) => (p.white ? '#ffffff' : '#379ecc')};
    border-bottom: 1px solid ${(p) => (p.white ? '#ffffff' : '#379ecc')};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
  }
`
STab.tabsRole = 'Tab'

const STabPanel = styled(TabPanel)`
  display: none;
  min-height: 40vh;
  padding: 4px;
  margin-top: -5px;

  &.is-selected {
    display: block;
  }
`
STabPanel.tabsRole = 'TabPanel'

const Tabs = ({ tabs = [], onSelect, bottom, white, noGap }) => {
  const TabPane = (tab, key) => (
    <TabPane tab={tab.name} key={key.toString()}>
      <div>1</div>
    </TabPane>
  )

  return (
    <STabs
      selectedTabClassName="is-selected"
      selectedTabPanelClassName="is-selected"
      bottom={bottom}
      onSelect={onSelect}
    >
      <STabList>
        {tabs.map((t, key) => (
          <STab white={white} noGap={noGap} key={key}>
            {t.name}
          </STab>
        ))}
      </STabList>
      {!onSelect &&
        tabs.map((t, key) => <STabPanel key={key}>{t.content}</STabPanel>)}
    </STabs>
  )
}

export default Tabs
