import { useMutation } from '@redwoodjs/web'
import { navigate, routes } from '@redwoodjs/router'
import QuoteForm from 'src/components/QuoteForm'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    quote: Quote(id: $id) {
      id
      to
      from
      attachments
      notes
    }
  }
`
const UPDATE_POST_MUTATION = gql`
  mutation UpdateQuoteMutation($id: Int!, $input: QuoteInput!) {
    updateQuote(id: $id, input: $input) {
      id
    }
  }
`

export const Loading = () => <LoadingIcon />

export const Success = ({ quote }) => {
  const [updateQuote, { loading, error }] = useMutation(UPDATE_POST_MUTATION, {
    onCompleted: () => {
      // n------avigate(routes.quotes())
    },
  })

  const onSave = (input, id) => {
    updateQuote({ variables: { id, input } })
  }

  return (
    <div className="bg-white border rounded-lg overflow-hidden">
      <header className="bg-gray-300 text-gray-700 py-3 px-4">
        <h2 className="text-sm font-semibold">Edit Quote {quote.id}</h2>
      </header>
      <div className="bg-gray-100 p-4">
        <QuoteForm
          quote={quote}
          onSave={onSave}
          error={error}
          loading={loading}
        />
      </div>
    </div>
  )
}
