import { useMutation } from '@apollo/client'
import CustomerForm from 'src/components/CustomerForm/CustomerForm'
import CustomerFormNew from 'src/components/CustomerForm/CustomerFormNew'

import { useHistory } from 'react-router-dom'

const CREATE_POST_MUTATION = gql`
  mutation CreatCustomerMutation(
    $email: String!
    $firstName: String
    $surname: String
    $phone: String
    $company: String
    $address1: String
    $address2: String
    $city: String
    $postCode: String
  ) {
    createCustomer(
      email: $email
      firstName: $firstName
      surname: $surname
      phone: $phone
      company: $company
      address1: $address1
      address2: $address2
      city: $city
      postCode: $postCode
      status: "active"
    ) {
      id
    }
  }
`

const NewCustomer = ({ onComplete }) => {
  const history = useHistory()
  const [createCustomer, { loading, error }] = useMutation(
    CREATE_POST_MUTATION,
    {
      onCompleted: ({ createCustomer: { id } }) => {
        onComplete ? onComplete(id) : history.push(`/customer/${id}`)
      },
    }
  )

  const onSave = (input) => {
    createCustomer({ variables: { ...input } })
  }

  return <CustomerFormNew onSave={onSave} loading={loading} error={error} />
}

export default NewCustomer
