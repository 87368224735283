import { Form, FormError, FieldError, Label } from '@redwoodjs/web'
import styled, { css } from 'styled-components'

import Input from '../Input/Input'
import Button from '../Button/Button'
import LoadingIcon from '../LoadingIcon/LoadingIcon'

const CustomerForm = (props) => {
  const onSubmit = (data) => {
    props.onSave(data, props?.customer?.id)
  }

  return (
    <StyledForm onSubmit={onSubmit} error={props.error}>
      {props.loading && (
        <LoadingCover>
          <LoadingIcon />
        </LoadingCover>
      )}
      <FormError
        error={props.error}
        wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mb-4"
        titleClassName="font-semibold"
        listClassName="mt-2 list-disc list-inside"
      />
      <AccountDataRow className="pt-0">
        <AccountDataBlock className="mr-1">
          <FormLabel name="First Name" />
          <Input
            name="firstName"
            placeholder="First Name"
            defaultValue={props.customer?.firstName}
            validation={{ required: true }}
          />
          <FieldError name="firstName" />
        </AccountDataBlock>

        <AccountDataBlock>
          <FormLabel name="Surname" />
          <Input
            name="surname"
            placeholder="Surname"
            defaultValue={props.customer?.surname}
            validation={{ required: true }}
          />
          <FieldError name="surname" />
        </AccountDataBlock>
      </AccountDataRow>

      <AccountDataRow>
        <AccountDataBlock>
          <FormLabel name="Email Address" />
          <Input
            name="email"
            placeholder="Email"
            defaultValue={props.customer?.email}
            validation={{ required: true }}
          />
          <FieldError name="email" />
        </AccountDataBlock>
      </AccountDataRow>

      <AccountDataRow>
        <AccountDataBlock>
          <FormLabel name="Phone" />
          <Input
            name="phone"
            placeholder="Add Phone Number..."
            defaultValue={props.customer?.phone}
          />
          <FieldError name="phone" />
        </AccountDataBlock>
      </AccountDataRow>

      <AccountDataRow>
        <AccountDataBlock>
          <FormLabel name="Company" />
          <Input
            name="company"
            placeholder="Company"
            defaultValue={props.customer?.company}
          />
          <FieldError name="company" />
        </AccountDataBlock>
      </AccountDataRow>

      <AccountDataRow>
        <AccountDataBlock>
          <FormLabel name="Address" />
          <Input
            name="address1"
            placeholder="Address Line 1"
            defaultValue={props.customer?.address1}
          />
          <Input
            name="address2"
            placeholder="Address Line 2"
            defaultValue={props.customer?.address2}
          />
          <AccountDataRow noTop>
            <Input
              name="city"
              placeholder="City"
              defaultValue={props.customer?.city}
              className="mr-1"
            />
            <Input
              name="postCode"
              placeholder="Post code"
              defaultValue={props.customer?.postCode}
            />
          </AccountDataRow>
          <FieldError name="address" />
        </AccountDataBlock>
      </AccountDataRow>

      <div className="mt-8 text-center">
        <Button
          disabled={props.loading}
          text="Save Details"
          type="submit"
          btnStyle="success"
          className="mt-2"
        />
      </div>
    </StyledForm>
  )
}

export default CustomerForm

const StyledForm = styled(Form)`
  position: relative;
  width: 100%;
`

const FormLabel = styled(Label)`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
`

const AccountDataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) =>
    props.noTop
      ? css`
          padding-top: 0rem;
        `
      : css`
          padding-top: 1rem;
        `}
`

const AccountDataBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const LoadingCover = styled.div`
  /* display: none;
  :hover { */
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  background-color: rgba(230, 230, 230, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5rem;
`
