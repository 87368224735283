import DataTable from 'react-data-table-component'

const customStyles = {
  table: {
    style: {
      overflow: 'visible',
      backgroundColor: 'transparent',
    },
  },
  tableWrapper: {
    style: {
      overflow: 'visible',
    },
  },
  header: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  headRow: {
    style: {
      backgroundColor: 'transparent',
      borderBottomWidth: '0px',
    },
  },
  rows: {
    style: {
      cursor: 'pointer',
      background: '#FFFFFF',
      boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: '20px',
      display: 'flex',
      alignContent: 'center',
      padding: '1rem',
      marginTop: '1rem',
      transitionDuration: '0.1s',
      transform: 'scale(1)',
      '&:hover': {
        transitionDuration: '0.1s',
        transform: 'scale(1.05)',
      },
    },
  },
  headCells: {
    style: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 16,
      color: '#001733',
    },
  },
  cells: {
    style: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 16,
      color: '#001733',
    },
  },
}

const CustomRowTable = ({ columns, rows, onRowClicked }) => {
  return (
    <DataTable
      onRowClicked={onRowClicked}
      className="overflow-visible"
      noHeader
      responsive
      columns={columns}
      customStyles={customStyles}
      data={rows}
    />
  )
}

export default CustomRowTable
