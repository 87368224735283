//import { Link, routes } from '@redwoodjs/router'
import styled, { css } from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import House from '../House/House'
import People from '../People/People'
import Folders from '../Folders/Folders'
import ToolboxIcon from '../ToolboxIcon/ToolboxIcon'
import PaperAirplane from '../PaperAirplane/PaperAirplane'

const Route = ({ icon, text, route, selected = false }) => (
  <IconDiv to={route} className="row flex-center mt-2 icon-div pointed">
    <div className="d-flex">
      <div className="flex-center icon-conatiner mr-1">{icon}</div>
      <IconsContainer>
        <MenuItemText selected={selected}>{text}</MenuItemText>
      </IconsContainer>
    </div>
  </IconDiv>
)

const Sidebar = () => {
  const history = useHistory()
  const { pathname } = window.location
  const appRoutes = [
    {
      icon: <House />,
      text: 'Dashboard',
      route: '/dashboard', //r--outes.dashboard(),
      selected: pathname.indexOf('dashboard') > -1,
    },
    {
      icon: <People />,
      text: 'Customers',
      route: '/customers', //r-outes.customers(),
      selected: pathname.indexOf('customers') > -1,
    },
    {
      icon: <Folders />,
      text: 'Projects',
      route: '/projects', //r-outes.projects(),
      selected: pathname.indexOf('projects') > -1,
    },
    {
      icon: <ToolboxIcon />,
      text: 'Toolboxes',
      route: '/toolboxes', //r-outes.toolboxes(),
      selected: pathname.indexOf('toolboxes') > -1,
    },
    {
      icon: <PaperAirplane />,
      text: 'Quotes',
      route: '/quotes', //r-outes.quotes(),
      selected: pathname.indexOf('quotes') > -1,
    },
  ]
  return (
    <Menu>
      <IconsContainer className="column">
        {appRoutes.map(Route)}
        <div className="flex-1" />
        <div className="column flex-center">
          <About>About Terms Credits</About>
          <LTD>©2020 ZapQuote Ltd</LTD>
        </div>
      </IconsContainer>
      <MenuShadow />
    </Menu>
  )
}

export default Sidebar

const Menu = styled.div`
  /* White */
  background: #ffffff;

  /* Large bold */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Dark blue */
  color: #001733;
  width: 200px;
  height: calc(100vh - 72px);
  display: flex;
  flex-direction: row;
`

const MenuShadow = styled.div`
  width: 2px;
  background: linear-gradient(90deg, #379ecc 0%, #1d4474 100%);
`

const MenuItemText = styled.span`
  /* Large */

  font-family: DM Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Dark blue */
  text-decoration: none;
  color: #001733;

  ${(p) =>
    p.selected &&
    css`
      font-weight: bold;
    `}
`

// .bold {
//   font-weight: 900;
// }

const IconsContainer = styled.div`
  width: 100%;
  align-items: center;
`

const IconDiv = styled(Link)`
  width: 100%;
  margin-left: 3rem;
  text-decoration: none;
`

const About = styled.span`
  /* Bold */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark blue */
  color: #001733;
  opacity: 0.6;
`

const LTD = styled.span`
  /* Body */
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark blue */
  color: #001733;
  opacity: 0.6;
`
