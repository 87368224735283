const EditSVG = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.175 10.4694L12.0639 9.58056C12.2028 9.44167 12.4445 9.53889 12.4445 9.73889V13.7778C12.4445 14.5139 11.8472 15.1111 11.1111 15.1111H1.33333C0.597223 15.1111 0 14.5139 0 13.7778V4C0 3.26389 0.597223 2.66667 1.33333 2.66667H8.93056C9.12779 2.66667 9.22779 2.90555 9.0889 3.04722L8.20001 3.93611C8.15834 3.97778 8.10278 4 8.04167 4H1.33333V13.7778H11.1111V10.625C11.1111 10.5667 11.1333 10.5111 11.175 10.4694ZM15.525 4.86389L8.23056 12.1583L5.71945 12.4361C4.99167 12.5167 4.37223 11.9028 4.45278 11.1694L4.73056 8.65834L12.025 1.36389C12.6611 0.727775 13.6889 0.727775 14.3222 1.36389L15.5222 2.56389C16.1583 3.2 16.1583 4.23056 15.525 4.86389ZM12.7806 5.72222L11.1667 4.10833L6.00556 9.27223L5.80278 11.0861L7.61667 10.8833L12.7806 5.72222ZM14.5806 3.50833L13.3806 2.30833C13.2667 2.19444 13.0806 2.19444 12.9695 2.30833L12.1111 3.16667L13.725 4.78056L14.5833 3.92222C14.6945 3.80555 14.6945 3.62222 14.5806 3.50833Z"
        fill="white"
      />
    </svg>
  )
}

export default EditSVG
