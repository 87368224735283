import { useMutation } from '@apollo/client'

import { useHistory } from 'react-router-dom'
import CustomRowTable from '../CustomRowTable/CustomRowTable'
import Archive from '../Archive/Archive'

const ARCHIVE_MUTATION = gql`
  mutation ArchiveToolboxMutation($id: ID!, $status: String) {
    updateToolbox(id: $id, status: $status) {
      id
    }
  }
`
const ToolboxesList = ({ toolboxes }) => {
  const history = useHistory()
  const [archiveToolbox] = useMutation(ARCHIVE_MUTATION, {
    onCompleted: () => {
      location.reload()
    },
  })

  const onRowClicked = ({ id }) => {
    history.push(`/toolbox/${id}`)
  }

  const headers = [
    { name: 'Toolbox name', selector: 'name' },
    { name: '', selector: 'icon', right: true },
  ]

  console.log('toolboxes', toolboxes)
  toolboxes = toolboxes.map((t) => ({
    ...t,
    icon: (
      <Archive
        archived={t.status === 'archived'}
        onClick={() =>
          archiveToolbox({
            variables: {
              id: t.id,
              status: t.status === 'archived' ? 'active' : 'archived',
            },
          })
        }
      />
    ),
  }))

  return (
    <CustomRowTable
      columns={headers}
      rows={toolboxes}
      onRowClicked={onRowClicked}
    />
  )
}

export default ToolboxesList
