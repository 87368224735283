import { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import SitecontainerLayout from 'src/layouts/SitecontainerLayout'
import WrapperForToolboxes from 'src/components/Wrappers/WrapperForToolboxes'
import styled from 'styled-components'

import Select from 'src/components/Select/Select'
import FilterGroup from 'src/components/FilterGroup/FilterGroup'
import PageHeader from 'src/components/PageHeader/PageHeader'
import Button from 'src/components/Button/Button'
import Modal from 'src/components/Modal/Modal'
import NewToolbox from 'src/components/NewToolbox/NewToolbox'

import { ModalContext } from 'src/utils/ModalContext'

const TOOLBOXES_QUERY = gql`
  query {
    toolboxes {
      id
      status
    }
  }
`

const ToolboxesPage = () => {
  const { data: { toolboxes = [] } = {} } = useQuery(TOOLBOXES_QUERY)
  const activeCount = toolboxes.filter(({ status }) => status === 'active')
    .length
  console.log(activeCount)
  const archivedCount = toolboxes.filter(({ status }) => status === 'archived')
    .length
  console.log(archivedCount)
  const filters = [
    { display: `Active (${activeCount})`, value: 'active' },
    { display: `Archived (${archivedCount})`, value: 'archived' },
  ]
  const initialOptions = [
    {
      id: 0,
      title: 'Recent',
      selected: true,
      key: 'recent',
    },
  ]
  const [activeFilter, setActiveFilter] = useState('active')
  const [options, setOptions] = useState(initialOptions)
  const { openModal, closeModal } = useContext(ModalContext)

  return (
    <SitecontainerLayout noTop noLeft noRight>
      <PageHeader
        title="Toolboxes"
        text="Toolboxes store your personalised icons to use in for various projects."
        button={
          <Button
            text="New Toolbox"
            onClick={() => openModal(<NewToolbox />)}
          />
        }
      />
      <ToolboxBody className="p-3 pl-5 pr-5">
        <HeaderContainer className="row mb-2">
          <FilterContainer>
            <FilterGroup
              filters={filters}
              onClick={setActiveFilter}
              activeFilter={activeFilter}
            />
          </FilterContainer>
          <FilterContainer>
            <Select options={options} setOptions={setOptions} />
          </FilterContainer>
        </HeaderContainer>
        <WrapperForToolboxes activeFilter={activeFilter} />
      </ToolboxBody>
    </SitecontainerLayout>
  )
}

export default ToolboxesPage

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ToolboxBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const FilterContainer = styled.div`
  width: 20%;
`
