import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'

import Projects from 'src/components/Projects'

import Quotes from 'src/components/Quotes'
import LoadingIcon from 'src/components/LoadingIcon'

export const QUERY = gql`
  query POSTS {
    documents {
      id
      status
      to {
        id
        surname
        email
        company
      }
      from {
        id
        surname
        email
        company
      }
      attachments
      notes
    }
  }
`

const WrapperForDocuments = () => {
  const { loading, error, data } = useQuery(QUERY)

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (data.documents.length == 0) {
    return (
      <div className="text-center">
        {'No quotes yet. '}
        <Link
          to={'/quotes/new'}
          className="text-blue-500 underline hover:text-blue-700"
        >
          {'Create one?'}
        </Link>
      </div>
    )
  } else {
    return <Quotes quotes={data.documents} />
  }
}

export default WrapperForDocuments
