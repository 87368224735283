import QuotesLayout from 'src/layouts/QuotesLayout'
import NewQuote from 'src/components/NewQuote'

const NewQuotePage = () => {
  return (
    <QuotesLayout>
      <NewQuote />
    </QuotesLayout>
  )
}

export default NewQuotePage
