import IconsLayout from 'src/layouts/IconsLayout'
import EditIconCell from 'src/components/EditIconCell'

import WrapperForEditFitting from 'src/components/Wrappers/WrapperForEditFitting'

const EditIconPage = ({ id, toolbox }) => {
  return (
    <IconsLayout>
      {id && (
        <>
          <WrapperForEditFitting id={id} toolbox={toolbox} />
          <EditIconCell id={id} />
        </>
      )}
    </IconsLayout>
  )
}

export default EditIconPage
