import SitecontainerLayout from 'src/layouts/SitecontainerLayout'
import WrapperForProjects from 'src/components/Wrappers/WrapperForProjects'
import styled from 'styled-components'
import React, { useState, useContext } from 'react'

import PageHeader from 'src/components/PageHeader/PageHeader'
import ModalComponent from 'src/components/Modal/Modal'
import Button from 'src/components/Button/Button'
import FilterGroup from 'src/components/FilterGroup/FilterGroup'
import Select from 'src/components/Select/Select'
import NewProject from 'src/components/NewProject/NewProject'

import { ModalContext } from '../../utils/ModalContext'

const ProjectsPage = () => {
  const initialOptions = [
    {
      id: 0,
      title: 'Apple',
      selected: false,
      key: 'fruit',
    },
  ]
  const { openModal, closeModal } = useContext(ModalContext)
  const [activeFilter, setActiveFilter] = useState('active')
  const [options, setOptions] = useState(initialOptions)
  const filters = [
    { display: 'Active', value: 'active' },
    { display: 'Draft', value: 'draft' },
    { display: 'Completed', value: 'completed' },
    { display: 'Archived', value: 'archived' },
  ]
  return (
    <SitecontainerLayout noTop noLeft noRight>
      <PageHeader
        title="Projects"
        text="Start a project by uploading a plan and marking it up with icons from your tool box."
        button={
          <Button
            text="New Project"
            onClick={() => openModal(<NewProject />)}
          />
        }
      />
      <ProjectBody className="p-3 pl-5 pr-5">
        <HeaderContainer className="row mb-2">
          <FilterContainer>
            <FilterGroup
              filters={filters}
              onClick={setActiveFilter}
              activeFilter={activeFilter}
            />
          </FilterContainer>
          <SelectContainer>
            <Select options={options} setOptions={setOptions} />
          </SelectContainer>
        </HeaderContainer>
        <WrapperForProjects status={activeFilter} />
      </ProjectBody>
    </SitecontainerLayout>
  )
}

export default ProjectsPage

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ProjectBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const FilterContainer = styled.div`
  width: 30%;
`
const SelectContainer = styled.div`
  width: 20%;
`
