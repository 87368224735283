const PaperAirplane = ({ color = '#379ECC' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7501 0.20155L0.750069 12.3141C-0.328056 12.9375 -0.220244 14.5265 0.928194 15L7.50007 17.7187V22.5C7.50007 23.9156 9.27194 24.5297 10.1579 23.4515L13.0032 19.9922L18.9282 22.4391C19.8235 22.8094 20.836 22.2422 20.9813 21.2812L23.9813 1.72967C24.1735 0.478112 22.8282 -0.421887 21.7501 0.20155ZM9.00007 22.5V18.3375L11.5548 19.3922L9.00007 22.5ZM19.5001 21.0516L9.83444 17.0578L19.186 6.00468C19.411 5.74218 19.0501 5.38592 18.7876 5.61092L6.82038 15.8109L1.50007 13.6172L22.5001 1.49999L19.5001 21.0516Z"
        fill={color}
      />
    </svg>
  )
}

export default PaperAirplane
