import NewCoreLayout from 'src/layouts/NewCoreLayout'
import WrapperForProject from 'src/components/Wrappers/WrapperForProject'
const ProjectPage = ({ id }) => {
  return (
    <NewCoreLayout hideSidebar>
      {/*id && <ProjectCell id={id} />*/}
      {id && <WrapperForProject id={id} />}
    </NewCoreLayout>
  )
}

export default ProjectPage
