import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { navigate } from '@redwoodjs/router'

import { useHistory } from 'react-router-dom'

import Input from '../Input/Input'

const customStyles = {
  table: {
    style: {
      height: '100%',
      backgroundColor: 'transparent',
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: 24,
    },
  },
  header: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  headRow: {
    style: {
      backgroundColor: 'transparent',
      borderBottomWidth: '0px',
    },
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 700,
    },
  },
  cells: {
    style: {
      fontSize: '16px',
      textTransform: 'capitalize',
    },
  },
  rows: {
    style: {
      '&:not(:last-of-type)': {
        borderBottomStyle: 'none',
        borderBottomWidth: '0px',
      },
    },
  },
}

const Table = ({ headers = [], rows = [] }) => {
  const history = useHistory()
  headers = headers.map((h) => ({
    ...h,
    cell: (row) => processVal(row, h.selector, history),
  }))
  return (
    <DataTable
      noHeader
      columns={headers}
      customStyles={customStyles}
      data={rows}
    />
  )
}

const processVal = (row, selector, history) => {
  const val = row[selector]
  let displayVal = val

  if (val instanceof Object) {
    if (val.link) {
      displayVal = (
        <TableLink
          onClick={() => history.push(val.link) /*n-avigate(val.link)*/}
          className={val.className}
        >
          {val.value}
        </TableLink>
      )
    } else if (val.bold) {
      displayVal = <TableBold className={val.className}>{val.value}</TableBold>
    } else if (val.input) {
      displayVal = (
        <Input
          value={val.value}
          onChange={val.onChange}
          name={val.name}
          placeholder={val.placeholder}
        />
      )
    } else {
      displayVal = <span className={val.className}>{val.value}</span>
    }
  }

  return displayVal
}

export default Table

const TableLink = styled.span`
  /* identical to box height, or 150% */
  text-decoration-line: underline;
  cursor: pointer;
  color: #379ecc;
`

const TableBold = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;

  /* Dark blue */
  color: #001733;
`
