import IconsLayout from 'src/layouts/IconsLayout'
import NewIcon from 'src/components/NewIcon'

const NewIconPage = () => {
  return (
    <IconsLayout>
      <NewIcon />
    </IconsLayout>
  )
}

export default NewIconPage
