import styled from 'styled-components'

const TopBar = styled.div`
  width: 100%;
  height: 72px;
  padding-left: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-top-bar);
`

export default TopBar
