import Toolbox from 'src/components/Toolbox'
import styled from 'styled-components'
import { Form } from '@redwoodjs/web/dist/form/form'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import PageHeader from 'src/components/PageHeader/PageHeader'
import Input from 'src/components/Input/Input'
import Row from 'src/components/Row/Row'

import LoadingIcon from '../LoadingIcon/LoadingIcon'
import ModalComponent from '../Modal/Modal'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    toolbox(id: $id) {
      id
      name
      icons {
        id
      }
      projects {
        id
      }
    }
  }
`
export const Error = () => <LoadingIcon />

export const Loading = () => <LoadingIcon />

export const Empty = () => <div>Toolbox not found</div>

export const Success = ({ toolbox }) => {
  const [search, setSearch] = useState(null)
  const handleInput = ({ target: { value } }) => setSearch(value)
  return (
    <>
      <PageHeader
        back={'/toolboxes'}
        title="Toolbox Editor"
        text={null}
        button={
          <Form>
            <Input
              placeholder="Search Icons"
              icon={<StyledIcon icon={faSearch} />}
              white
              onChange={handleInput}
            />
          </Form>
        }
      />
      <Toolbox search={search} toolbox={toolbox} />
    </>
  )
}

const ToolboxName = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */
  width: 100%;
  color: #ffffff;
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: #379ecc;
`

const Edit = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 1rem;
  text-decoration-line: underline;
  cursor: pointer;
  color: #ffffff;
`
