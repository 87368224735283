import styled from 'styled-components'

import ProjectQuotesCell from '../ProjectQuotesCell/ProjectQuotesCell'

const ProjectQuotes = ({ project: { id } }) => {
  return <TableContainer>{id && <ProjectQuotesCell id={id} />}</TableContainer>
}

export default ProjectQuotes

const TableContainer = styled.div`
  width: 75%;
`
