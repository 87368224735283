import styled, { css } from 'styled-components'
import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import Row from '../Row/Row'
import Column from '../Column/Column'
import Tabs from '../Tabs/Tabs'
import ProjectToolbox from '../ProjectToolbox/ProjectToolbox'

import InnerContainer from 'src/components/Layout/InnerContainer'

import WrapperForFittings from '../Wrappers/WrapperForFittings'

import InputNonRW from 'src/components/Input/InputNonRW'

const UPDATE_POST_MUTATION = gql`
  mutation AddToToolbox($fitting: FittingInput, $toolboxId: String) {
    addFittingToToolbox(fitting: $fitting, toolboxId: $toolboxId) {
      fittings
    }
  }
`

const OverallTabs = ({ selections, activeSelection, setActiveSelection }) => {
  return (
    <TabsContainer>
      {selections.map((selection) =>
        selection.value == activeSelection ? (
          <Tab text={selection.label} value={selection.value} active />
        ) : (
          <Tab
            text={selection.label}
            value={selection.value}
            setActiveSelection={setActiveSelection}
          />
        )
      )}
    </TabsContainer>
  )
}

const Tab = ({ setActiveSelection, active, text, value }) => {
  return (
    <>
      <TabBox>
        <Filters
          onClick={() => (!active ? setActiveSelection(value) : null)}
          selected={active}
        >
          {text}
        </Filters>
        {active && <Underline />}
      </TabBox>
    </>
  )
}

const Toolbox = ({ toolbox, category, updateToolboxFittings }) => {
  const [updateFitting, result] = useMutation(UPDATE_POST_MUTATION, {
    onCompleted: ({ addFittingToToolbox: { fittings } }) => {
      updateToolboxFittings(fittings)
    },
  })
  const [documentTabs, setDocumentTabs] = useState([
    { label: 'Add Mode', value: 'addMode' },
    { label: 'Edit Mode', value: 'editMode' },
  ])
  const [currentTab, setCurrentTab] = useState('addMode')
  const tabs = [
    {
      name: 'Supply',
      value: 'Supply',
      content: (
        <Row wrap>
          <WrapperForFittings toolbox={toolbox} category={'Supply'} />
        </Row>
      ),
    },
    {
      name: 'Power',
      value: 'Power',
      content: (
        <Row wrap>
          <WrapperForFittings toolbox={toolbox} category={'Power'} />
        </Row>
      ),
    },
    {
      name: 'Lighting',
      value: 'Lighting',
      content: (
        <Row wrap>
          <WrapperForFittings toolbox={toolbox} category={'Lighting'} />
        </Row>
      ),
    },
    {
      name: 'AV',
      value: 'AV',
      content: (
        <Row wrap>
          <WrapperForFittings toolbox={toolbox} category={'AV'} />
        </Row>
      ),
    },
    {
      name: 'Generic',
      value: 'Generic',
      content: (
        <Row wrap>
          <WrapperForFittings toolbox={toolbox} category={'Generic'} />
        </Row>
      ),
    },

    {
      name: 'Decorating',
      value: 'Decorating',
      content: (
        <Row wrap>
          <WrapperForFittings toolbox={toolbox} category={'Decorating'} />
        </Row>
      ),
    },
  ]

  const initialOptions = tabs.map((t, idx) => ({
    id: idx,
    title: t.name,
    key: t.value,
    selected: idx === 0,
  }))

  const setTab = (value) => {
    setCurrentTab(value)
  }

  const onSave = (input) => {
    let fittingInput = { id: input.id }
    if (input.name) fittingInput.name = input.name
    if (input.category) fittingInput.category = input.category
    if (input.make) fittingInput.make = input.make
    if (input.model) fittingInput.model = input.model
    if (input.notes) fittingInput.notes = input.notes
    if (input.partNumber) fittingInput.partNumber = input.partNumber
    if (input.priceToSupply)
      fittingInput.priceToSupply = parseFloat(input.priceToSupply)
    if (input.priceToInstall)
      fittingInput.priceToInstall = parseFloat(input.priceToInstall)

    updateFitting({
      variables: { fitting: fittingInput, toolboxId: toolbox.id },
    })
  }

  return (
    <>
      <Main>
        <TabsSection>
          <OverallTabs
            selections={documentTabs}
            activeSelection={currentTab}
            setActiveSelection={setTab}
          />
        </TabsSection>
        {currentTab == 'addMode' && (
          <ToolboxEditData>
            <Row>
              <ToolboxEditIcons>
                <ToolboxEditHeader>Available Fittings</ToolboxEditHeader>
                <ToolboxEditInfo>
                  To create a tool box, drag Fittings from "Available Fittings"
                  to the tool box on the right, then edit each icon.
                </ToolboxEditInfo>
                <Tabs noGap tabs={tabs} />
              </ToolboxEditIcons>
              <Column>
                <ProjectToolbox
                  initialOptions={initialOptions}
                  toolbox={toolbox}
                  updateToolboxFittings={updateToolboxFittings}
                />
              </Column>
            </Row>
          </ToolboxEditData>
        )}
        {currentTab == 'editMode' && (
          <FittingCardsBody>
            <ToolboxEditIcons
              style={{
                width: '90%',
                margin: 'auto',
                marginBottom: '2rem',
                marginTop: '2rem',
              }}
            >
              <ToolboxEditHeader>Edit Toolbox Fittings</ToolboxEditHeader>
              <ToolboxEditInfo>
                Edit fitting data here for all the fittings currently in the
                toolbox.
              </ToolboxEditInfo>
            </ToolboxEditIcons>
            {toolbox.fittings && toolbox.fittings?.length != 0
              ? toolbox.fittings.map((f) => (
                  <FittingCard
                    icon={JSON.parse(f)}
                    key={JSON.parse(f).id}
                    update={onSave}
                  />
                ))
              : setCurrentTab('addMode')}
          </FittingCardsBody>
        )}
      </Main>
    </>
  )
}

export default Toolbox

const FittingCard = ({ icon, update }) => {
  const handleUpdate = (e) => {
    if (icon[e.target.name] != e.target.value) {
      console.log(icon)
      console.log({ ...icon, [e.target.name]: e.target.value })
      update({ ...icon, [e.target.name]: e.target.value })
    }
  }

  return (
    <FittingCardContainer>
      <div>
        <span> </span>
        <CardElement>
          <label name="Name">Name</label>
          <InputNonRW
            noMargin
            name="name"
            onBlur={handleUpdate}
            defaultValue={icon.name}
          />
        </CardElement>
      </div>
      <div>
        <CardElement center>
          <img src={icon.image} />
        </CardElement>
        <CardElement>
          <label name="PriceToSupply">Price To Supply</label>
          <InputNonRW
            noMargin
            name="priceToSupply"
            onBlur={handleUpdate}
            alert={icon.priceToSupply == 0}
            defaultValue={icon.priceToSupply}
          />
        </CardElement>
        <CardElement>
          <label name="PriceToInstall">Price To Install</label>
          <InputNonRW
            noMargin
            name="priceToInstall"
            onBlur={handleUpdate}
            alert={icon.priceToInstall == 0}
            defaultValue={icon.priceToInstall}
          />
        </CardElement>
      </div>
      <div>
        <CardElement center>
          <label>Upload</label>
        </CardElement>
        <CardElement>
          <label name="Make">Make</label>
          <InputNonRW
            noMargin
            name="make"
            onBlur={handleUpdate}
            defaultValue={icon.make}
          />
        </CardElement>
        <CardElement>
          <label name="Model">Model</label>
          <InputNonRW
            noMargin
            name="model"
            onBlur={handleUpdate}
            defaultValue={icon.model}
          />
        </CardElement>
        <CardElement>
          <label name="PartNumber">Part Number</label>
          <InputNonRW
            noMargin
            name="partNumber"
            onBlur={handleUpdate}
            defaultValue={icon.partNumber}
          />
        </CardElement>
        <CardElement>
          <label name="Notes">Notes</label>
          <InputNonRW
            noMargin
            name="notes"
            onBlur={handleUpdate}
            defaultValue={icon.notes}
          />
        </CardElement>
      </div>
    </FittingCardContainer>
  )
}

const FittingCardsBody = styled.div`
  width: 100%;
  height: 93%;
  overflow-y: scroll;
`

const FittingCardContainer = styled.div`
  width: 90%;
  padding: 1rem;
  background-color: white;
  border-radius: 20px;
  margin: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  > div:first-child {
    display: grid;
    grid-template-columns: 20% 50% 20% 10%;
    margin-bottom: 1rem;
  }
  > div {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    margin-bottom: 1rem;
  }
`

const CardElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  align-items: ${(p) => (p.center ? 'center' : 'start')};
  > img {
    width: 25%;
  }
  > label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`

const TabsSection = styled.div`
  width: 100%;
  height: 7%;
  background-color: var(--color-zapquote-blue-0);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ToolboxEditData = styled.div`
  background: white;
  padding: 3rem;
  height: 100%;
  overflow-y: hidden;
`

const ToolboxEditIcons = styled.div`
  width: 80%;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
`

const ToolboxEditHeader = styled.span`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #001733;
`

const ToolboxEditInfo = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #001733;
`

const Main = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`

const TabBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Filters = styled.span`
  cursor: pointer;
  /* Body */
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: var(--color-text-light);

  opacity: 0.6;

  ${(props) =>
    props.selected &&
    css`
      /* Primary */
      opacity: 1;
      color: var(--color-text-light);
    `}
`

const Underline = styled.div`
  border: 1px solid var(--color-text-active);
`
