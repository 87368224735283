import { useQuery, useMutation } from '@apollo/client'
import { navigate, routes } from '@redwoodjs/router'
import ToolboxForm from 'src/components/ToolboxForm'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    toolbox: toolbox(id: $id) {
      id
      name
    }
  }
`
const UPDATE_POST_MUTATION = gql`
  mutation UpdateToolboxMutation($id: ID!, $name: String!) {
    updateToolbox(id: $id, name: $name) {
      id
    }
  }
`

const WrapperForEditToolbox = ({ id }) => {
  const { loading, error, data } = useQuery(QUERY, { variables: { id } })
  const [updateToolbox, result] = useMutation(UPDATE_POST_MUTATION, {
    onCompleted: () => {
      location.reload()
    },
  })
  const onSave = (input, id) => {
    updateToolbox({ variables: { id, ...input } })
  }
  console.log('ID: ' + id)

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (!data.toolbox) {
    return <p>Empty ting</p>
  } else {
    return (
      <ToolboxForm
        toolbox={data.toolbox}
        onSave={onSave}
        error={result.error}
        loading={result.loading}
      />
    )
  }
}

export default WrapperForEditToolbox
