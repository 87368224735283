import { useState } from 'react'
import QuotesCell from 'src/components/QuotesCell'
import styled from 'styled-components'

import SitecontainerLayout from 'src/layouts/SitecontainerLayout/SitecontainerLayout'
import PageHeader from 'src/components/PageHeader/PageHeader'
import Button from 'src/components/Button/Button'
import FilterGroup from 'src/components/FilterGroup/FilterGroup'
import Select from 'src/components/Select/Select'
import ModalComponent from 'src/components/Modal/Modal'
import NewQuote from 'src/components/NewQuote/NewQuote'

import WrapperForDocuments from 'src/components/Wrappers/WrapperForDocuments'

const QuotesPage = () => {
  const filters = [
    { display: 'Drafts', value: 'drafts' },
    { display: 'Awaiting Signature', value: 'awaiting' },
    { display: 'Accepted', value: 'accepted' },
    { display: 'Declined', value: 'declined' },
  ]
  const initialOptions = [
    {
      id: 0,
      title: 'Apple',
      selected: true,
      key: 'fruit',
    },
  ]
  const [activeFilter, setActiveFilter] = useState('drafts')
  const [options, setOptions] = useState(initialOptions)

  return (
    <SitecontainerLayout noTop noLeft noRight>
      <PageHeader
        title="Quotes"
        text="Send a dynamic quote to a customer when you’ve finished creating a marked-up project plan."
        button={
          <ModalComponent
            modalSize="sm"
            button={<Button text="New Quote" />}
            header="New Quote"
            body={<NewQuote />}
          />
        }
      />
      <QuoteBody className="p-3 pl-5 pr-5">
        <QuoteDataHeader className="mb-2">
          <QuoteFilterContainer>
            <FilterGroup
              filters={filters}
              onClick={setActiveFilter}
              activeFilter={activeFilter}
            />
          </QuoteFilterContainer>
          <QuoteFilterContainer>
            <Select options={options} setOptions={setOptions} />
          </QuoteFilterContainer>
        </QuoteDataHeader>
        {/* <QuotesCell /> */}
        <WrapperForDocuments />
      </QuoteBody>
    </SitecontainerLayout>
  )
}

export default QuotesPage

const QuoteFilterContainer = styled.div`
  width: 40%;
`

const QuoteBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const QuoteDataHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
