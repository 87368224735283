import { useState } from 'react'
import styled from 'styled-components'

// auth goes here

import NewCoreLayout from 'src/layouts/NewCoreLayout'

import WrapperForUser from 'src/components/Wrappers/WrapperForUser'

const ProjectPage = ({ id }) => {
  return (
    <NewCoreLayout hideSidebar>
      {id && <WrapperForProject id={id} />}
    </NewCoreLayout>
  )
}

const AccountPage = () => {
  const [editing, setEditing] = useState(false)

  return (
    <NewCoreLayout>
      <WrapperForUser id={'can be anything right now'} />
    </NewCoreLayout>
  )
}

export default AccountPage
