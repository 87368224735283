import styled, { css } from 'styled-components'

export const FormBox = styled.div`
  display: grid;
  place-items: center;
`

export const FormColumn = styled.div`
  flex: 1;
  margin: 0rem 1rem;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) =>
    props.noTop
      ? css`
          padding-top: 0rem;
        `
      : css`
          padding-top: 1rem;
        `}
  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    > label {
      font-family: DM Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      /* identical to box height, or 150% */

      /* Dark blue */

      color: #001733;
    }
    > span {
      font-family: DM Sans;
      font-style: normal;
      font-size: 16px;
      color: #666;
      margin-left: 1rem;
      > a {
        color: var(--color-text-hyperlink);
        cursor: pointer;
      }
    }
  }
`

export const FormContainer = styled.form`
  position: relative;
  width: 100%;
`

export const FormBody = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`

export const FormFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

export const RequiredAsterix = styled.span`
  color: var(--color-danger-main);
`

export const FormError = styled.span`
  margin-top: 1rem;
  color: var(--color-danger-main);
`
