// This currently turns all SVGs into PNGs so that they can be embedded as a stamp annotations in the PDF document instead.
// Make sure that the original annotations remain pdf, this is purely for the

// Don't do this every save, only when a quote is created or updated.

export const savePdfWithAnnotations = async (
  annotManager,
  docViewer,
  instance,
  hard,
  saveWorking,
  saveDisplay,
  setAlreadySaving
) => {
  // 1. Convert all SVGs to PNGs for stamp
  // 2. Draw all Linkers directly to the document pages using fullAPI, then delete the annotations
  // 3. Save all other annotations to the document normally. (this should be a soft step, that runs even on normal saves, as these annots should be
  //    pdf exclusive and have no real relevance to quote or connections (stuff like measurements, comments, text))

  // normal: Saves all annotations other than ICONS and LINKERS (and eventually ROOMS) as XFDF string
  // hard: Saves ICONS as PNGS, draws Linkers directly to the document then

  const doc = docViewer.getDocument()

  let allAnnotations = annotManager.getAnnotationsList()

  if (hard) {
    let filteredAns = allAnnotations.filter(
      (annot) => annot instanceof instance.Annotations.StampAnnotation
    )

    await processSvgs(filteredAns)
    let filteredAns2 = allAnnotations.filter(
      (annot) => annot.Subject == 'Linker'
    )

    await bakeLinkers(filteredAns2, doc, instance)

    console.log(filteredAns2)
  }

  let xfdfString
  if (hard) {
    xfdfString = await annotManager.exportAnnotations()
  } else {
    let workingAns = allAnnotations.filter(
      (annot) =>
        !(annot instanceof instance.Annotations.StampAnnotation) &&
        annot.Subject !== 'Linker'
    )
    xfdfString = await annotManager.exportAnnotations({
      annotList: workingAns,
    })
  }

  console.log(xfdfString)

  if (hard) {
    const data = await doc.getFileData({
      // saves the document with annotations in it
      xfdfString,
    })

    const arr = new Uint8Array(data)

    const blob = new Blob([arr], { type: 'application/pdf' })

    await saveDisplay(blob)

    setAlreadySaving(false)
  } else {
    const blob = new Blob([xfdfString], {
      type: 'application/vnd.adobe.xfdf',
    })

    await saveWorking(blob)

    setAlreadySaving(false)
  }
}

const bakeLinkers = async (linkers, doc, instance) => {
  // ElementBuilder is used to build new Element objects
  const eb = await instance.PDFNet.ElementBuilder.create()
  // ElementWriter is used to write Elements to the page
  const writer = await instance.PDFNet.ElementWriter.create()

  const PDFDoc = await doc.getPDFDoc()

  console.log(doc.getPageInfo(0))

  let element
  let gstate

  // Reset the GState to default
  eb.reset()
  /* Brute force to allow gstate enough time to initialize styles. No documentation on which functions are async and which are not, so will need a full fix later */

  eb.moveTo(0, 0)
  element = await eb.pathEnd()
  gstate = await element.getGState()
  element.setPathStroke(true)
  gstate.setStrokeColorSpace(await instance.PDFNet.ColorSpace.createDeviceRGB())
  gstate.setStrokeColorWithColorPt(await instance.PDFNet.ColorPt.init(0, 0, 0))
  await gstate.setLineWidth(1)
  // IMPORTANT NOTE: PDFNet code was edited for this to work, as default, suggested PDFTron implementation didn't work.
  await gstate.setDashPattern([5, 5], 0)
  writer.writePlacedElement(element)
  writer.end()

  for (const linker of linkers) {
    let currentPage = await PDFDoc.getPage(linker.PageNumber)
    console.log(linker.PageNumber)
    let pageWidth = doc.getPageInfo(linker.PageNumber - 1).width
    let pageHeight = doc.getPageInfo(linker.PageNumber - 1).height
    console.log('PAGE NUMBER: ')
    console.log(linker.PageNumber - 1)
    let pageRotation = instance.docViewer.getCompleteRotation(linker.PageNumber)

    let linkerStart = { x: 0, y: 0 }
    let linkerEnd = { x: pageWidth, y: pageHeight }
    let linkerControl = { x: 200, y: 0 }

    switch (pageRotation) {
      case 1:
        linkerStart = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          pageWidth - linker.startY,
          linker.startX - (pageHeight - pageWidth) // why?
        )
        linkerEnd = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          pageWidth - linker.endY,
          linker.endX - (pageHeight - pageWidth) // why?
        )
        linkerControl = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          pageWidth - linker.controlY,
          linker.controlX - (pageHeight - pageWidth) // why?
        )
        console.log(linkerStart)
        console.log({ pageWidth: pageWidth, pageHeight: pageHeight })

        break

      case 2:
        linkerStart = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          pageWidth - linker.startX,
          pageHeight - linker.startY
        )
        linkerEnd = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          pageWidth - linker.endX,
          pageHeight - linker.endY
        )
        linkerControl = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          pageWidth - linker.controlX,
          pageHeight - linker.controlY
        )
        break

      case 3:
        linkerStart = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          linker.startY,
          pageHeight - linker.startX - (pageHeight - pageWidth)
        )
        linkerEnd = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          linker.endY,
          pageHeight - linker.endX - (pageHeight - pageWidth)
        )
        linkerControl = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          linker.controlY,
          pageHeight - linker.controlX - (pageHeight - pageWidth)
        )
        break

      default:
        linkerStart = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          linker.startX,
          linker.startY
        )
        linkerEnd = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          linker.endX,
          linker.endY
        )
        linkerControl = doc.getPDFCoordinates(
          linker.PageNumber - 1,
          linker.controlX,
          linker.controlY
        )
        break
    }

    writer.beginOnPage(currentPage)

    // start constructing the path
    console.log(linker)

    eb.pathBegin()
    eb.moveTo(linkerStart.x, linkerStart.y)
    // quadratic to cubic bezier conversion
    let control1X = (2 / 3) * linkerControl.x + (1 / 3) * linkerStart.x
    let control1Y = (2 / 3) * linkerControl.y + (1 / 3) * linkerStart.y
    let control2X = (2 / 3) * linkerControl.x + (1 / 3) * linkerEnd.x
    let control2Y = (2 / 3) * linkerControl.y + (1 / 3) * linkerEnd.y
    eb.curveTo(
      control1X,
      control1Y,
      control2X,
      control2Y,
      linkerEnd.x,
      linkerEnd.y
    )

    // the path is now finished
    element = await eb.pathEnd()

    gstate = await element.getGState()

    element.setPathStroke(true)
    gstate.setStrokeColorSpace(
      await instance.PDFNet.ColorSpace.createDeviceRGB()
    )
    gstate.setStrokeColorWithColorPt(
      await instance.PDFNet.ColorPt.init(0, 0, 0)
    )
    await gstate.setLineWidth(1)
    // IMPORTANT NOTE: PDFNet code was edited for this to work, as default, suggested PDFTron implementation didn't work.
    await gstate.setDashPattern([5, 5], 0)
    writer.writePlacedElement(element)
    // save changes to the current page
    // delete the annotation
    instance.annotManager.deleteAnnotation(linker.id)
    writer.end()
  }
}

const svgToPng = async (svg64) => {
  // 1. get back pure svg ?
  // 2. create a canvas
  // 3. draw svg to canvas
  // 4. save canvas image as png
  // 5. base64 png
  // 6. return png64

  //
  return new Promise((resolve, reject) => {
    let svg = atob(svg64.slice(26))
    const svgDocument = new DOMParser().parseFromString(svg, 'image/svg+xml')
    let svgWidth = svgDocument.documentElement.width.baseVal.value
    let svgHeight = svgDocument.documentElement.height.baseVal.value

    let canvas = document.createElement('canvas')
    canvas.width = svgWidth ? svgWidth : 100
    canvas.height = svgHeight ? svgHeight : 100
    canvas.style.display = 'none'
    let ctx = canvas.getContext('2d')

    let DOMURL = window.URL || window.webkitURL || window
    let img = new Image()
    let svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' })
    let url = DOMURL.createObjectURL(svgBlob)

    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      DOMURL.revokeObjectURL(url)
      let imgURI = canvas.toDataURL('image/png')
      canvas.remove()
      console.log('DONE A')
      resolve(imgURI)
    }

    img.src = url
  })
}

const processSvgs = (annotationList) => {
  let promises = []
  annotationList.forEach((annot) => {
    promises.push(
      new Promise(async (res, rej) => {
        annot.ImageData = await svgToPng(annot.ImageData)
        res(true)
      })
    )
  })
  return Promise.all(promises).then(() => {
    console.log('DONE')
    return true
  })
}
