import ReactDOM from 'react-dom'
import { RedwoodProvider, FatalErrorBoundary } from '@redwoodjs/web'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

import Auth from './utils/auth'

import Apollo from './utils/apollo'

import App from './App'

import { BrowserRouter as Router } from 'react-router-dom'

import './scaffold.css'
import './index.css'
import 'react-tippy/dist/tippy.css'
import 'rsuite/dist/styles/rsuite-default.css'

ReactDOM.render(
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider>
      <Auth>
        <App />
      </Auth>
    </RedwoodProvider>
  </FatalErrorBoundary>,
  document.getElementById('redwood-app')
)
