import styled, { css } from 'styled-components'
import { useDrag } from 'react-dnd'
import { useState, useContext } from 'react'

import Row from '../Row/Row'
import EditSVG from '../EditSVG/EditSVG'
import DuplicateSVG from '../DuplicateSVG/DuplicateSVG'
import Trash from '../Trash/Trash'
import ModalComponent from '../Modal/Modal'
import ModalConfirm from '../ModalConfirm/ModalConfirm'

import { ModalContext } from 'src/utils/ModalContext'

import WrapperForEditFitting from 'src/components/Wrappers/WrapperForEditFitting'

const IconCard = ({
  id,
  name,
  category,
  image,
  make,
  model,
  notes,
  noText,
  partNumber,
  priceToSupply,
  priceToInstall,
  noContainer,
  className,
  updateToolboxFittings,
  setDrag,
  toolboxUpdateSave,
  inProject,
  type = 'icon',
  toolbox,
  displayIcons,
  editable = false,
  duplicate = () => {},
  remove = () => {},
}) => {
  const icon = {
    id,
    name,
    category,
    image,
    make,
    model,
    notes,
    partNumber,
    priceToSupply,
    priceToInstall,
    type,
  }
  const { openModal, closeModal } = useContext(ModalContext)
  const [{ isDragging }, drag] = useDrag({
    // used to be just item: icon
    item: { ...icon, type: 'icon' },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  return (
    <IconCardContainer
      ref={drag}
      className={className}
      noContainer={noContainer}
      isDragging={isDragging}
      onDragStart={setDrag}
      draggable
      noText={noText}
    >
      {editable && (
        <IconEditMenu>
          <Row className="mb-half ml-half align-items-center pointed">
            <EditSVG className="pr-half" />
            <MenuText
              onClick={() =>
                openModal(
                  <>
                    <WrapperForEditFitting
                      close={closeModal}
                      toolbox={toolbox}
                      inProject={inProject}
                      updateToolboxFittings={updateToolboxFittings}
                      displayIcons={displayIcons}
                      toolboxUpdateSave={toolboxUpdateSave}
                      id={id}
                    />
                  </>
                )
              }
            >
              Edit Icon
            </MenuText>
          </Row>
          {/* <Row
            className="mb-half ml-half align-items-center pointed"
            onClick={duplicate}
          >
            <ModalConfirm
              message="Would you like to duplicate this icon?"
              trigger={
                <div className="align-items-center">
                  <DuplicateSVG className="pr-half" />
                  <MenuText>Duplicate</MenuText>
                </div>
              }
              onConfirm={() => duplicate(icon)}
            />
          </Row>*/}

          <Row className="mb-half ml-half align-items-center pointed">
            <ModalConfirm
              message="Would you like to remove this icon?"
              trigger={
                <div className="align-items-center">
                  <Trash className="pr-half" fill="white" />
                  <MenuText>Remove</MenuText>
                </div>
              }
              onConfirm={() => remove(id)}
            />
          </Row>
        </IconEditMenu>
      )}
      {<IconCardHeader src={image} noText={noText} />}
      {!noText && (
        <IconCardInfo>
          <IconCardText>{name}</IconCardText>
        </IconCardInfo>
      )}
    </IconCardContainer>
  )
}

export default IconCard

const IconEditMenu = styled.div`
  /* display: none;
  :hover { */
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 9;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  transition: opacity 0.2s;
  background-color: rgba(0, 23, 51, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5rem;
  :hover {
    opacity: 1;
    transition: opacity 0.2s;
  }
`

const MenuText = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  cursor: pointer;
  color: #ffffff;

  :hover {
    color: #379ecc;
  }
`

const IconCardContainer = styled.div`
  background: #ffffff;
  border-radius: 10px;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  position: relative;
  height: ${(p) => (p.noText ? '61px' : '83px')};
  width: 78px;

  cursor: pointer;

  margin: 0.3rem;

  ${(p) =>
    p.noContainer &&
    css`
      box-shadow: none;
      background: none;
    `}
  ${(p) =>
    p.isDragging &&
    css`
      opacity: 0.6;
    `}
`

const IconCardHeader = styled.img`
  display: flex;
  /* background-size: 100%;
  background-image: url(${(p) => p.img}); */
  justify-content: center;
  align-items: center;
  height: ${(p) => (p.noText ? '69%' : '50%')};
  width: 100%;
`

const IconCardInfo = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
`

const IconCardText = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  text-align: center;
  color: #001733;
  opacity: 0.6;
`
