import React from 'react'
import { useQuery, gql } from '@apollo/client'
import Customers from 'src/components/Customers'
import LoadingIcon from 'src/components/LoadingIcon'

export const QUERY = gql`
  query POSTS($activeFilter: String) {
    customers(status: $activeFilter) {
      id
      email
      firstName
      surname
      phone
      company
      status
    }
  }
`

const WrapperForCustomers = ({ activeFilter }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: { activeFilter },
  })
  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (data?.customers.length == 0) {
    return (
      <div className="text-center">{`No ${activeFilter} customers yet. `}</div>
    )
  } else {
    console.log(data)
    return <Customers customers={data.customers} />
  }
}

export default WrapperForCustomers
