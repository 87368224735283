import Lottie from 'react-lottie'

const LoadingIcon = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      className: 'loading-color',
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return <Lottie options={defaultOptions} height={50} width={50} />
}

export default LoadingIcon

const animationData = {
  v: '5.4.4',
  fr: 24,
  ip: 0,
  op: 28,
  w: 50,
  h: 50,
  nm: 'Hourglass',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Hourglass 1',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.469],
                y: [0],
              },
              t: 3,
              s: [0],
              e: [186],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 9,
              s: [186],
              e: [180],
            },
            {
              t: 11,
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [25, 25, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [25, 25, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 3,
              s: [100, 100, 100],
              e: [75, 75, 100],
            },
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 5,
              s: [75, 75, 100],
              e: [93, 93, 100],
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 9,
              s: [93, 93, 100],
              e: [100, 100, 100],
            },
            {
              t: 11,
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.833,
                      y: 0.833,
                    },
                    o: {
                      x: 0.167,
                      y: 0.167,
                    },
                    t: 3,
                    s: [
                      {
                        i: [
                          [1.268, 0.478],
                          [0, -7.646],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-1.267, 0.499],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, -7.807],
                        ],
                        v: [
                          [0.004, -8.094],
                          [-9.5, 3.594],
                          [-9.5, 3.594],
                          [9.5, 3.594],
                          [9.5, 3.594],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [1.268, 0.478],
                          [0.016, -7.305],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-1.267, 0.499],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.031, -7.55],
                        ],
                        v: [
                          [6.115, -8.334],
                          [-9.234, 1.993],
                          [-9.234, 2.008],
                          [9.125, 1.992],
                          [9.188, 2.008],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.833,
                      y: 0.833,
                    },
                    o: {
                      x: 0.167,
                      y: 0.167,
                    },
                    t: 5,
                    s: [
                      {
                        i: [
                          [1.268, 0.478],
                          [0.016, -7.305],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-1.267, 0.499],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.031, -7.55],
                        ],
                        v: [
                          [6.115, -8.334],
                          [-9.234, 1.993],
                          [-9.234, 2.008],
                          [9.125, 1.992],
                          [9.188, 2.008],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [1.268, 0.478],
                          [0.047, -6.623],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-1.267, 0.499],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.094, -7.037],
                        ],
                        v: [
                          [-1.196, -12.48],
                          [-9.255, -1.963],
                          [-9.255, -1.916],
                          [9.111, -3.856],
                          [9.164, -3.795],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: {
                      x: 0.833,
                      y: 0.833,
                    },
                    o: {
                      x: 0.167,
                      y: 0.167,
                    },
                    t: 9,
                    s: [
                      {
                        i: [
                          [1.268, 0.478],
                          [0.047, -6.623],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-1.267, 0.499],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.094, -7.037],
                        ],
                        v: [
                          [-1.196, -12.48],
                          [-9.255, -1.963],
                          [-9.255, -1.916],
                          [9.111, -3.856],
                          [9.164, -3.795],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [1.268, 0.478],
                          [0.062, -6.281],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-1.267, 0.499],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.125, -6.781],
                        ],
                        v: [
                          [0.008, -11.719],
                          [-8.438, -2.812],
                          [-8.438, -2.75],
                          [8, -2.812],
                          [8.25, -2.75],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 11,
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [25, 39.406],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-2.225, -1.483],
                    [0, 0],
                    [0, 0],
                    [0, -2.674],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 2.675],
                    [0, 0],
                    [0, 0],
                    [-2.225, 1.484],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.5, -18],
                    [-4.5, -10.281],
                    [-0.938, -3.625],
                    [4.5, 0],
                    [-0.938, 3.625],
                    [-4.5, 10.281],
                    [-4.5, 18],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [16.5, 25],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.225, -1.483],
                    [0, 0],
                    [0, 0],
                    [0, -2.674],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 2.675],
                    [0, 0],
                    [0, 0],
                    [2.225, 1.484],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.5, -18],
                    [4.5, -10.281],
                    [0.938, -3.625],
                    [-4.5, 0],
                    [0.938, 3.625],
                    [4.5, 10.281],
                    [4.5, 18],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [33.5, 25],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.105, 0],
                    [0, 0],
                    [0, -1.105],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, -1.105],
                    [0, 0],
                    [1.105, 0],
                    [0, 0],
                  ],
                  v: [
                    [18, 2.5],
                    [-18, 2.5],
                    [-18, -0.5],
                    [-16, -2.5],
                    [16, -2.5],
                    [18, -0.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [25, 45.5],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.105, 0],
                    [0, 0],
                    [0, 1.105],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.105, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 1.105],
                  ],
                  v: [
                    [-16, 2.5],
                    [16, 2.5],
                    [18, 0.5],
                    [18, -2.5],
                    [-18, -2.5],
                    [-18, 0.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [25, 4.5],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 11,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Middle',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [25, 25, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [25, 25, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [25, 22],
                    [25, 42],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: {
                  x: [0.667],
                  y: [1],
                },
                o: {
                  x: [0.333],
                  y: [0],
                },
                t: 17,
                s: [0],
                e: [0],
              },
              {
                i: {
                  x: [0.667],
                  y: [1],
                },
                o: {
                  x: [0.333],
                  y: [0],
                },
                t: 22,
                s: [0],
                e: [100],
              },
              {
                t: 25,
              },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: {
                  x: [0.667],
                  y: [1],
                },
                o: {
                  x: [0.333],
                  y: [0],
                },
                t: 17,
                s: [0],
                e: [100],
              },
              {
                i: {
                  x: [0.667],
                  y: [1],
                },
                o: {
                  x: [0.333],
                  y: [0],
                },
                t: 21,
                s: [100],
                e: [100],
              },
              {
                t: 25,
              },
            ],
            ix: 2,
          },
          o: {
            a: 0,
            k: 0,
            ix: 3,
          },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 0,
      op: 28,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Mask top',
      td: 1,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 17,
              s: [25.25, 36.875, 0],
              e: [25.25, 48.75, 0],
              to: [0, 1.979, 0],
              ti: [0, -1.979, 0],
            },
            {
              t: 24,
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [25, 25, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-23, 6.501],
                    [23, 6.501],
                    [23, -6.501],
                    [-23, -6.501],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.969000004787, 0.541000007181, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [25, 6.5],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 11,
      op: 28,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Top shape',
      tt: 1,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [25, 25, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [25, 25, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.958, -0.238],
                    [-0.339, 6.712],
                    [0, 0],
                  ],
                  o: [
                    [1.018, -0.254],
                    [0, 0],
                    [0.34, 6.737],
                  ],
                  v: [
                    [-0.001, 4.493],
                    [8.487, -4.493],
                    [-8.487, -4.493],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [25, 17.993],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 28,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Mask bottom',
      td: 1,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [25, 25, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [25, 25, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [23, 6.5],
                    [-23, 6.5],
                    [-23, -6.5],
                    [23, -6.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.620000023935, 0.961000031116, 1, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [25, 36.5],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 28,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Bottom shape',
      tt: 1,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 18,
              s: [25, 37.514, 0],
              e: [25, 24.514, 0],
              to: [0, -2.167, 0],
              ti: [0, 2.167, 0],
            },
            {
              t: 25,
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [25, 25, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.268, 0.478],
                    [0, -7.646],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.267, 0.499],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -7.807],
                  ],
                  v: [
                    [0.004, -8.094],
                    [-9.5, 3.594],
                    [-9.5, 8.094],
                    [9.5, 8.094],
                    [9.5, 3.594],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [25, 39.406],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 28,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Hourglass 2',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [25, 25, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [25, 25, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-2.225, -1.483],
                    [0, 0],
                    [0, 0],
                    [0, -2.674],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 2.675],
                    [0, 0],
                    [0, 0],
                    [-2.225, 1.484],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.5, -18],
                    [-4.5, -10.281],
                    [-0.938, -3.625],
                    [4.5, 0],
                    [-0.938, 3.625],
                    [-4.5, 10.281],
                    [-4.5, 18],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [16.5, 25],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.225, -1.483],
                    [0, 0],
                    [0, 0],
                    [0, -2.674],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 2.675],
                    [0, 0],
                    [0, 0],
                    [2.225, 1.484],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.5, -18],
                    [4.5, -10.281],
                    [0.938, -3.625],
                    [-4.5, 0],
                    [0.938, 3.625],
                    [4.5, 10.281],
                    [4.5, 18],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [33.5, 25],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.105, 0],
                    [0, 0],
                    [0, -1.105],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, -1.105],
                    [0, 0],
                    [1.105, 0],
                    [0, 0],
                  ],
                  v: [
                    [18, 2.5],
                    [-18, 2.5],
                    [-18, -0.5],
                    [-16, -2.5],
                    [16, -2.5],
                    [18, -0.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [25, 45.5],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.105, 0],
                    [0, 0],
                    [0, 1.105],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.105, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 1.105],
                  ],
                  v: [
                    [-16, 2.5],
                    [16, 2.5],
                    [18, 0.5],
                    [18, -2.5],
                    [-18, -2.5],
                    [-18, 0.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [25, 4.5],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 11,
      op: 28,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
