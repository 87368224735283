import React, { useContext, useEffect, useState } from 'react'
import {
  ApolloProvider,
  createHttpLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { ApolloLink } from 'apollo-link'
import { GRAPHQL_URL } from '../constants/urls'

import { AuthContext } from './auth'

const Apollo = ({ children }) => {
  const { authenticated, user } = useContext(AuthContext)

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        if (message == 'Context creation failed: User is not logged in') {
          location.reload()
        }
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        )
      })
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  const httpLink = createHttpLink({
    uri: GRAPHQL_URL,
  })

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    let idToken
    if (authenticated) {
      idToken = user.token
    }
    return {
      headers: {
        ...headers,
        ...(authenticated ? { authorization: `Bearer ${idToken}` } : {}),
      },
    }
  })

  const apolloLink = ApolloLink.from([errorLink, authLink, httpLink])

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: apolloLink,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
  })
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default Apollo
