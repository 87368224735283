import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery, gql } from '@apollo/client'
import Toolboxes from 'src/components/Toolboxes'

import { Link } from 'react-router-dom'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query POSTS($activeFilter: String) {
    toolboxes(status: $activeFilter, type: "user") {
      id
      name
      fittings
      projects {
        id
      }
      status
    }
  }
`

const WrapperForToolboxes = ({ activeFilter }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: { activeFilter },
  })

  console.log('FILTER: ' + activeFilter)

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (data?.toolboxes.length == 0) {
    console.log(JSON.stringify(data))
    return (
      <div className="text-center">
        {'No toolboxes yet. '}
        <Link
          to={'/toolboxes/new'}
          className="text-blue-500 underline hover:text-blue-700"
        >
          {'Create one?'}
        </Link>
      </div>
    )
  } else {
    console.log(JSON.stringify(data))
    return <Toolboxes toolboxes={data.toolboxes} />
  }
}

export default WrapperForToolboxes
