import styled, { css } from 'styled-components'

import Header from 'src/components/Header/Header'
import Sidebar from 'src/components/Sidebar/Sidebar'

const Layout = styled.div`
  height: max-content;
  min-height: calc(100vh - 72px);
  width: 100vw;
`

const SiteContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: max-content;
  min-height: calc(100vh - 72px);
`

const SiteBody = styled.div`
  min-height: calc(100vh - 72px);
  /* height: calc(100vh - 72px); */
  width: 100%;
  padding: 1rem;
  background: #E4EEF2;
  ${(props) =>
    props.noTop &&
    css`
      padding-top: 0;
    `}
  ${(props) =>
    props.noBottom &&
    css`
      padding-bottom: 0;
    `}
  ${(props) =>
    props.noRight &&
    css`
      padding-right: 0;
    `}
  ${(props) =>
    props.noLeft &&
    css`
      padding-left: 0;
    `}
`

const SitecontainerLayout = ({
  children,
  hideSidebar = false,
  noHeader,
  ...restOfProps
}) => {
  return (
    <Layout>
      {!noHeader ? <Header /> : null}
      <SiteContainer {...restOfProps}>
        {!hideSidebar && <Sidebar />}
        <SiteBody {...restOfProps}>{children}</SiteBody>
      </SiteContainer>
    </Layout>
  )
}

export default SitecontainerLayout
