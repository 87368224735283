import styled from 'styled-components'
import React, { useReducer, useEffect } from 'react'
import { useState } from 'react'
import Project from 'src/components/Project'
import { useQuery, useMutation, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'

/*
import { formatter, formatFullAddress } from 'src/utils/utils'
import PageHeader from 'src/components/PageHeader/PageHeader'
import EditProjectPage from 'src/pages/EditProjectPage/EditProjectPage'
*/

import InnerContainer from 'src/components/Layout/InnerContainer'
import TopBar from 'src/components/Layout/TopBar'
import RightBar from 'src/components/Layout/RightBar'

import LoadingIcon from '../LoadingIcon/LoadingIcon'
import Row from '../Row/Row'
import Button from '../Button/Button'
import ProjectQuotes from '../ProjectQuotes/ProjectQuotes'
import Variations from '../Variations/Variations'
import Back from '../Back/Back'

function numberWithCommas(x) {
  let y = x.toFixed(2)
  return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    project: project(id: $id) {
      id
      name
      address1
      address2
      city
      postCode
      reference
      toolbox {
        id
        name
        fittings
      }
      customer {
        id
        firstName
        surname
        email
        address1
        address2
        city
        postCode
      }
      variations {
        id
        createdAt
        version
        file
        workingFile
        icons
        iconsUsed
        estimate
        documentId
      }
      userId
    }
  }
`

const UPDATE_POST_MUTATION = gql`
  mutation UpdateVariationMutation(
    $id: ID!
    $version: Int
    $file: String
    $iconsUsed: Int
    $icons: [String]
    $estimate: Float
  ) {
    updateVariation(
      id: $id
      version: $version
      file: $file
      iconsUsed: $iconsUsed
      icons: $icons
      estimate: $estimate
    ) {
      id
    }
  }
`

const CREATE_NEW_QUOTE = gql`
  mutation CreateDocumentMutation(
    $reference: String
    $attachments: String
    $notes: String
    $status: String
    $projectId: String
    $variationId: String
    $customerId: String
  ) {
    createDocument(
      reference: $reference
      attachments: $attachments
      notes: $notes
      status: $status
      type: "quote"
      projectId: $projectId
      variationId: $variationId
      customerId: $customerId
    ) {
      id
    }
  }
`

const iconsReducer = (iconsList, action) => {
  let icons, index, element
  switch (action.type) {
    case 'add new':
      return [...iconsList, action.payload]

    case 'bulk update':
      return action.payload

    case 'delete icon':
      icons = iconsList
      index = icons.findIndex((icon) => icon.id === action.payload.id)
      icons.splice(index, 1)
      return [...icons]

    case 'update fittings':
      icons = iconsList
      console.log(JSON.stringify(icons))
      icons.forEach((icon, i, icons) => {
        console.log('WHEN')
        if (icon.fittingId == action.payload.id) {
          icon = {
            ...icon,
            fittingSupplyPrice: action.payload.priceToSupply,
            fittingInstallPrice: action.payload.priceToInstall,
            fittingName: action.payload.name,
          }
          icons[i] = icon
        }
      })
      console.log(icons)
      return [...icons]

    case 'update rotation':
      icons = iconsList
      element = icons.find((icon, i) => {
        index = i
        return icon.id === action.payload.id
      })
      element.rotation = action.payload.rotation
      icons.splice(index, 1)
      icons.push(element)
      return [...icons]

    case 'update position':
      icons = iconsList
      element = icons.find((icon, i) => {
        index = i
        return icon.id === action.payload.id
      })
      element.x = action.payload.x
      element.y = action.payload.y
      icons.splice(index, 1)
      icons.push(element)
      return [...icons]

    case 'add link':
      icons = iconsList
      element = icons.find((icon, i) => {
        index = i
        return icon.id === action.payload.id
      })
      if (element) {
        if (element.links && element.links !== '') {
          element.links.push(action.payload.linkTo)
        } else {
          element.links = [action.payload.linkTo]
        }
        icons.splice(index, 1)
        icons.push(element)
      }
      return [...icons]

    case 'remove link':
      icons = iconsList
      element = icons.find((icon, i) => {
        index = i
        return icon.id === action.payload.id
      })
      if (element) {
        if (element.links.length > 1) {
          const linkIndex = element.links.indexOf(action.payload.linkTo)
          element.links.splice(linkIndex, 1)
        } else {
          element.links = ''
        }
        icons.splice(index, 1)
        icons.push(element)
      }
      return [...icons]

    default:
      throw new Error()
  }
}

const WrapperForProject = ({ id }) => {
  const history = useHistory()
  const { loading, error, data } = useQuery(QUERY, {
    variables: { id },
  })
  const [iconsList, dispatch] = useReducer(iconsReducer, [])
  const [estimate, setEstimate] = useState(0)
  const [initialLoad, setInitialLoad] = useState(false)

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (!data.project) {
    return <p>Project Not Found</p>
  } else {
    if (data.project.variations[0].icons && !initialLoad) {
      let parseIcons = data.project.variations[0].icons.map((icon) =>
        JSON.parse(icon)
      )
      dispatch({
        type: 'bulk update',
        payload: parseIcons,
      })

      setEstimate(data.project.variations[0].estimate)
      setInitialLoad(true)
    }
    return (
      <SuccessComponent
        project={data.project}
        iconsList={iconsList}
        dispatch={dispatch}
        history={history}
        estimate={estimate}
        initialLoad={initialLoad}
        setEstimate={setEstimate}
      />
    )
  }
}

export default WrapperForProject

const SuccessComponent = ({
  project,
  iconsList,
  dispatch,
  initialLoad,
  history,
  estimate,
  setEstimate,
}) => {
  const [creatingQuote, setCreatingQuote] = useState(false)
  const [saving, setSaving] = useState(false)
  const [savingFull, setSavingFull] = useState(false)
  const [documentId, setDocumentId] = useState(null)
  const [documentReady, setDocumentReady] = useState(false)
  const [updateVariation, variationMutationData] = useMutation(
    UPDATE_POST_MUTATION,
    {
      onCompleted: () => {
        setSaving(false)
        if (creatingQuote) {
          console.log('Yarrrr')
          if (project.variations[0].documentId) {
            setDocumentId(project.variations[0].documentId)
            setDocumentReady(true)
            //history.push(`/quote/${project.variations[0].documentId}`)
          } else {
            createDocument({
              variables: {
                reference: '',
                attachments: '',
                notes: '',
                status: '',
                projectId: project.id,
                variationId: project.variations[0].id,
                customerId: project.customer.id,
              },
            })
          }
        }
      },
    }
  )
  const [createDocument, documentMutationData] = useMutation(CREATE_NEW_QUOTE, {
    onCompleted: ({ createDocument: { id } }) => {
      setDocumentId(id)
      setDocumentReady(true)
    },
  })
  const tabs = [
    { name: 'Plans', tab: 'project' },
    { name: 'Variations', tab: 'variations' },
    { name: 'Quotes', tab: 'quotes' },
    { name: 'Settings', tab: 'settings' },
  ]
  const [activeVariation, setActiveVariation] = useState(
    project?.variations.length - 1
  )
  const [activeTab, setActiveTab] = useState('project')

  const [changes, setChanges] = useState(0)
  // Autosave after 5 changes
  const saves = 5

  useEffect(() => {
    // On each change, increment changes until it hits the amount to autosave
    // Then auto save and reset
    console.log(initialLoad)
    if (initialLoad) {
      if (changes == saves) {
        saveVariation()
        setChanges(0)
      } else {
        setChanges((c) => c + 1)
        if (changes > 0) {
          const timer = setTimeout(() => {
            console.log('Autosaving')
            saveVariation()
            setChanges(0)
          }, 5000)
          return () => clearTimeout(timer)
        }
      }
    }
  }, [iconsList])

  useEffect(() => {
    if (!savingFull && documentReady) {
      history.push(`/quote/${documentId}`)
    }
  }, [documentReady, savingFull])

  /*
  useEffect(() => {
    if (!saving && !savingFull) {
      console.log('DONE')
      const timer = setInterval(() => {
        console.log('Autosaving')
        saveVariation()
      }, 200000)
      return () => clearInterval(timer)
    }
  }, [saving, savingFull, iconsList])
  */

  const saveVariation = () => {
    setSaving(true)
    let stringifiedIcons = iconsList.map((icon) => JSON.stringify(icon))
    console.log(stringifiedIcons)
    updateVariation({
      variables: {
        id: project.variations[0].id,
        icons: stringifiedIcons,
        estimate: estimate,
      },
    })
  }

  const toolboxUpdateSave = (fitting) => {
    console.log('we made it')
    console.log(fitting)
    dispatch({
      type: 'update fittings',
      payload: fitting,
    })
    saveVariation()
  }

  const createQuote = () => {
    setCreatingQuote(true)
    setSavingFull(true)
    saveVariation()
  }

  return (
    <>
      <TopBar>
        <Back className="mt-1" routeTo={'/projects'} />
        <CurrentToolTitle>{project.name}</CurrentToolTitle>
      </TopBar>
      <InnerContainer>
        <Project
          active={activeTab === 'project'}
          project={project}
          activeVariation={activeVariation}
          iconsList={iconsList}
          dispatch={dispatch}
          estimate={estimate}
          setEstimate={setEstimate}
          toolboxUpdateSave={toolboxUpdateSave}
          saving={saving}
          savingFull={savingFull}
          saveVariation={saveVariation}
          setSavingFull={setSavingFull}
        />
        <RightBar>
          <Data>
            {project.reference && (
              <DataRow>
                <DataBlock>
                  <DataLabel>Reference</DataLabel>
                  <DataInfo>{project.reference}</DataInfo>
                </DataBlock>
              </DataRow>
            )}
            {project.address1 && (
              <DataRow>
                <DataBlock>
                  <DataLabel>Address</DataLabel>
                  <DataInfo>{project.address1}</DataInfo>
                  {project.address2 && <DataInfo>{project.address2}</DataInfo>}
                  {project.postCode && <DataInfo>{project.postCode}</DataInfo>}
                </DataBlock>
              </DataRow>
            )}

            <DataRow>
              <DataBlock>
                <DataLabel>Customer</DataLabel>
                <DataInfo>{`${project.customer.firstName} ${project.customer.surname}`}</DataInfo>
              </DataBlock>
            </DataRow>
            <Divider />
            <DataRow style={{ marginBottom: '0px' }}>
              <DataBlock>
                <DataLabel>Estimate</DataLabel>
                <DataInfo style={{ fontSize: '24px' }}>
                  {'£' + numberWithCommas(estimate)}
                </DataInfo>
              </DataBlock>
            </DataRow>
          </Data>
          <ButtonSection>
            <Button
              text="Save"
              btnStyle="clear"
              onClick={saveVariation}
              marginBottom={true}
              disabled={savingFull || saving}
            />
            <Button
              text="Create Quote"
              btnStyle="primary"
              onClick={createQuote}
              disabled={saving || savingFull}
            />
          </ButtonSection>
        </RightBar>
      </InnerContainer>
    </>
  )
}

const CurrentToolTitle = styled.span`
  margin-right: 2rem;
  color: white;
  font-family: Josefin Sans;
  font-style: normal;
  font-size: 26px;
`

const ButtonSection = styled.div`
  display: flex;
  margin: 1rem;
  padding: 1rem;
  width: 88%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem;
  margin-left: 0px;
  margin-top: 0px;
`

const DataBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const DataLabel = styled.span`
  /* Bold */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  /* identical to box height, or 150% */

  /* Dark blue */
  color: #001733;
  color: white;
`

const DataInfo = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
  color: white;
`

const Data = styled.div`
  /* Default */
  /*
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  
  border-radius: 20px;
  border: white 1px solid;
  */
  padding: 1rem;
  margin: 1rem;
  width: 85%;
`

// New parts finish

const Divider = styled.hr``
