import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import SitecontainerLayout from 'src/layouts/SitecontainerLayout'

import InputNonRW from '../../components/Input/InputNonRW'
import Button from '../../components/Button/Button'

import LoadingIcon from 'src/components/LoadingIcon'

import { AuthContext } from '../../utils/auth'

const LoginPage = () => {
  const { logIn } = useContext(AuthContext)
  return (
    <SitecontainerLayout style={{ height: '100vh' }} noTop noHeader hideSidebar>
      <StyledContainer>
        <LoginBox logIn={logIn} />
      </StyledContainer>
    </SitecontainerLayout>
  )
}

export default LoginPage

const reduceErrors = (error) => {
  switch (error.code) {
    case 'auth/invalid-email':
      return {
        elements: ['email'],
        message: 'The email address entered is invalid',
      }
    case 'auth/wrong-password':
      return { elements: ['password'], message: 'The password is invalid' }
    case 'auth/user-not-found':
      return { elements: [], message: 'User not found' }
    default:
      return { elements: [], message: 'An error occurred' }
  }
}

const LoginBox = ({ logIn }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (email !== '' && password !== '') {
      setLoading(true)
      logIn(email, password)
        .then((res) => {
          window.location.href = '/dashboard'
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
          setError(reduceErrors(err))
        })
    } else {
      setError({ message: 'Required fields missing' })
    }
  }
  return (
    <DashboardInfoCard className={'className'}>
      <>
        <DashboardInfoCardHeaderContainer>
          <DashboardInfoCardHeader>{'Sign in'}</DashboardInfoCardHeader>
        </DashboardInfoCardHeaderContainer>
        <DashboardInfoCardDivider />
        <DashboardTableContainer>
          <StyledForm>
            <AccountDataRow>
              <AccountDataBlock>
                <FormLabel name="Email Address">
                  Email address <RequiredAsterix>*</RequiredAsterix>
                </FormLabel>
                <InputNonRW
                  name="email"
                  placeholder=""
                  onChange={handleEmailChange}
                />
              </AccountDataBlock>
            </AccountDataRow>
            <AccountDataRow>
              <AccountDataBlock>
                <FormLabel name="Other Line">
                  Password <RequiredAsterix>*</RequiredAsterix>
                </FormLabel>
                <InputNonRW
                  name="password"
                  type="password"
                  placeholder="At least 8 characters"
                  onChange={handlePasswordChange}
                />
              </AccountDataBlock>
            </AccountDataRow>
            <div className="mt-8 text-center">
              {loading ? (
                <LoadingIcon />
              ) : (
                <Button
                  text="Sign In"
                  onClick={handleSubmit}
                  className="mt-2"
                />
              )}
            </div>
            {error && (
              <ErrorBox>
                <span>{error.message}</span>
              </ErrorBox>
            )}
          </StyledForm>
        </DashboardTableContainer>
        <DashboardInfoCardDivider />
        <DashboardInfoCardFooterContainer>
          <Link to={'/signup'} style={{ color: 'var(--color-text-hyperlink)' }}>
            Create an account
          </Link>
        </DashboardInfoCardFooterContainer>
      </>
    </DashboardInfoCard>
  )
}

const RequiredAsterix = styled.span`
  color: red;
`

const ErrorBox = styled.div`
  display: grid;
  place-items: center;
  padding: 0.5rem 0rem;
  > span {
    color: var(--color-danger-main);
    text-align: center;
    font-size: var(--text-info-size);
  }
`

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DashboardInfoCard = styled.div`
  background: #ffffff;
  /* Default */

  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 40%;
`

const DashboardInfoCardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  height: 2rem;
  padding: 3rem;
`

const DashboardInfoCardFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 2rem;
  padding: 3rem;
`

const DashboardInfoCardHeader = styled.span`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 150% */

  color: #001733;
`

const DashboardTableContainer = styled.div`
  padding: var(--form-container-padding);
`

const DashboardInfoCardDivider = styled.div`
  width: 90%;
  margin: auto;
  border-bottom: 1px solid rgba(0, 23, 51, 0.2);
`

const TableCardActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 40%;
  cursor: pointer;
`

const StyledForm = styled.form`
  position: relative;
  width: 100%;
`

const FormLabel = styled.label`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: var(--input-label-font-size);
  /* identical to box height, or 150% */

  /* Dark blue */

  color: #001733;
`

const AccountDataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) =>
    props.noTop
      ? css`
          padding-top: 0rem;
        `
      : css`
          padding-top: var(--form-padding);
        `}
`

const AccountDataBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const LoadingCover = styled.div`
  /* display: none;
  :hover { */
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  background-color: rgba(230, 230, 230, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5rem;
`
