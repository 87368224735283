import React, { useContext } from 'react'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

import { BrowserRouter as Router } from 'react-router-dom'

import GlobalStyles from './constants/GlobalStyles'

import Apollo from './utils/apollo'

import AllRoutes from './routes/AllRoutes'

import { AuthContext } from './utils/auth'
import { ModalProvider } from './utils/ModalContext'
import Uploader from './utils/uploader'

const App = () => {
  const {
    tempLoaded,
    token,
    authenticated,
    subscribed,
    getLoggedIn,
    getSubscribedToken,
  } = useContext(AuthContext)
  return tempLoaded ? (
    <>
      <GlobalStyles />
      <Apollo>
        <Uploader>
          <DndProvider backend={Backend}>
            <Router>
              <ModalProvider>
                <AllRoutes />
              </ModalProvider>
            </Router>
          </DndProvider>
        </Uploader>
      </Apollo>
    </>
  ) : (
    <span>Loading, please wait...</span>
  )
}

export default App
