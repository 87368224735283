import { useState, useRef } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components'

import ModalComponent from '../Modal/Modal'
import Row from '../Row/Row'
import Button from '../Button/Button'

const ModalConfirm = ({
  trigger,
  message,
  modalSize = 'md',
  onConfirm = () => {},
  onCancel = () => {},
}) => {
  const [close, setClose] = useState(false)

  // const ref = useRef(null)
  // useClickAway(ref, () => {
  //   setClose(true)
  // })

  const cancelClick = () => {
    onCancel()
    setClose(true)
  }

  return (
    <ModalComponent
      // ref={ref}
      modalSize={modalSize}
      close={close}
      button={trigger}
      header="Confirm"
      body={message}
      footer={
        <ButtonRow>
          <Spacer />
          <ButtonContainer>
            <Button
              className="mr-1"
              btnStyle="danger"
              text="Cancel"
              onClick={cancelClick}
            />
            <Button btnStyle="success" text="Confirm" onClick={onConfirm} />
          </ButtonContainer>
        </ButtonRow>
      }
    />
  )
}

export default ModalConfirm

const ButtonRow = styled(Row)`
  width: 100%;
`

const Spacer = styled.div`
  width: 60%;
`

const ButtonContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
`
