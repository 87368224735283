import { useMutation, useQuery } from '@apollo/client'
import { navigate, routes } from '@redwoodjs/router'
import ProjectForm from 'src/components/ProjectForm'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    project: project(id: $id) {
      id
      name
      address1
      address2
      city
      postCode
      reference
    }
  }
`
const UPDATE_POST_MUTATION = gql`
  mutation UpdateProjectMutation(
    $id: ID!
    $name: String
    $reference: String
    $address1: String
    $address2: String
    $city: String
    $postCode: String!
  ) {
    updateProject(
      id: $id
      name: $name
      reference: $reference
      address1: $address1
      address2: $address2
      city: $city
      postCode: $postCode
    ) {
      id
    }
  }
`

const ARCHIVE_MUTATION = gql`
  mutation UpdateProjectMutation($id: ID!) {
    UudateProject(id: $id, status: "archived") {
      id
    }
  }
`

const WrapperForEditProject = ({ id }) => {
  const { loading, error, data } = useQuery(QUERY, { variables: { id } })
  const [updateProject, mutationData] = useMutation(UPDATE_POST_MUTATION, {
    onCompleted: () => {},
  })
  const [archiveProject] = useMutation(ARCHIVE_MUTATION, {
    onCompleted: () => {},
  })

  const onSave = (input, id) => {
    updateProject({ variables: { id, ...input } })
  }

  const onArchive = (id) => {
    archiveProject({ variables: { id } })
  }

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (!data) {
    return <p>Empty ting</p>
  } else {
    console.log(data.project)
    return (
      <ProjectForm
        project={data.project}
        onSave={onSave}
        onArchive={onArchive}
        loading={mutationData.loading}
        error={mutationData.error}
        editing
      />
    )
  }
}

export default WrapperForEditProject
