import styled from 'styled-components'

import ChevronRight from '../ChevronRight/ChevronRight'

const Archive = ({ onClick, archived }) => {
  return (
    <ActionContainer onClick={onClick}>
      <Action>{archived ? 'Re-activate' : 'Archive'}</Action>
      <ChevronRight />
    </ActionContainer>
  )
}

export default Archive

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 10rem;
`

const Action = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-decoration-line: underline;

  color: #379ecc;
`
