import styled from 'styled-components'
import { TextField } from '@redwoodjs/web'

const Input = ({
  onChange,
  placeholder,
  value,
  name,
  type,
  align = 'left',
  white = false,
  icon,
  ...restOfProps
}) => {
  return (
    <PositionRelative>
      <FormInput
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        type={type}
        align={align}
        white={white}
        {...restOfProps}
      />
      <IconContainer>{icon}</IconContainer>
    </PositionRelative>
  )
}

export default Input

const PositionRelative = styled.div`
  position: relative;
`

const FormInput = styled(TextField)`
  text-align: left !important;
  margin-top: 1rem;
  /* Dark blue */
  background-color: ${(p) =>
    p.white ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 23, 51, 0.05)'};
  /* Dark blue */
  border: ${(p) => (p.white ? 'none' : '1px solid rgba(0, 23, 51, 0)')};
  box-sizing: border-box;
  border-radius: 100px;
  height: 0.5rem;
  padding: 1.5rem;
  text-align: end;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #001733;

  ::placeholder {
    /* Body */
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* Dark blue */
    color: #001733;
    opacity: 0.6;
    /* opacity: 0.6; */
  }
`

const IconContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1.5rem;
`
