const Folders = ({ color = '#379ECC' }) => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2 2.79999H15L12.9514 0.751369C12.7264 0.526369 12.4211 0.399994 12.1028 0.399994H6.6C5.60588 0.399994 4.8 1.20587 4.8 2.19999V5.19999H1.8C0.805875 5.19999 0 6.00587 0 6.99999V17.8C0 18.7941 0.805875 19.6 1.8 19.6H17.4C18.3941 19.6 19.2 18.7941 19.2 17.8V14.8H22.2C23.1941 14.8 24 13.9941 24 13V4.59999C24 3.60587 23.1941 2.79999 22.2 2.79999ZM18 17.8C18 18.1315 17.7315 18.4 17.4 18.4H1.8C1.4685 18.4 1.2 18.1315 1.2 17.8V6.99999C1.2 6.66849 1.4685 6.39999 1.8 6.39999H4.8V13C4.8 13.9941 5.60588 14.8 6.6 14.8H18V17.8ZM22.8 13C22.8 13.3315 22.5315 13.6 22.2 13.6H6.6C6.2685 13.6 6 13.3315 6 13V2.19999C6 1.86849 6.2685 1.59999 6.6 1.59999H12.1028L14.1514 3.64862C14.3764 3.87362 14.6816 3.99999 15 3.99999H22.2C22.5315 3.99999 22.8 4.26849 22.8 4.59999V13Z"
        fill={color}
      />
    </svg>
  )
}

export default Folders
