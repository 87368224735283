import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery, gql } from '@apollo/client'

import Projects from 'src/components/Projects'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

const QUERY = gql`
  query POSTS($status: String) {
    projects(status: $status) {
      id
      address1
      address2
      city
      postCode
      reference
      status
      customer {
        id
        firstName
        surname
      }
    }
  }
`

const WrapperForProjects = ({ status }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      status,
    },
  })

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (typeof Empty !== 'undefined' && isEmpty(data)) {
    return <p>Empty ting</p>
  } else {
    return (
      <ProjectsContainer>
        {console.log(data.projects)}
        <Projects projects={data.projects} />
      </ProjectsContainer>
    )
  }
}

export default WrapperForProjects

const ProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
