import { Link } from 'react-router-dom'
import Quotes from 'src/components/Quotes'
import LoadingIcon from 'src/components/LoadingIcon'

export const QUERY = gql`
  query POSTS($id: ID!) {
    quotes(filter: { project: { id: $id } }) {
      id
      to {
        id
        surname
        email
        company
      }
      from {
        id
        surname
        email
        company
      }
      attachments
      notes
    }
  }
`

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-and-network' }
}

export const Loading = () => <LoadingIcon />

export const Empty = () => {
  return (
    <div className="text-center">
      {'No quotes yet. '}
      <Link
        to={'/quotes/new'}
        className="text-blue-500 underline hover:text-blue-700"
      >
        {'Create one?'}
      </Link>
    </div>
  )
}

export const Success = ({ quotes }) => {
  return <Quotes quotes={quotes} />
}
