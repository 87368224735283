import React from 'react'
import { useQuery, gql } from '@apollo/client'
import Customer from 'src/components/Customer'
import LoadingIcon from 'src/components/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    customer(id: $id) {
      id
      email
      firstName
      surname
      phone
      company
      address1
      address2
      city
      postCode
    }
  }
`

const WrapperForCustomer = ({ id, setEditing }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: { id },
  })
  console.log(id)

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (!data.customer) {
    return <span>Customer not found</span>
  } else {
    console.log(data)
    return <Customer customer={data.customer} setEditing={setEditing} />
  }
}

export default WrapperForCustomer
