import styled from 'styled-components'

import Filter from '../Filter/Filter'

const FilterGroup = ({
  filters = [],
  activeFilter,
  onClick,
  className = '',
}) => {
  return (
    <FilterGroupContainer className={className}>
      {filters.map((f, key) => (
        <Filter
          key={key}
          text={f.display}
          value={f.value}
          active={activeFilter === f.value}
          onClick={onClick}
        />
      ))}
    </FilterGroupContainer>
  )
}

export default FilterGroup

const FilterGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
