import { useMutation } from '@redwoodjs/web'
import { navigate, routes } from '@redwoodjs/router'
import ProjectForm from 'src/components/ProjectForm'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    project: project(id: $id) {
      id
      name
      address1
      reference
    }
  }
`
const UPDATE_POST_MUTATION = gql`
  mutation UpdateProjectMutation(
    $id: ID!
    $name: String
    $reference: String
    $address1: String
    $address2: String
    $city: String
    $postCode: String!
  ) {
    UpdateProject(
      id: $id
      name: $name
      reference: $reference
      address1: $address1
      address2: $address2
      city: $city
      postCode: $postCode
    ) {
      id
    }
  }
`

const ARCHIVE_MUTATION = gql`
  mutation UpdateProjectMutation($id: ID!) {
    UpdateProject(id: $id, status: "archived") {
      id
    }
  }
`

export const Loading = () => <LoadingIcon />

export const Success = ({ project }) => {
  const [updateProject, { loading, error }] = useMutation(
    UPDATE_POST_MUTATION,
    {
      onCompleted: () => {},
    }
  )
  const [archiveProject] = useMutation(ARCHIVE_MUTATION, {
    onCompleted: () => {},
  })

  const onSave = (input, id) => {
    updateProject({ variables: { id, ...input } })
  }

  const onArchive = (id) => {
    archiveProject({ variables: { id } })
  }

  return (
    <ProjectForm
      project={project}
      onSave={onSave}
      onArchive={onArchive}
      loading={loading}
      error={error}
      editing
    />
  )
}
