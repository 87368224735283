import Icon from 'src/components/Icon'

import LoadingIcon from '../LoadingIcon/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: Int!) {
    icon: icon(id: $id) {
      id
      name
      category
      image
      make
      model
      notes
      partNumber
      priceToSupply
      priceToInstall
      toolboxes
    }
  }
`

export const Loading = () => <LoadingIcon />

export const Empty = () => <div>Icon not found</div>

export const Success = ({ icon }) => {
  return <Icon icon={icon} />
}
