import React from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import CustomerForm from 'src/components/CustomerForm'
import LoadingIcon from 'src/components/LoadingIcon'

export const QUERY = gql`
  query FIND_POST_BY_ID($id: ID!) {
    customer(id: $id) {
      id
      email
      firstName
      surname
      phone
      company
      address1
      address2
      city
      postCode
    }
  }
`

const UPDATE_POST_MUTATION = gql`
  mutation UpdateCustomerMutation(
    $email: String
    $id: ID!
    $firstName: String
    $surname: String
    $phone: String
    $company: String
    $address1: String
    $address2: String
    $city: String
    $postCode: String
  ) {
    updateCustomer(
      email: $email
      id: $id
      firstName: $firstName
      surname: $surname
      phone: $phone
      company: $company
      address1: $address1
      address2: $address2
      city: $city
      postCode: $postCode
    ) {
      id
    }
  }
`

const WrapperForEditCustomer = ({ id, setEditing }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: { id },
  })
  const [updateCustomer, { loading_mut, error_mut }] = useMutation(
    UPDATE_POST_MUTATION,
    {
      onCompleted: () => {
        setEditing(false)
      },
    }
  )

  const onSave = (input, id) => {
    updateCustomer({
      variables: {
        id,
        ...input,
      },
    })
  }

  console.log('Loaded')

  if (loading) return <LoadingIcon />
  if (error) return <p>Error :( {error ? JSON.stringify(error) : null}</p>
  if (!data.customer) {
    return <span>Customer not found</span>
  } else {
    console.log(data)
    return (
      <CustomerForm
        customer={data.customer}
        onSave={onSave}
        error={error_mut}
        loading={loading_mut}
      />
    )
  }
}

export default WrapperForEditCustomer
