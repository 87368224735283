const People = ({ color = '#379ECC' }) => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4 7.8C22.0575 7.8 23.4 6.4575 23.4 4.8C23.4 3.1425 22.0575 1.8 20.4 1.8C18.7425 1.8 17.4 3.1425 17.4 4.8C17.4 6.4575 18.7425 7.8 20.4 7.8ZM20.4 3C21.3938 3 22.2 3.80625 22.2 4.8C22.2 5.79375 21.3938 6.6 20.4 6.6C19.4063 6.6 18.6 5.79375 18.6 4.8C18.6 3.80625 19.4063 3 20.4 3ZM12 9C14.3213 9 16.2 7.12125 16.2 4.8C16.2 2.47875 14.3213 0.599998 12 0.599998C9.67875 0.599998 7.8 2.47875 7.8 4.8C7.8 7.12125 9.67875 9 12 9ZM12 1.8C13.6538 1.8 15 3.14625 15 4.8C15 6.45375 13.6538 7.8 12 7.8C10.3463 7.8 9 6.45375 9 4.8C9 3.14625 10.3463 1.8 12 1.8ZM21.15 9H19.65C19.08 9 18.5513 9.18 18.1088 9.48375C18.4613 9.72375 18.78 10.005 19.0613 10.3237C19.245 10.245 19.4438 10.2 19.65 10.2H21.15C22.0575 10.2 22.8 11.0062 22.8 12C22.8 12.33 23.07 12.6 23.4 12.6C23.73 12.6 24 12.33 24 12C24 10.3462 22.7213 9 21.15 9ZM3.6 7.8C5.2575 7.8 6.6 6.4575 6.6 4.8C6.6 3.1425 5.2575 1.8 3.6 1.8C1.9425 1.8 0.6 3.1425 0.6 4.8C0.6 6.4575 1.9425 7.8 3.6 7.8ZM3.6 3C4.59375 3 5.4 3.80625 5.4 4.8C5.4 5.79375 4.59375 6.6 3.6 6.6C2.60625 6.6 1.8 5.79375 1.8 4.8C1.8 3.80625 2.60625 3 3.6 3ZM15.0038 9.75C13.7513 9.75 13.44 10.2 12 10.2C10.56 10.2 10.2487 9.75 8.99625 9.75C7.635 9.75 6.31125 10.3575 5.535 11.5087C5.07 12.1987 4.8 13.0275 4.8 13.92V15.6C4.8 16.5937 5.60625 17.4 6.6 17.4H17.4C18.3938 17.4 19.2 16.5937 19.2 15.6V13.92C19.2 13.0275 18.93 12.1987 18.465 11.5087C17.6888 10.3575 16.365 9.75 15.0038 9.75ZM18 15.6C18 15.93 17.73 16.2 17.4 16.2H6.6C6.27 16.2 6 15.93 6 15.6V13.92C6 13.2975 6.18375 12.6937 6.52875 12.18C7.04625 11.4112 7.96875 10.95 8.9925 10.95C10.02 10.95 10.3875 11.4 12 11.4C13.6125 11.4 13.98 10.95 15.0038 10.95C16.0275 10.95 16.95 11.4112 17.4675 12.18C17.8125 12.6937 17.9963 13.2975 17.9963 13.92V15.6H18ZM5.89125 9.48375C5.445 9.18 4.91625 9 4.35 9H2.85C1.27875 9 0 10.3462 0 12C0 12.33 0.27 12.6 0.6 12.6C0.93 12.6 1.2 12.33 1.2 12C1.2 11.0062 1.9425 10.2 2.85 10.2H4.35C4.55625 10.2 4.755 10.245 4.93875 10.3237C5.22 10.005 5.5425 9.72375 5.89125 9.48375Z"
        fill={color}
      />
    </svg>
  )
}

export default People
