import { useMutation } from '@apollo/client'
import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import Button from '../Button/Button'
import InputNonRW from '../Input/InputNonRW'
import Column from '../Column/Column'
import Row from '../Row/Row'
import Divider from '../Divider/Divider'
import FilterGroup from '../FilterGroup/FilterGroup'
import DragNDrop from '../DragNDrop/DragNDrop'
import { useHistory } from 'react-router-dom'
import Back from '../Back/Back'

import InnerContainer from 'src/components/Layout/InnerContainer'
import TopBar from 'src/components/Layout/TopBar'
import RightBar from 'src/components/Layout/RightBar'

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const DELETE_POST_MUTATION = gql`
  mutation DeleteQuoteMutation($id: ID!) {
    deleteQuote(id: $id) {
      id
    }
  }
`

const ADD_MISC_CHARGE_MUTATION = gql`
  mutation AddMiscChargeMutation($id: ID!, $miscCharge: MiscChargeInput) {
    updateVariation(id: $id, miscCharge: $miscCharge) {
      id
    }
  }
`

const DELETE_MISC_CHARGE_MUTATION = gql`
  mutation DeleteMiscChargeMutation($id: ID!, $variationId: String) {
    deleteMiscCharge(id: $id, variationId: $variationId)
  }
`

const Tabs = ({ selections, activeSelection, setActiveSelection }) => {
  return (
    <TabsContainer>
      {selections.map((selection) =>
        selection.value == activeSelection ? (
          <Tab text={selection.label} value={selection.value} active />
        ) : (
          <Tab
            text={selection.label}
            value={selection.value}
            setActiveSelection={setActiveSelection}
          />
        )
      )}
    </TabsContainer>
  )
}

const Tab = ({ setActiveSelection, active, text, value }) => {
  return (
    <>
      <TabBox>
        <Filters
          onClick={() => (!active ? setActiveSelection(value) : null)}
          selected={active}
        >
          {text}
        </Filters>
        {active && <Underline />}
      </TabBox>
    </>
  )
}

const Quote = ({ quote, user }) => {
  const history = useHistory()
  const [activeFilter, setActiveFilter] = useState('all items')
  const [fittings, setFittings] = useState([])
  const [miscCharges, setMiscCharges] = useState([])
  // Note, buffered method below relies on async graphql call taking longer than state update
  const [bufferedCharge, setBufferedCharge] = useState(null)
  const [bufferedId, setBufferedId] = useState(null)
  const [fittingsTotal, setFittingsTotal] = useState(0)
  const [miscTotal, setMiscTotal] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [documentTabs, setDocumentTabs] = useState([
    { label: 'Quote', value: 'quote' },
    { label: 'Markup', value: 'markup' },
  ])
  const [currentTab, setCurrentTab] = useState('quote')
  const [total, setTotal] = useState(0)
  const [deleteQuote] = useMutation(DELETE_POST_MUTATION, {
    onCompleted: () => {
      history.push('/quotes')
      location.reload()
    },
  })
  const [addMiscCharge] = useMutation(ADD_MISC_CHARGE_MUTATION, {
    onCompleted: () => {
      console.log('success')
      setMiscCharges([...miscCharges, bufferedCharge])
      setBufferedCharge(null)
    },
  })

  const [deleteMiscCharge] = useMutation(DELETE_MISC_CHARGE_MUTATION, {
    onCompleted: () => {
      console.log('success')
      let misc_array = miscCharges
      misc_array = misc_array.filter((obj) => {
        return obj.id !== bufferedId
      })
      setMiscCharges(misc_array)
      setBufferedId(null)
    },
  })

  const deleteCharge = (id) => {
    setBufferedId(id)
    deleteMiscCharge({
      variables: {
        id,
        variationId: quote.variation.id,
      },
    })
  }

  const saveMiscCharge = (charge) => {
    setBufferedCharge(charge)
    addMiscCharge({ variables: { id: quote.variation.id, miscCharge: charge } })
  }

  const getTotal = (accumulator, currentValue) => {
    console.log(currentValue)
    return accumulator + currentValue.totalPrice
  }

  const reduceIcons = (icons) => {
    let finalArray = []
    let checkIndex = -1
    let currentElement = {}
    for (let i = 0; i < icons.length; i++) {
      let icon = JSON.parse(icons[i])
      checkIndex = finalArray.findIndex(
        (fitting) => fitting.id == icon.fittingId
      )
      if (checkIndex >= 0) {
        currentElement = finalArray[checkIndex]
        currentElement.units += 1
        currentElement.totalPrice =
          (currentElement.priceToInstall + currentElement.priceToSupply) *
          currentElement.units
        finalArray[checkIndex] = currentElement
      } else {
        finalArray.push({
          id: icon.fittingId,
          name: icon.fittingName,
          units: 1,
          priceToSupply: parseFloat(icon.fittingSupplyPrice),
          priceToInstall: parseFloat(icon.fittingInstallPrice),
          totalPrice:
            parseFloat(icon.fittingInstallPrice) +
            parseFloat(icon.fittingSupplyPrice),
        })
      }
    }
    return finalArray
  }

  const updateQuoteFields = () => {
    let total_misc = miscCharges.reduce((acc, cur) => acc + cur.price, 0)
    let total_sub = fittingsTotal + total_misc
    setMiscTotal(total_misc)
    setSubTotal(total_sub)
    setTotal(total_sub)
  }

  const setTab = (value) => {
    setCurrentTab(value)
  }

  useEffect(() => {
    let sortedArray = reduceIcons(quote.variation.icons)
    setFittings(sortedArray)
    let total_fittings = sortedArray.reduce(getTotal, 0)
    let total_misc
    if (quote.variation.miscCharges) {
      total_misc = quote.variation.miscCharges.reduce(
        (acc, cur) => acc + cur.price,
        0
      )
      setMiscCharges(quote.variation.miscCharges)
    } else {
      total_misc = 0
    }
    let total_sub = total_fittings + total_misc
    setFittingsTotal(total_fittings)
    setMiscTotal(total_misc)
    setSubTotal(total_sub)
    setTotal(total_sub)
    // Add tabs if user documents exist
    user.insuranceFile &&
      setDocumentTabs((tabs) => [
        ...tabs,
        { label: 'Insurance', value: 'insurance' },
      ])
    user.termsFile &&
      setDocumentTabs((tabs) => [...tabs, { label: 'Terms', value: 'terms' }])
  }, [])

  useEffect(() => {
    updateQuoteFields()
  }, [miscCharges, fittings])

  return (
    <>
      <TopBar>
        <Back className="mt-1" routeTo={`/project/${quote.projectId}`} />
        <CurrentToolTitle>Quote</CurrentToolTitle>
      </TopBar>
      <InnerContainer>
        <Main>
          <TabsSection>
            <Tabs
              selections={documentTabs}
              activeSelection={currentTab}
              setActiveSelection={setTab}
            />
          </TabsSection>
          <BodySection>
            {currentTab == 'quote' && (
              <CenterQuote>
                <QuoteInfoDataContainer className="p-3">
                  <TopGrid>
                    <QuoteDetailText>#902385</QuoteDetailText>
                    {user.companyLogo ? (
                      <CompanyLogo src={user.companyLogo} />
                    ) : (
                      <div></div>
                    )}
                    <QuoteDetailText>
                      <QuoteDetailText bold>Issued On </QuoteDetailText>
                      24/01/2020
                    </QuoteDetailText>
                  </TopGrid>
                  <Row className="justify-center mt-2">
                    <QuoteDetailHeader>Customer Quote 0012</QuoteDetailHeader>
                  </Row>
                  <Divider className="mt-2" />
                  <Row className="justify-center flex-center">
                    <Column className="m-2">
                      <QuoteDetailText bold>To</QuoteDetailText>
                      <QuoteDetailText>
                        {quote.customer.firstName} {quote.customer.surname}
                      </QuoteDetailText>
                      <QuoteDetailText>
                        {quote.customer.company},
                      </QuoteDetailText>
                      <QuoteDetailText>
                        {quote.customer.address1}
                      </QuoteDetailText>
                      <QuoteDetailText>{quote.customer.city}</QuoteDetailText>
                      <QuoteDetailText>
                        {quote.customer.postCode}
                      </QuoteDetailText>
                      <QuoteDetailText email className="mt-2">
                        {quote.customer.email}
                      </QuoteDetailText>
                    </Column>
                    <Column className="m-2">
                      <QuoteDetailText bold>From</QuoteDetailText>
                      <QuoteDetailText>
                        {user.firstName} {user.surname}
                      </QuoteDetailText>
                      <QuoteDetailText>{user.company},</QuoteDetailText>
                      <QuoteDetailText>{user.address1}</QuoteDetailText>
                      <QuoteDetailText>{user.city}</QuoteDetailText>
                      <QuoteDetailText>{user.postCode}</QuoteDetailText>
                      <QuoteDetailText email className="mt-2">
                        {user.email}
                      </QuoteDetailText>
                    </Column>
                  </Row>
                  <Divider className="mt-2 mb-2" />
                  <Column>
                    <FilterGroup
                      filters={[
                        'All Items',
                        'Group items by category',
                        'Group items by room',
                      ]}
                      activeFilter={activeFilter}
                      onClick={setActiveFilter}
                      className="flex-center space-evenly"
                    />
                    <ItemizedTable>
                      <colgroup>
                        <col span="1" style={{ width: '30%' }} />
                        <col span="1" style={{ width: '10%' }} />
                        <col span="1" style={{ width: '18%' }} />
                        <col span="1" style={{ width: '18%' }} />
                        <col span="1" style={{ width: '18%' }} />
                        <col span="1" style={{ width: '6%' }} />
                      </colgroup>
                      <thead>
                        <Tr>
                          <Th style={{ textAlign: 'left' }}>Item Name</Th>
                          <Th>Units</Th>
                          <Th>Supply Price (£)</Th>
                          <Th>Install Price (£)</Th>
                          <Th>Total Price (£)</Th>
                        </Tr>
                      </thead>
                      <tbody>
                        <Tr>
                          <Th style={{ textAlign: 'left' }}>Fittings</Th>
                        </Tr>
                        {fittings.map((fitting) => (
                          <Tr>
                            <LockedTd style={{ textAlign: 'left' }}>
                              {fitting.name}
                            </LockedTd>
                            <LockedTd>{fitting.units}</LockedTd>
                            <LockedTd>{fitting.priceToInstall}</LockedTd>
                            <LockedTd>{fitting.priceToSupply}</LockedTd>
                            <LockedTd>{fitting.totalPrice.toFixed(2)}</LockedTd>
                          </Tr>
                        ))}
                        <Tr>
                          <Th colSpan="3"></Th>
                          <Th>Fittings Total</Th>
                          <Th>
                            {'£' + numberWithCommas(fittingsTotal.toFixed(2))}
                          </Th>
                        </Tr>
                        <Tr>
                          <Th style={{ textAlign: 'left' }}>Other Charges</Th>
                        </Tr>
                        {miscCharges &&
                          miscCharges.map((charge) => (
                            <Tr>
                              {/* JSON.parse as icons retrieved as stringified objects */}
                              <EditableTd style={{ textAlign: 'left' }}>
                                {charge.name}
                              </EditableTd>
                              <EditableTd>1</EditableTd>
                              <EditableTd>n/a</EditableTd>
                              <EditableTd>n/a</EditableTd>
                              <EditableTd>{charge.price.toFixed(2)}</EditableTd>
                              <DeleteTd>
                                <DeleteButton
                                  onClick={() => deleteCharge(charge.id)}
                                >
                                  -
                                </DeleteButton>
                              </DeleteTd>
                            </Tr>
                          ))}

                        <NewMiscChargeRow
                          saveMiscCharge={saveMiscCharge}
                          miscTotal={miscTotal}
                        />
                      </tbody>
                    </ItemizedTable>
                  </Column>
                  <Divider className="mt-2" />
                  <Column />
                  <Column>
                    <ItemizedTable>
                      <colgroup>
                        <col span="1" style={{ width: '30%' }} />
                        <col span="1" style={{ width: '10%' }} />
                        <col span="1" style={{ width: '18%' }} />
                        <col span="1" style={{ width: '18%' }} />
                        <col span="1" style={{ width: '18%' }} />
                        <col span="1" style={{ width: '6%' }} />
                      </colgroup>
                      <thead>
                        <Tr>
                          <Th colSpan="3"></Th>
                          <Th>Subtotal</Th>
                          <Th>{'£' + numberWithCommas(subTotal.toFixed(2))}</Th>
                        </Tr>
                      </thead>
                      <tbody></tbody>
                    </ItemizedTable>
                  </Column>
                  <Divider className="mt-2" />
                  <Column />
                  <Column>
                    <ItemizedTable>
                      <colgroup>
                        <col span="1" style={{ width: '30%' }} />
                        <col span="1" style={{ width: '10%' }} />
                        <col span="1" style={{ width: '18%' }} />
                        <col span="1" style={{ width: '18%' }} />
                        <col span="1" style={{ width: '18%' }} />
                        <col span="1" style={{ width: '6%' }} />
                      </colgroup>
                      <thead>
                        <Tr>
                          <Th colSpan="3"></Th>
                          <Th style={{ fontSize: '1.5em' }}>Total</Th>
                          <Th style={{ fontSize: '1.5em' }}>
                            {'£' + numberWithCommas(total.toFixed(2))}
                          </Th>
                        </Tr>
                      </thead>
                      <tbody></tbody>
                    </ItemizedTable>
                  </Column>
                  <Divider className="mt-2" />
                  <Column />
                  <Column></Column>
                </QuoteInfoDataContainer>
              </CenterQuote>
            )}
            {currentTab == 'markup' && (
              <embed
                src={quote.variation.displayFile}
                width="100%"
                height="100%"
              />
            )}
            {currentTab == 'insurance' && (
              <embed src={user.insuranceFile} width="100%" height="100%" />
            )}
            {currentTab == 'terms' && (
              <embed src={user.termsFile} width="100%" height="100%" />
            )}
          </BodySection>
        </Main>

        <RightBar>
          <span>TODO: Functionality to go here</span>
        </RightBar>
      </InnerContainer>
    </>
  )
}

export default Quote

const NewMiscChargeRow = ({ saveMiscCharge, miscTotal }) => {
  const [name, setName] = useState('')
  const [price, setPrice] = useState(0)

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handlePriceChange = (e) => {
    setPrice(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (typeof price != 'number') {
      try {
        let floatPrice = parseFloat(price)
        if (isNaN(floatPrice)) {
          throw new Error()
        } else {
          setPrice(floatPrice)
        }
      } catch {
        console.log('Error')
      }
    }
    if (price && name) {
      saveMiscCharge({ id: uuidv4(), name, price: parseFloat(price) })
    }
    setName('')
    setPrice(0)
  }

  return (
    <>
      <Tr>
        <td>
          <InputNonRW
            placeholder="Enter item name..."
            style={{ textAlign: 'left' }}
            onChange={handleNameChange}
            value={name ? name : null}
          />
        </td>
        <LockedTd>1</LockedTd>
        <LockedTd>n/a</LockedTd>
        <LockedTd>n/a</LockedTd>
        <td>
          <InputNonRW
            placeholder="0.00"
            onChange={handlePriceChange}
            value={price ? price : null}
          />
        </td>
      </Tr>
      <Tr>
        <Th colSpan="3"></Th>
        <Th>Other Charges Total</Th>
        <Th>{'£' + numberWithCommas(miscTotal.toFixed(2))}</Th>
      </Tr>
      <Tr>
        <td colSpan="4"></td>
        <Button btnStyle="secondary" text="Add Charge" onClick={handleSubmit} />
      </Tr>
    </>
  )
}

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 200px;
  width: 100%;
  > *:first-child {
    align-self: start;
    justify-self: start;
  }
  > *:nth-child(2) {
    align-self: center;
    justify-self: center;
  }
  > *:nth-child(3) {
    align-self: start;
    justify-self: end;
  }
`

const CurrentToolTitle = styled.span`
  margin-right: 2rem;
  color: white;
  font-family: Josefin Sans;
  font-style: normal;
  font-size: 26px;
`

const ItemizedTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 15px;
  font-size: 1.2em;
`

const Tr = styled.tr``

const Th = styled.th`
  text-align: right;
  color: black;
`

const EditableTd = styled.td`
  text-align: right;
`

const LockedTd = styled.td`
  text-align: right;
`

const DeleteTd = styled.td``

const DeleteButton = styled.button`
  border: none;
  color: white;
`

const InputTd = styled.td``

const StyledInput = styled.input`
  border-radius: 20px;
  border: none;
  background-color: #f2f3f5;
  color: #646e81;
  width: 100%;
  padding: 15px;
  text-align: right;
`

/*
End of my additions
*/

const CenterQuote = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 2%;
  padding-top: 2%;
`

const QuoteInfoDataContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  /* border-radius: 20px; */
  height: 90%;
  padding: 3rem;
  padding-top: 4rem;
`

const QuoteDetailText = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  ${(p) =>
    p.bold &&
    css`
      font-weight: bold;
    `}
  ${(p) =>
    p.email &&
    css`
      text-decoration-line: underline;
      color: #379ecc;
    `}
`

const QuoteDetailHeader = styled.span`
  /* Heading 3 */
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 150% */
  text-align: center;

  color: #001733;
`

const TabsSection = styled.div`
  width: 100%;
  height: 7%;
  background-color: var(--color-zapquote-blue-0);
  display: flex;
  justify-content: center;
  align-items: center;
`

const BodySection = styled.div`
  width: 100%;
  height: 93%;
  overflow-y: scroll;
`

const Main = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`

const TabBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Filters = styled.span`
  cursor: pointer;
  /* Body */
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: var(--color-text-light);

  opacity: 0.6;

  ${(props) =>
    props.selected &&
    css`
      /* Primary */
      opacity: 1;
      color: var(--color-text-light);
    `}
`

const Underline = styled.div`
  border: 1px solid var(--color-text-active);
`

const CompanyLogo = styled.img`
  height: 150px;
`
