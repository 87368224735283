import Modal, { ModalProvider, BaseModalBackground } from 'styled-react-modal'
import styled, { css } from 'styled-components'
import { useState, useEffect } from 'react'

import Divider from '../Divider/Divider'
import CloseButton from '../CloseButton/CloseButton'

const StyledModal = Modal.styled`
  width: ${(p) => {
    if (p.modalSize === 'sm') {
      return css`
        30rem;
      `
    } else if (p.modalSize === 'md') {
      return css`
        50rem;
      `
    } else if (p.modalSize === 'lg') {
      return css`
        80rem;
      `
    }
  }};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${(props) => props.opacity};
  transition: opacity ease 500ms;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 2.5rem;
`

const ModalTrigger = ({
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  modalSize = 'md',
  className,
  close,
  flex,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    if (close && isOpen) {
      setIsOpen(false)
    }
  })

  const toggleModal = () => {
    console.log('AAAAAA')
    setIsOpen(!isOpen)
  }

  const afterOpen = () => {
    console.log('BBBBBBB')
    setTimeout(() => {
      setOpacity(1)
    }, 10)
  }

  const beforeClose = () => {
    console.log('CCCCCC')
    return new Promise((resolve) => {
      setOpacity(0)
      setTimeout(resolve, 200)
    })
  }

  return (
    <Full flex={flex} className={className}>
      <div onClick={toggleModal}>{Button}</div>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        modalSize={modalSize}
      >
        <HeaderContainer>
          <ModalHeaderComponent>{ModalHeader}</ModalHeaderComponent>
          <CloseButton onClick={toggleModal} />
        </HeaderContainer>
        <Divider className="mt-1 mb-1" />
        {ModalBody}
        {ModalFooter && <Divider className="mt-1 mb-1" />}
        {ModalFooter}
      </StyledModal>
    </Full>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: opacity ease 200ms;
`

const ModalComponent = ({
  button,
  trigger,
  header,
  body,
  footer,
  modalSize,
  className,
  close,
  flex = false,
}) => {
  return (
    <ModalProvider backgroundComponent={FadingBackground}>
      <ModalTrigger
        flex={flex}
        trigger={trigger}
        close={close}
        className={className}
        Button={button}
        ModalHeader={header}
        ModalBody={body}
        ModalFooter={footer}
        modalSize={modalSize}
      />
    </ModalProvider>
  )
}

export default ModalComponent

const Full = styled.div`
  ${(p) =>
    p.flex
      ? css`
          flex: 1;
        `
      : css`
          width: 100%;
        `}
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const ModalHeaderComponent = styled.span`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
`
