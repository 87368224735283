const CloseButton = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
    >
      <path
        d="M15 0.46875C6.97266 0.46875 0.46875 6.97266 0.46875 15C0.46875 23.0273 6.97266 29.5312 15 29.5312C23.0273 29.5312 29.5312 23.0273 29.5312 15C29.5312 6.97266 23.0273 0.46875 15 0.46875ZM15 27.6562C8.04492 27.6562 2.34375 22.0254 2.34375 15C2.34375 8.04492 7.97461 2.34375 15 2.34375C21.9551 2.34375 27.6562 7.97461 27.6562 15C27.6562 21.9551 22.0254 27.6562 15 27.6562ZM20.5547 10.9395L16.4941 15L20.5547 19.0605C20.8301 19.3359 20.8301 19.7812 20.5547 20.0566L20.0566 20.5547C19.7812 20.8301 19.3359 20.8301 19.0605 20.5547L15 16.4941L10.9395 20.5547C10.6641 20.8301 10.2188 20.8301 9.94336 20.5547L9.44531 20.0566C9.16992 19.7812 9.16992 19.3359 9.44531 19.0605L13.5059 15L9.44531 10.9395C9.16992 10.6641 9.16992 10.2188 9.44531 9.94336L9.94336 9.44531C10.2188 9.16992 10.6641 9.16992 10.9395 9.44531L15 13.5059L19.0605 9.44531C19.3359 9.16992 19.7812 9.16992 20.0566 9.44531L20.5547 9.94336C20.8242 10.2188 20.8242 10.6641 20.5547 10.9395Z"
        fill="#379ECC"
      />
    </svg>
  )
}

export default CloseButton
