import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const ButtonContainer = styled(motion.button)`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #379ecc;
  box-shadow: ${(p) => !p.noFloat && css`0px 10px 20px rgba(0, 0, 0, 0.15)`};
  border-radius: 100px;
  height: 45px;
  width: 100%;
  ${(props) => (props.marginBottom ? 'margin-bottom: 1rem;' : null)}

  :focus {
    outline: 0;
  }

  ${({ btnStyle }) => {
    if (btnStyle === 'primary') {
      return css`
        border: none;
      `
    } else if (btnStyle === 'secondary') {
      return css`
        border: 1px solid #379ecc;
        box-sizing: border-box;
        border-radius: 100px;
        background: none;
      `
    } else if (btnStyle === 'danger') {
      return css`
        background: none;
        /* Red */
        border: 1px solid #cc3737;
        box-sizing: border-box;
        border-radius: 100px;
      `
    } else if (btnStyle === 'success') {
      return css`
        /* Green */
        background: #37cc73;
        box-shadow: ${(p) =>
          !p.noFloat && css`0px 10px 20px rgba(0, 0, 0, 0.15)`};
        border-radius: 100px;
      `
    } else if (btnStyle === 'success-secondary') {
      return css`
        /* Green */
        background: none;
        border: 1px solid #37cc73;
        box-shadow: ${(p) =>
          !p.noFloat && css`0px 10px 20px rgba(0, 0, 0, 0.15)`};
        border-radius: 100px;
      `
    } else if (btnStyle === 'clear') {
      return css`
        background: none;
        /* White */
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 100px;
      `
    }
  }}
  ${({ disabled }) =>
    disabled &&
    css`
      background: rgba(211, 211, 211, 0.5);
    `}
`

const ButtonText = styled.span`
  /* Bold */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;

  ${({ btnStyle }) => {
    if (
      btnStyle === 'primary' ||
      btnStyle === 'success' ||
      btnStyle === 'clear'
    ) {
      return css`
        color: #ffffff;
      `
    } else if (btnStyle === 'danger') {
      return css`
        color: #cc3737;
      `
    } else if (btnStyle === 'success-secondary') {
      return css`
        color: #37cc73;
      `
    } else if (btnStyle === 'secondary') {
      return css`
        color: #379ecc;
      `
    }
  }}
`

const Button = ({
  onClick,
  text,
  className,
  btnStyle = 'primary',
  type = '',
  noFloat = false,
  disabled = false,
  marginBottom,
}) => {
  return (
    <ButtonContainer
      disabled={disabled}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className={className}
      btnStyle={btnStyle}
      noFloat={noFloat}
      type={type}
      onClick={onClick}
      marginBottom={marginBottom}
    >
      <ButtonText type={type} btnStyle={btnStyle}>
        {text}
      </ButtonText>
    </ButtonContainer>
  )
}

export default Button
