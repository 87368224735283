const ChevronRight = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50243 9.42497L2.04775 16.825C1.80908 17.06 1.42314 17.06 1.18447 16.825L0.179004 15.835C-0.059668 15.6 -0.059668 15.22 0.179004 14.985L6.19658 8.99997L0.179003 3.01498C-0.0596686 2.77998 -0.0596686 2.39998 0.179003 2.16498L1.18447 1.17498C1.42314 0.939978 1.80908 0.939978 2.04775 1.17498L9.50243 8.57497C9.74111 8.80997 9.74111 9.18997 9.50243 9.42497Z"
        fill="#379ECC"
      />
    </svg>
  )
}

export default ChevronRight
