import { useState, useRef, useEffect } from 'react'
import { motion, useCycle } from 'framer-motion'
import { useMutation, useQuery } from '@apollo/client'
import styled, { css } from 'styled-components'
import { useDrop } from 'react-dnd'
import { useBoolean } from 'react-use'
import { v4 as uuidv4 } from 'uuid'
import { Link } from 'react-router-dom'

import { useDimensions } from 'src/utils/utils'

import WrapperForEditToolbox from '../Wrappers/WrapperForEditToolbox'
import LoadingIcon from '../LoadingIcon/LoadingIcon'
import Row from '../Row/Row'
import Icons from '../Icons/Icons'
import ModalComponent from '../Modal/Modal'
import DragHandle from '../DragHandle/DragHandle'
import MenuToggle from '../MenuToggle/MenuToggle'
import Tabs from '../Tabs/Tabs'

const QUERY = gql`
  query POSTS($toolboxId: ID!) {
    fittingsByToolbox: toolbox(id: $toolboxId) {
      fittings
    }
  }
`

const ADD_FITTING_TO_TOOLBOX = gql`
  mutation AddToToolbox($fitting: FittingInput, $toolboxId: String) {
    addFittingToToolbox(fitting: $fitting, toolboxId: $toolboxId) {
      fittings
    }
  }
`

const REMOVE_ICON_MUTATION = gql`
  mutation UpdateIconMutation($id: ID!, $fittingId: String) {
    deleteFittingFromToolbox(id: $id, fittingId: $fittingId) {
      fittings
    }
  }
`

// updateToolboxFittings will update the toolbox that is then passed down

const ProjectToolbox = ({
  toolbox,
  draggable,
  setDrag,
  noEdit,
  updateToolboxFittings,
  inProject,
  toolboxUpdateSave,
}) => {
  let { data: { fittingsByToolbox } = {}, loading, error } = useQuery(QUERY, {
    variables: { toolboxId: toolbox.id },
  })

  const [removeMutation] = useMutation(REMOVE_ICON_MUTATION, {
    onCompleted: ({ deleteFittingFromToolbox: { fittings } }) => {
      setRemoveLoading(false)
      setDisplayIcons(fittings.map((fitting) => JSON.parse(fitting)))
      updateToolboxFittings && updateToolboxFittings(fittings)
    },
  })

  const [addFittingToToolbox] = useMutation(ADD_FITTING_TO_TOOLBOX, {
    onCompleted: ({ addFittingToToolbox: { fittings } }) => {
      setDisplayIcons(fittings.map((fitting) => JSON.parse(fitting)))
      updateToolboxFittings && updateToolboxFittings(fittings)
      // todo: on addition, edit fitting modal should pop-up.
    },
  })

  // Array of the actual icons in the ProjectToolbox
  const [displayIcons, setDisplayIcons] = useState([])
  // Load state of the remove action
  const [removeLoading, setRemoveLoading] = useBoolean(false)
  // Load state of initial load
  const [initialLoad, setInitialLoad] = useBoolean(false)

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'icon',
    drop: (i) => {
      let num = 2
      let name = i.name
      let found = displayIcons.find((i) => i.name === name)
      while (found) {
        name = `${i.name} ${num++}`
        found = displayIcons.find((i) => i.name === name)
      }
      i.name = name
      const newIcon = Object.assign(i, {})
      // Format to FittingInput
      const fittingInput = {
        id: uuidv4(),
        name: newIcon.name ? newIcon.name : 'n/a',
        category: newIcon.category ? newIcon.category : 'n/a',
        image: newIcon.image ? newIcon.image : 'n/a',
        make: newIcon.make ? newIcon.make : 'n/a',
        model: newIcon.model ? newIcon.model : 'n/a',
        notes: newIcon.notes ? newIcon.notes : 'n/a',
        partNumber: newIcon.partNumber ? newIcon.partNumber : 'n/a',
        priceToSupply: newIcon.priceToSupply ? newIcon.priceToSupply : 0.0,
        priceToInstall: newIcon.priceToInstall ? newIcon.priceToInstall : 0.0,
        deleted: newIcon.deleted ? newIcon.deleted : false,
      }
      addFittingToToolbox({
        variables: { fitting: fittingInput, toolboxId: toolbox.id },
      })
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  useEffect(() => {
    console.log(toolbox)
    if (initialLoad) {
      setDisplayIcons(toolbox.fittings.map((fitting) => JSON.parse(fitting)))
    }
  }, [toolbox])

  /**
   *  Gets fittings for the toolbox used for this project.
   */

  if (fittingsByToolbox && !initialLoad) {
    if (fittingsByToolbox.fittings) {
      setDisplayIcons(
        fittingsByToolbox.fittings.map((fitting) => JSON.parse(fitting))
      )
    }
    setInitialLoad(true)
  }
  // Motion
  const variants = {
    open: {
      height: '100%',
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      height: 50,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  const sidebar = {
    open: (height = 1000) => ({
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      transition: {
        delay: 0.5,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  }

  /**
   *
   *  Remove Mutation removes the icon by setting it to deleted? Should be taking the fitting out of the toolbox,
   *  and deleting only if it is a certain type and not connected to any toolboxes anymore.
   *
   */

  const remove = (id) => {
    setRemoveLoading(true)
    removeMutation({ variables: { id: toolbox.id, fittingId: id } })
  }

  const [isOpen, toggleOpen] = useCycle(true, false)
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)

  return (
    <ToolboxContainer
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={draggable ? containerRef : drop}
      variants={variants}
    >
      <motion.div className="background" variants={sidebar}>
        {draggable && (
          <ToolboxHeaderContainer noBottom>
            <HeaderRow>
              <DragHandle />
              <MenuToggle toggle={() => toggleOpen()} />
            </HeaderRow>
          </ToolboxHeaderContainer>
        )}
        <ToolboxHeaderContainer>
          <ToolboxHead>
            <ToolboxHeader>{toolbox?.name}</ToolboxHeader>
            <span>{displayIcons.length + ' icons'}</span>
            {noEdit ? (
              <Link to={`/toolbox/${toolbox.id}`}>
                Edit Toolbox For This Project
              </Link>
            ) : (
              <ModalComponent
                flex
                modalSize="md"
                button={<Edit>Edit name</Edit>}
                header="Edit Toolbox"
                body={<WrapperForEditToolbox id={toolbox?.id} />}
              />
            )}
          </ToolboxHead>
        </ToolboxHeaderContainer>
        <hr />
        <ToolboxTools>
          {loading ? (
            <LoadingIcon />
          ) : (
            <Icons
              noContainer
              inProject={inProject}
              listView
              editable={!noEdit}
              toolboxUpdateSave={toolboxUpdateSave}
              toolbox={toolbox}
              displayIcons={displayIcons}
              updateToolboxFittings={updateToolboxFittings}
              /*was type="project-icons"*/
              type="icon"
              icons={displayIcons}
              setDrag={setDrag}
              addFittingToToolbox={addFittingToToolbox}
              toolboxId={toolbox?.id}
              remove={remove}
            />
          )}
        </ToolboxTools>
      </motion.div>
    </ToolboxContainer>
  )
}

export default ProjectToolbox

const ToolboxContainer = styled.div`
  background: #e4eef2;

  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  height: 540px;
  width: 281px;
  overflow: hidden;
  ${(p) =>
    p.isActive &&
    css`
      opacity: 0.75;
    `}
`

const ToolboxHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  margin-bottom: ${(p) => !p.noBottom && css`1rem`};
`

const ToolboxHeader = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #001733;
  flex: 2;
`

const ToolboxTools = styled.div`
  display: flex;

  max-height: 350px;
  overflow-y: scroll;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.5rem;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Edit = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
  color: #001733;
  flex: 1;
`

const HeaderRow = styled(Row)`
  justify-content: space-between;
`

const ToolboxHead = styled.div`
  display: flex;
  flex-direction: column;
`
