import { useMutation } from '@redwoodjs/web'
import { navigate, routes } from '@redwoodjs/router'
import IconForm from 'src/components/IconForm'
import { useHistory } from 'react-router-dom'

export const CREATE_ICON_MUTATION = gql`
  mutation CreateIcon(
    $name: String!
    $category: Category
    $image: String!
    $make: String
    $model: String
    $notes: String
    $partNumber: String
    $priceToSupply: Float
    $priceToInstall: Float
  ) {
    CreateIcon(
      name: $name
      category: $category
      image: $image
      make: $make
      model: $model
      notes: $notes
      partNumber: $partNumber
      priceToSupply: $priceToSupply
      priceToInstall: $priceToInstall
    ) {
      id
      name
      category
      image
      make
      model
      notes
      partNumber
      priceToSupply
      priceToInstall
    }
  }
`

export const ADD_ICON_TO_TOOLBOX = gql`
  mutation AddToToolbox($to: _ToolboxInput!, $from: _IconInput!) {
    AddToolboxIcons(from: $from, to: $to) {
      to {
        id
      }
    }
  }
`

const NewIcon = () => {
  const history = useHistory()
  const [createIcon, { loading, error }] = useMutation(CREATE_ICON_MUTATION, {
    onCompleted: () => {
      history.push('/icons')
    },
  })

  const onSave = (input) => {
    createIcon({ variables: { input } })
  }

  return (
    <div className="bg-white border rounded-lg overflow-hidden">
      <header className="bg-gray-300 text-gray-700 py-3 px-4">
        <h2 className="text-sm font-semibold">New Icon</h2>
      </header>
      <div className="bg-gray-100 p-4">
        <IconForm onSave={onSave} loading={loading} error={error} />
      </div>
    </div>
  )
}

export default NewIcon
