import styled from 'styled-components'

const RightBar = styled.div`
  height: 100%;
  width: 238px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-right-bar);
`

export default RightBar
